import { useRouter } from 'next/router';
import { Button, useResolution } from 'ui';
import { ArrowBack } from 'ui/icons';

export const GoBackButton = () => {
  const { back } = useRouter();
  const { isTablet } = useResolution();

  return (
    <Button variant="square" data-testid="go-back" onClick={() => back()} size={isTablet ? 'md' : 'sm'}>
      <ArrowBack />
    </Button>
  );
};
