import { Typography, TypographyProps } from '@mui/material';

interface Props extends TypographyProps {
  lines?: number;
}

const TypographyOverflow = ({ children, sx, display, lines = 1, ...rest }: Props) => {
  const isMultiline = lines > 1;

  return (
    <Typography
      display={isMultiline ? '-webkit-box' : display || 'block'}
      noWrap={!isMultiline}
      width="100%"
      overflow="hidden"
      title={children as string}
      sx={[
        {
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: lines,
          WebkitTextOverflow: 'ellipsis',
          wordBreak: 'break-word',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      {children}
    </Typography>
  );
};

export default TypographyOverflow;
