import { InvoiceDataAddressParts } from 'types';

import { Address } from '~/data';

export const formatAddress = (address: string | InvoiceDataAddressParts | null): Address => {
  if (!address) {
    return {
      addressLine1: '',
      addressLine2: '',
      area: '',
      municipality: '',
      postCode: '',
      region: '',
      settlement: '',
    };
  }

  if (typeof address === 'string') {
    return {
      addressLine1: address,
      addressLine2: '',
      area: '',
      municipality: '',
      postCode: '',
      region: '',
      settlement: '',
    };
  }

  return {
    addressLine1: address.addressLine || '',
    addressLine2: '',
    area: address.area || '',
    municipality: address.municipality || '',
    postCode: address.postCode || '',
    region: address.region || '',
    settlement: address.settlement || '',
  };
};
