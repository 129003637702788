import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { dateLocales, Locale } from '../config/locales';
import { DateInput } from '../types';

export const toTimeFromNow = ({ date, locale = 'bg' }: { date: DateInput; locale?: Locale }) => {
  dayjs.extend(relativeTime);
  return dayjs(date).locale(dateLocales[locale]).fromNow();
};
