import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { CloseOutlined } from 'ui/icons';

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  title?: JSX.Element;
  content?: JSX.Element;
  actions?: JSX.Element;
}

const Modal = ({ open, handleClose, title, content, actions }: ModalProps) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {title}
        {handleClose ? (
          <IconButton
            onClick={handleClose}
            size="large"
            sx={{
              position: 'absolute',
              right: { sm: 14, xs: 8 },
              top: { sm: 14, xs: 8 },
            }}
          >
            <CloseOutlined />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>{actions}</DialogActions>
    </Dialog>
  );
};

export default Modal;
