import { useAuthUser } from '~/auth';

import { doesEmailHaveImpersonationAccess } from './access';

type Page = () => JSX.Element | null;

export const withImpersonationPageAccess = (Page: Page) => {
  return (props = {}) => {
    const { getUser } = useAuthUser();

    if (Page && getUser() && doesEmailHaveImpersonationAccess(getUser()?.email || '')) {
      return <Page {...props} />;
    }

    return null;
  };
};
