import { Address } from '../types';

const getAddress = (address: Address | string): string => {
  if (typeof address === 'string') {
    return address;
  }

  return [
    address.settlement,
    address.addressLine1,
    address.addressLine2,
    address.postCode,
    address.region,
    address.municipality,
    address.area,
  ]
    .filter((item) => !!item)
    .join(', ');
};

export default getAddress;
