import { useTranslation } from 'next-i18next';
import { CardMobileSwiper, ListItemGroup, TabPanel, useResolution } from 'ui';
import {
  AccountBalance,
  Assessment,
  Business,
  ElectricalServices,
  Email,
  LocationOn,
  Money,
  Person,
  Phone,
  Power,
  Tag,
} from 'ui/icons';

import { getCustomerRepresentatives } from '~/customer';
import { Contact, getAddress, MeteringPoint } from '~/data';

interface Props {
  meteringPoint: MeteringPoint;
  contacts: Contact[];
}

const buildData = (meteringPoint: MeteringPoint, contacts: Contact[], t: (key: string) => string) => {
  return [
    {
      details: [
        {
          icon: Tag,
          subtitle: t('common:tabPanelTabs.meteringPointsData.id'),
          title: meteringPoint.meteringPointId,
        },
        {
          icon: Business,
          subtitle: t('common:tabPanelTabs.meteringPointsData.name'),
          title: meteringPoint.name,
        },
        {
          icon: LocationOn,
          subtitle: t('common:tabPanelTabs.meteringPointsData.address'),
          title: meteringPoint.address ? getAddress(meteringPoint.address) : null,
        },
        {
          icon: ElectricalServices,
          subtitle: t('common:tabPanelTabs.meteringPointsData.erp'),
          title: meteringPoint.erp,
        },
        {
          icon: Power,
          subtitle: t('common:tabPanelTabs.meteringPointsData.profile'),
          title: meteringPoint.profile,
        },
        {
          icon: Assessment,
          subtitle: t('common:tabPanelTabs.meteringPointsData.plan'),
          title: meteringPoint.plan,
        },
        {
          icon: Money,
          subtitle: t('common:tabPanelTabs.meteringPointsData.price'),
          title: meteringPoint.price ? meteringPoint.price.toString() : null,
        },
      ],
      key: 'meteringPointsData',
    },
    {
      details: [
        {
          icon: Tag,
          subtitle: t('common:tabPanelTabs.tradeRegistryData.customerName'),
          title: meteringPoint.customer.name,
        },
        {
          icon: Power,
          subtitle: t('common:tabPanelTabs.tradeRegistryData.customerId'),
          title: meteringPoint.customer.customerId,
        },
        {
          icon: AccountBalance,
          subtitle: t('common:tabPanelTabs.tradeRegistryData.vatRegistration'),
          title: meteringPoint.customer.vatNo
            ? t('common:tabPanelTabs.tradeRegistryData.yes')
            : t('common:tabPanelTabs.tradeRegistryData.no'),
        },
        {
          icon: LocationOn,
          subtitle: t('common:tabPanelTabs.tradeRegistryData.address'),
          title: getAddress(meteringPoint.customer.address),
        },
        ...(getCustomerRepresentatives(meteringPoint.customer).map((representative) => ({
          icon: Person,
          subtitle: t('common:tabPanelTabs.tradeRegistryData.representative'),
          title: representative,
        })) || []),
      ],
      key: 'tradeRegistryData',
    },
    {
      details: [
        ...(contacts[0]
          ? [
              {
                icon: Person,
                subtitle: t('common:tabPanelTabs.contactData.name'),
                title: contacts[0].name,
              },
              {
                icon: Phone,
                subtitle: t('common:tabPanelTabs.contactData.phone'),
                title: contacts[0].phone,
              },
              {
                icon: Phone,
                subtitle: t('common:tabPanelTabs.contactData.phone'),
                title: contacts[0].phoneMobile,
              },
              {
                icon: Email,
                subtitle: t('common:tabPanelTabs.contactData.email'),
                title: contacts[0].email,
              },
            ]
          : []),
      ],
      key: 'contactData',
    },
  ];
};

const MeteringPointDetailedData = ({ meteringPoint, contacts }: Props) => {
  const { t } = useTranslation();
  const { isTablet } = useResolution();

  const data = buildData(meteringPoint, contacts, t);

  const listData = data.map(({ key, details }) => ({
    content: <ListItemGroup data={details} />,
    label: t(`tabPanelTabs.${key}.tabName`),
  }));

  return isTablet ? <TabPanel tabs={listData} /> : <CardMobileSwiper elements={listData} />;
};

export default MeteringPointDetailedData;
