import { Box, Typography } from '@mui/material';

import { AvatarGroup } from '../AvatarGroup';
import Card from '../Card/Card';
import CardAddress from '../CardAddress';
import { Divider } from '../Divider';
import { TypographyOverflow } from '../TypographyOverflow';

interface Props {
  customerName?: string;
  title: React.ReactNode;
  subtitle: string | null;
  address: string | null;
  details: React.ReactNode | null;
  representativesLabel?: string;
  representatives?: string[];
  'data-testid'?: string;
}

export const MeteringPointCard = ({
  customerName,
  title,
  subtitle,
  address,
  details,
  representatives,
  representativesLabel,
  ...rest
}: Props) => {
  return (
    <Card
      {...rest}
      cardGradient={customerName}
      title={title}
      subtitle={
        <TypographyOverflow variant="h6" color="info.main" fontWeight="bold" mb={{ md: 1 }} lines={2} lineHeight={1}>
          {subtitle}
        </TypographyOverflow>
      }
      content={
        <Box display="flex" flexDirection="column" justifyContent="space-between" mt={0.5}>
          {address && <CardAddress address={address} />}

          {details && <Box mt={{ md: 3, xs: 2 }}>{details}</Box>}
        </Box>
      }
      actions={
        representatives && (
          <Box width="100%">
            <Divider sx={{ bgcolor: 'textColor.main', mb: 1.5, mt: { md: 2, xs: 1 } }} />
            <Typography variant="body2" color="textColor.light">
              {representativesLabel}
            </Typography>
            <AvatarGroup users={representatives} />
          </Box>
        )
      }
    />
  );
};
