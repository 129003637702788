import { DocumentData } from 'firebase/firestore';
import { Reducer, useCallback, useReducer } from 'react';

import { Address } from '~/data';
import { MeteringPoint } from '~/services/firestore';

import {
  Action,
  SET_ACCOUNT_ID,
  SET_ADDRESS,
  SET_CURRENT_METERING_POINT_INDEX,
  SET_METERING_POINT_ID,
  SET_METERING_POINTS_DATA,
  SET_NAME,
  SET_PREVIOUS_UTILITY,
} from './actions';
import { stateReducer } from './stateReducer';
import { CreateMeteringPointState } from './types';

export const DEFAULT_METERING_POINT_STATE: MeteringPoint = {
  accountId: '',
  address: {
    addressLine1: '',
    addressLine2: '',
    area: '',
    municipality: '',
    postCode: '',
    region: '',
    settlement: '',
  },
  meteringPointId: '',
  name: '',
  previousUtility: '',
};

export const DEFAULT_STATE: CreateMeteringPointState = {
  currentMeteringPointIndex: 0,
  meteringPointsData: [],
};

interface UseStateManagerOptions {
  onboardingData?: DocumentData | null;
}

export type UseStateManagerReturn = {
  addMeteringPoint: (meteringPointData: MeteringPoint) => void;
  removeMeteringPoint: (meteringPointIndex: number) => void;
  setAccountId: (accountId: string, meteringPointIndex: number) => void;
  setAddress: (address: Address, meteringPointIndex: number) => void;
  setCurrentMeteringPointIndex: (meteringPointIndex: number) => void;
  setMeteringPointId: (meteringPointId: string, meteringPointIndex: number) => void;
  setPreviousUtility: (previousUtility: string, meteringPointIndex: number) => void;
  setMeteringPointsData: (meteringPointsData: CreateMeteringPointState['meteringPointsData']) => void;
  setName: (name: string, meteringPointIndex: number) => void;
  state: CreateMeteringPointState;
};

export const useStateManager = ({ onboardingData }: UseStateManagerOptions): UseStateManagerReturn => {
  const onboardingMeteringPoints = onboardingData?.meteringPointsData;

  const [state, dispatch] = useReducer<Reducer<CreateMeteringPointState, Action>>(
    stateReducer,
    onboardingMeteringPoints?.length
      ? {
          currentMeteringPointIndex: onboardingMeteringPoints.length - 1,
          meteringPointsData: onboardingMeteringPoints,
        }
      : DEFAULT_STATE
  );

  const setAccountId = (accountId: string, meteringPointIndex: number): void => {
    dispatch({ meteringPointIndex, payload: { accountId }, type: SET_ACCOUNT_ID.type });
  };

  const setMeteringPointId = (meteringPointId: string, meteringPointIndex: number): void => {
    dispatch({ meteringPointIndex, payload: { meteringPointId }, type: SET_METERING_POINT_ID.type });
  };

  const setPreviousUtility = (previousUtility: string, meteringPointIndex: number): void => {
    dispatch({ meteringPointIndex, payload: { previousUtility }, type: SET_PREVIOUS_UTILITY.type });
  };

  const setName = (name: string, meteringPointIndex: number): void => {
    dispatch({ meteringPointIndex, payload: { name }, type: SET_NAME.type });
  };

  const setAddress = (address: Address, meteringPointIndex: number): void => {
    dispatch({ meteringPointIndex, payload: { address }, type: SET_ADDRESS.type });
  };

  const setMeteringPointsData = (meteringPointsData: CreateMeteringPointState['meteringPointsData']): void => {
    dispatch({ payload: { meteringPointsData }, type: SET_METERING_POINTS_DATA.type });
  };

  const addMeteringPoint = (meteringPointData: MeteringPoint) => {
    const meteringPointsData = [...state.meteringPointsData, meteringPointData];
    dispatch({ payload: { meteringPointsData }, type: SET_METERING_POINTS_DATA.type });
  };

  const setCurrentMeteringPointIndex = useCallback((meteringPointIndex: number): void => {
    dispatch({
      payload: { currentMeteringPointIndex: meteringPointIndex },
      type: SET_CURRENT_METERING_POINT_INDEX.type,
    });
  }, []);

  const removeMeteringPoint = (meteringPointIndex: number) => {
    const newMeteringPointsData = [...state.meteringPointsData];
    newMeteringPointsData.splice(meteringPointIndex, 1);

    dispatch({ payload: { meteringPointsData: newMeteringPointsData }, type: SET_METERING_POINTS_DATA.type });

    if (state.currentMeteringPointIndex === meteringPointIndex) {
      setCurrentMeteringPointIndex(0);
    }
  };

  return {
    addMeteringPoint,
    removeMeteringPoint,
    setAccountId,
    setAddress,
    setCurrentMeteringPointIndex,
    setMeteringPointId,
    setMeteringPointsData,
    setName,
    setPreviousUtility,
    state,
  };
};
