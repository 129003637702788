import { useSuspenseQuery } from '@tanstack/react-query';

import { useCustomerContacts } from '~/customer';
import {
  BaseHTTPResponse,
  Contact,
  createDataCacheKeys,
  MeteringPoint,
  PointInvoiceAddendum,
  transformMeteringPoint,
} from '~/data';
import { ResponseError, useMeteringPointApi } from '~/data/api';

import { useMeteringPointInvoices } from '../useMeteringPointInvoices/useMeteringPointInvoices';

const cacheKeys = createDataCacheKeys('metering-points');

type UseMeteringPointResult = Omit<BaseHTTPResponse<MeteringPoint>, 'data'> & {
  data: {
    meteringPoint: MeteringPoint | undefined;
    contacts: Contact[];
    invoices: PointInvoiceAddendum[];
  };
};

type MeteringPointOptions = {
  meteringPointId: string;
};

export const useMeteringPoint = ({ meteringPointId }: MeteringPointOptions): UseMeteringPointResult => {
  const api = useMeteringPointApi();

  const meteringPoint = useSuspenseQuery<MeteringPoint, ResponseError>({
    queryFn: () => api.get(meteringPointId),
    queryKey: cacheKeys.detail(meteringPointId),
  });
  const contacts = useCustomerContacts({ customerId: meteringPoint.data.customer.customerId || '' });
  const invoices = useMeteringPointInvoices({ meteringPointId });

  return {
    data: {
      contacts: contacts.data?.items || [],
      invoices: invoices.data?.items || [],
      meteringPoint: meteringPoint.data && transformMeteringPoint(meteringPoint.data),
    },
    error: meteringPoint.error || contacts.error || invoices.error,
    isError: meteringPoint.isError || contacts.isError || invoices.isError,
    isLoading: meteringPoint.isLoading || contacts.isLoading || invoices.isLoading,
    isSuccess: meteringPoint.isSuccess && contacts.isSuccess && invoices.isSuccess,
  };
};
