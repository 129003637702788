import CardListContainer from '../CardListContainer';
import { CardLoader } from '../CardLoader/CardLoader';

export const CardListLoader = ({ cardsAmount = 3 }: { cardsAmount?: number }) => (
  <CardListContainer
    elements={[...Array(cardsAmount)].map((_, index) => (
      <CardLoader key={index} />
    ))}
  />
);
