import { Box } from '../Box/Box';
import Title from './Title';
import { PageTitleProps } from './types';

const GraphicTitleLayout = ({ title, subtitle, titleGraphic, leftButtons, ...rest }: PageTitleProps) => (
  <Box
    {...rest}
    sx={{
      display: 'grid',
      gap: 2,
      gridTemplateColumns: '1fr 1fr 1fr',
    }}
  >
    <Box>{leftButtons}</Box>

    <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>{titleGraphic}</Box>

    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gridColumnEnd: 4,
        gridColumnStart: 1,
        gridRowStart: 2,
        placeSelf: 'center',
      }}
    >
      <Title title={title} subtitle={subtitle} />
    </Box>
  </Box>
);

export default GraphicTitleLayout;
