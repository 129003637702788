import { SxProps, TableBody, TableContainer, TableHead } from '@mui/material';

import { Table } from '../Table/Table';
import { TableCell } from '../TableCell/TableCell';
import { TableRow } from '../TableRow/TableRow';

type Accessor<T> = keyof T;
type value<T> = ({ row }: { row: T }) => React.ReactNode;

export type DataTableColumns<T> = {
  header: string;
  accessor?: Accessor<T>;
  value?: value<T>;
  virtualColumn?: string;
  sx?: SxProps;
};

type renderCell<T> = (row: T, accessor: Accessor<T> | string) => React.ReactNode;

type Props<T> = {
  data: T[];
  uid: keyof T;
  emptyLabel?: string;
  columns: DataTableColumns<T>[];
  renderCell?: renderCell<T>;
  renderActions?: (row: T) => React.ReactNode;
  'data-testid'?: string;
};

const renderExistingCell = <T,>(
  row: T,
  accessor: Accessor<T>,
  cellKey: string,
  renderCell?: renderCell<T>,
  value?: value<T>,
  sx?: SxProps
) => {
  if (renderCell) {
    const cell = renderCell(row, accessor);

    if (cell !== null) {
      return (
        <TableCell sx={sx} key={cellKey}>
          {cell}
        </TableCell>
      );
    }
  }

  if (typeof value === 'function') {
    return (
      <TableCell sx={sx} key={cellKey}>
        {value({ row })}
      </TableCell>
    );
  }

  if (accessor && (typeof row[accessor] === 'string' || typeof row[accessor] === 'number')) {
    return (
      <TableCell sx={sx} key={cellKey}>
        {row[accessor] as string}
      </TableCell>
    );
  }

  return <TableCell sx={sx} key={cellKey} />;
};

const renderVirtualCell = <T,>(
  row: T,
  virtualColumn: string,
  cellKey: string,
  renderCell?: renderCell<T>,
  value?: value<T>
) => {
  if (renderCell) {
    const cell = renderCell(row, virtualColumn);

    if (cell !== null) {
      return <TableCell key={cellKey}>{cell}</TableCell>;
    }
  }

  if (typeof value === 'function') {
    return <TableCell key={cellKey}>{value({ row })}</TableCell>;
  }

  return <TableCell key={cellKey} />;
};

export const DataTable = <T,>({ renderActions, data, uid, columns, emptyLabel, renderCell, ...rest }: Props<T>) => (
  <TableContainer {...rest}>
    <Table size="medium">
      <TableHead>
        <TableRow rounded>
          {columns.map(({ header }) => (
            <TableCell key={header}>{header}</TableCell>
          ))}

          {renderActions && <TableCell />}
        </TableRow>
      </TableHead>

      <TableBody>
        {!data.length && emptyLabel ? (
          <TableRow rounded>
            <TableCell>{emptyLabel}</TableCell>
          </TableRow>
        ) : (
          data.map((row) => {
            const rowKey = String(row[uid]);

            return (
              <TableRow key={rowKey} rounded>
                {columns.map(({ accessor, value, virtualColumn, sx }) => {
                  const cellKey = `${rowKey}-${String(accessor || virtualColumn)}`;

                  if (virtualColumn && (renderCell || value)) {
                    return renderVirtualCell(row, virtualColumn, cellKey, renderCell, value);
                  }

                  if (accessor) {
                    return renderExistingCell(row, accessor, cellKey, renderCell, value, sx);
                  }

                  return <TableCell key={cellKey} />;
                })}

                {renderActions && <TableCell>{renderActions(row)}</TableCell>}
              </TableRow>
            );
          })
        )}
      </TableBody>
    </Table>
  </TableContainer>
);
