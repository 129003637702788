import { Box, Typography } from '@mui/material';
import { useTranslation } from 'next-export-i18n';

import LinkContainer from '../LinkContainer';

export const SeeMoreCard = ({ count, href }: { count: number; href: string }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <LinkContainer href={href}>
        <Box display="flex" flexDirection="column">
          <Typography component="span" variant="h2" color="primary.main">
            +{count}
          </Typography>

          <Typography component="span" color="primary.main">
            {t('shared.common.seeAll')}
          </Typography>
        </Box>
      </LinkContainer>
    </Box>
  );
};
