import { LabelFormatter } from '../types';

export const labelFormatter: LabelFormatter = (label, payload) => {
  if (payload && payload.length) {
    const customLabel = payload[0].payload?.tooltipLabel;
    return customLabel || label;
  }

  return null;
};
