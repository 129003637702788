import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Button, Card, CardContent, Loader, Typography, useResolution } from 'ui';
import { FileUploadOutlined } from 'ui/icons';

import { useCreateCustomer } from '~/customer';
import { parseInvoiceFileData } from '~/customer/utils';
import { OnboardingRequest } from '~/services/firestore';
import { StepTitle } from '~/ui';

export const InvoiceUploadStep = ({ onInvoiceUpload }: { onInvoiceUpload?: (data: OnboardingRequest) => void }) => {
  const { t } = useTranslation('createCustomerWizard');
  const [isLoading, setIsLoading] = useState(false);
  const { setCurrentStep, setCustomerData, getCustomerData, isCompany } = useCreateCustomer();
  const customerData = getCustomerData();
  const { isDesktop } = useResolution();

  const onDrop = async (acceptedFiles: File[]) => {
    setIsLoading(true);
    const file = acceptedFiles[0];

    const { address, customerName, meteringPoints: meteringPointsData } = await parseInvoiceFileData(file);

    onInvoiceUpload?.({ meteringPointsData });
    setCustomerData({
      ...customerData,
      address,
      customerType: customerData.customerType,
      eik: customerData.eik,
      personalId: customerData.personalId,
      ...customerName,
    });

    const confirmationStep = isCompany() ? 'businessConfirmation' : 'residentConfirmation';
    setCurrentStep(confirmationStep);

    setIsLoading(false);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      'application/pdf': [],
      'image/*': ['.jpeg', '.jpg', '.png', '.heic', '.heif'],
    },
    maxFiles: 1,
    onDrop,
    // A note about Playwright testing
    // https://github.com/react-dropzone/react-dropzone/discussions/1339
    useFsAccessApi: false,
  });

  return (
    <Box display="flex" flex={1} flexDirection="column">
      <Box mb={2}>
        <StepTitle>{t('invoiceUpload.title')}</StepTitle>
      </Box>

      {isDesktop && (
        <>
          <Card {...getRootProps()} sx={({ customShadows }) => ({ boxShadow: customShadows.main })}>
            <input {...getInputProps()} />

            <CardContent>
              <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" height={70}>
                {isLoading && <Loader size={30} />}
                {!isLoading && (
                  <>
                    <Box mb={1}>
                      <FileUploadOutlined sx={{ color: 'primary.dark' }} />
                    </Box>

                    <Typography variant="body1" fontWeight="bold" color="primary.dark">
                      {t('invoiceUpload.dragAndDropLabel')}
                    </Typography>
                  </>
                )}
              </Box>
            </CardContent>
          </Card>

          <Box mb={2} alignSelf="center" mt={2}>
            <Typography variant="body2">{t('invoiceUpload.or')}</Typography>
          </Box>

          <Button size="lg" fullWidth onClick={open} data-testid="upload-invoice-button" color="gradient">
            {t('invoiceUpload.browse')}
          </Button>
        </>
      )}

      {!isDesktop && (
        <Box>
          <input {...getInputProps()} />
          <Button
            size="lg"
            fullWidth
            onClick={open}
            loading={isLoading}
            data-testid="upload-invoice-button"
            color="gradient"
          >
            {t('invoiceUpload.browse')}
          </Button>
        </Box>
      )}
    </Box>
  );
};
