import { DonutPayload, DonutTooltipFormatter } from './types';

const tooltipStyle = {
  background: '#fff',
  border: 'solid 1px #aaa',
  padding: '10px',
};

interface Props {
  payload: DonutPayload;
  tooltipFormatter?: DonutTooltipFormatter;
}

export const DefaultTooltip = ({ payload, tooltipFormatter }: Props) => {
  if (!payload || !payload.value || !payload.name) return null;

  return (
    <div style={tooltipStyle}>
      {tooltipFormatter ? (
        <div>{tooltipFormatter(payload.value, payload.name, payload).reverse().join(': ')}</div>
      ) : (
        <div>{`${payload?.name}: ${payload?.value}`}</div>
      )}
    </div>
  );
};
