import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormHelperText, ToggleButtonGroup, ToggleButtonGroupProps } from 'ui';

import { getError } from '../../utils';

interface Props extends ToggleButtonGroupProps {
  name: string;
  options: string[];
  selected?: string[];
}

export const ButtonGroup = ({ name, selected = [], ...rest }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = getError(errors, name);

  return (
    <FormControl fullWidth error={Boolean(error)}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <ToggleButtonGroup
            value={selected}
            error={error?.message}
            onChange={(_, value) => {
              field.onChange(value);
            }}
            aria-label={name}
            {...rest}
          />
        )}
      />
      <FormHelperText>{error?.message}</FormHelperText>
    </FormControl>
  );
};
