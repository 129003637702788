import { Grid, TypographyOverflow } from 'ui';

export const CompanyInvoiceDetails = ({ data }: { data: string[] }) => {
  return (
    <Grid container spacing={1} sx={{ pt: 0 }} mt={{ md: 1, xs: 0 }}>
      {data.map((text) => (
        <Grid item xs={12} key={text}>
          <TypographyOverflow noWrap>{text}</TypographyOverflow>
        </Grid>
      ))}
    </Grid>
  );
};
