import { createDataCacheKeys, Customer } from '~/data';
import { CustomerFilter, useCustomerApi, UsePaginatedQueryResult, useSuspensePaginatedQuery } from '~/data/api';

const cacheKeys = createDataCacheKeys('customers');

type UserCustomersOptions = {
  filterBy?: CustomerFilter;
  size?: number;
};

export type UseCustomersResult = UsePaginatedQueryResult<Customer>;
export const useCustomers = (
  { filterBy, size }: UserCustomersOptions = { filterBy: undefined }
): UseCustomersResult => {
  const api = useCustomerApi();

  const { data, isLoading, isError, isSuccess, error, fetchNextPage } = useSuspensePaginatedQuery<Customer>({
    cacheKey: [...cacheKeys.list({ filterBy })],
    fetchAll: (pageParams) =>
      api.getAll({ filter: filterBy, page: { page: pageParams.page, size: size || pageParams.size } }),
  });

  return {
    data,
    error,
    fetchNextPage,
    isError,
    isLoading,
    isSuccess,
  };
};
