import { useTranslation } from 'next-i18next';

import { formatNumberToCurrency } from '~/invoice/helpers/formatNumberToCurrency';

export const useInvoiceBalance = ({ balance }: { balance: number | null | undefined }) => {
  const { t } = useTranslation('invoices');

  if (!balance) {
    return null;
  }

  const balanceType = balance > 0 ? 'balance' : 'overpaidBalance';
  const balanceCurrency = formatNumberToCurrency({ value: Math.abs(balance) });

  return t(`invoices:${balanceType}`, {
    value: balanceCurrency,
  });
};
