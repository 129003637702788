import Link from 'next/link';
import { useRouter } from 'next/router';
import { BottomNavigation as UiBottomNavigation } from 'ui';

import { NavigationOptions } from '../types';

interface Props {
  options: NavigationOptions[];
}

const BottomNavigation = ({ options }: Props) => {
  const { pathname } = useRouter();
  return (
    <UiBottomNavigation currentPath={pathname}>
      {options.map(({ label, href, icon }, i) => (
        <UiBottomNavigation.Item
          key={i}
          data-testid={`bottom-nav-${href.slice(1)}`}
          component={Link}
          href={href}
          value={href}
          label={label}
          icon={icon}
        />
      ))}
    </UiBottomNavigation>
  );
};

export default BottomNavigation;
