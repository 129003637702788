import { useInvoice } from '../useInvoice/useInvoice';

type InvoiceMeteringPointsOptions = {
  invoiceId: string;
};

export const useInvoiceMeteringPoints = ({ invoiceId }: InvoiceMeteringPointsOptions) => {
  const { data, error, isError, isLoading, isSuccess } = useInvoice({ invoiceId });

  const meteringPoints = data?.meteringPoints || [];

  return {
    data: meteringPoints || [],
    error,
    isError,
    isLoading,
    isSuccess,
  };
};
