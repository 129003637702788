import { CreateCustomerWizard } from './components/CreateCustomerWizard';
import { CustomerCard, CustomerDetails } from './components/detail';
import { CustomerPicker, CustomersList } from './components/list';
import getCustomerRepresentatives from './helpers/getCustomerRepresentatives';
import { CreateCustomerProvider, useCreateCustomer } from './hooks/useCreateCustomer';
import { useCustomer } from './hooks/useCustomer/useCustomer';
import { useCustomerContacts } from './hooks/useCustomerContacts/useCustomerContacts';
import { useCustomers } from './hooks/useCustomers/useCustomers';
import { useSelectedCustomerIds } from './hooks/useSelectedCustomerIds/useSelectedCustomerIds';

export {
  CreateCustomerProvider,
  CreateCustomerWizard,
  CustomerCard,
  CustomerDetails,
  CustomerPicker,
  CustomersList,
  getCustomerRepresentatives,
  useCreateCustomer,
  useCustomer,
  useCustomerContacts,
  useCustomers,
  useSelectedCustomerIds,
};
