import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from 'firebase-client';
import logger from 'logger';

export const createUser = async ({ email, password }: { email: string; password: string }) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    logger.debug('userCredential :', userCredential);
    return userCredential;
  } catch (error) {
    logger.error('createUser error', { error });
    throw error;
  }
};
