import { MeteringPoint } from '~/data';

import { getReadableErpName } from './getReadableErpName';
import { getReadablePlanName } from './getReadablePlanName';

export const transformMeteringPoint = (meteringPoint: MeteringPoint): MeteringPoint => {
  return {
    ...meteringPoint,
    erp: meteringPoint.erp && getReadableErpName(meteringPoint.erp),
    plan: meteringPoint.plan && getReadablePlanName(meteringPoint.plan),
  };
};

export const transformMeteringPoints = (meteringPoints: MeteringPoint[]): MeteringPoint[] =>
  meteringPoints.map(transformMeteringPoint);
