import { Trans, useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { Box, Grid, MeteringPointCard as MeteringPointCardUI, Typography } from 'ui';

import { getCustomerRepresentatives } from '~/customer';
import { getAddress, MeteringPoint } from '~/data';
import { LinkContainer } from '~/ui';

import getMeteringPointPath from '../../helpers/getMeteringPointPath';

interface DetailValueProps {
  labelKey: string;
  value: string | number | null;
  align: 'flex-start' | 'flex-end';
}

const DetailValue = ({ labelKey, value, align }: DetailValueProps) => {
  return (
    <Box display="flex" flex={1} flexDirection="row" justifyContent={align}>
      <Trans
        i18nKey={`meteringPoints:${labelKey}`}
        values={{ [labelKey]: value }}
        components={[
          <Typography variant="body2" color="textColor.light" component="span" />,
          <Typography variant="body2" fontWeight="bold" ml={0.5} component="span" />,
        ]}
      />
    </Box>
  );
};

interface DetailsProps {
  erp: string;
  profile: string | null;
  plan: string | null;
  status: string;
  price: number | null;
}

const Details = ({ erp, profile, plan, status, price }: DetailsProps) => {
  const data = useMemo(() => {
    return [
      { labelKey: 'erp', value: erp },
      { labelKey: 'profile', value: profile },
      { labelKey: 'plan', value: plan },
      { labelKey: 'price', value: price },
      { labelKey: 'status', value: status },
    ].filter(({ value }) => value);
  }, [erp, plan, price, profile, status]);

  return (
    <Grid container spacing={2}>
      {data.map(({ labelKey, value }, index) => {
        return (
          <Grid item xs={6} key={labelKey}>
            <DetailValue labelKey={labelKey} value={value} align={index % 2 === 0 ? 'flex-start' : 'flex-end'} />
          </Grid>
        );
      })}
    </Grid>
  );
};

interface MeteringPointProps {
  displayCustomerName?: boolean;
  meteringPoint: MeteringPoint;
}

export const MeteringPointCard = ({ displayCustomerName = false, meteringPoint }: MeteringPointProps) => {
  const { t } = useTranslation();
  const { customer, name, meteringPointId, address, erp, profile, plan, price, status } = meteringPoint;

  return (
    <LinkContainer href={getMeteringPointPath(meteringPoint)}>
      <MeteringPointCardUI
        data-testid="metering-point-card"
        title={
          <Trans
            i18nKey="meteringPoints:id"
            values={{ id: meteringPointId }}
            components={[
              <Typography variant="body2" color="textColor.light" display="inline" />,
              <Typography variant="body2" display="inline" />,
            ]}
          />
        }
        subtitle={name}
        customerName={displayCustomerName ? customer.name : undefined}
        address={address ? getAddress(address) : null}
        details={erp ? <Details erp={erp} profile={profile} plan={plan} price={price} status={status} /> : null}
        representativesLabel={t('meteringPoints:representatives')}
        representatives={getCustomerRepresentatives(customer)}
      />
    </LinkContainer>
  );
};
