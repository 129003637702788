import { useAuthUser } from '~/auth';
import { GetCompanyReturn } from '~/services/TradeRegister/types';

type UseTradeRegisterReturn = {
  getCompany: (eik: string) => Promise<GetCompanyReturn>;
};

export const useTradeRegister = (): UseTradeRegisterReturn => {
  const { getToken } = useAuthUser();

  const getCompany = async (eik: string): Promise<GetCompanyReturn> => {
    const token = await getToken();

    if (!token) {
      throw new Error('No token');
    }

    const response = await fetch(`/api/trade-register/${eik}`, {
      headers: { Authorization: token, 'content-type': 'application/json' },
      method: 'GET',
    });

    const company = (await response.json()) as { data: GetCompanyReturn };

    return company.data;
  };

  return {
    getCompany,
  };
};
