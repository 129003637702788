import { Box, Typography } from '@mui/material';

import { useResolution } from '../../hooks';
import { Button } from '../Button';
import { Divider } from '../Divider';
import NotificationContent from './NotificationContent';

interface Props {
  title: React.ReactNode;
  subtitle: string;
  icon: React.ReactNode;
  color: 'red' | 'orange' | 'yellow' | 'green';
  timestamp: string;
  buttonTitle?: string;
  handleClick?: (event: React.MouseEvent) => void;
}

const Notification = ({ title, subtitle, icon, color, timestamp, buttonTitle, handleClick }: Props) => {
  const { isTablet } = useResolution();
  const renderButton = buttonTitle && handleClick;
  return (
    <Box display="flex" height={isTablet ? 70 : 100}>
      <Box display="flex" flexDirection="row" gap={2} alignItems="center" width="100%">
        {isTablet && (
          <Typography variant="body2" minWidth={100}>
            {timestamp}
          </Typography>
        )}
        <Divider
          orientation="vertical"
          sx={{ bgcolor: `notifications.${color}`, borderRadius: 3, height: '80%', width: 7 }}
        />
        {isTablet && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ bgcolor: `notifications.${color}`, borderRadius: 3, height: 50, minWidth: 50 }}
          >
            {icon}
          </Box>
        )}
        <NotificationContent title={title} subtitle={subtitle} timestamp={timestamp} />
      </Box>
      {isTablet && renderButton && (
        <Box display="flex" alignItems="center">
          <Button onClick={handleClick} color="mintGreen">
            {buttonTitle}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Notification;
