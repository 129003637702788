import { Address, analyzeInvoice } from '~/data';
import { MeteringPoint } from '~/services/firestore';

import { formatAddress } from './formatAddress/formatAddress';
import { CustomerName, parseCustomerName } from './parseCustomerName/parseCustomerName';
import { parseMeteringPointsData } from './parseMeteringPointsData/parseMeteringPointsData';

type ParseInvoiceFileDataReturn = {
  address: Address;
  customerName: CustomerName;
  meteringPoints: MeteringPoint[];
};

export const parseInvoiceFileData = async (invoiceFile: File): Promise<ParseInvoiceFileDataReturn> => {
  const invoiceData = await analyzeInvoice(invoiceFile);

  const address = formatAddress(invoiceData.receiverAddress);
  const customerName = parseCustomerName(invoiceData.receiverName || '');
  const meteringPoints = parseMeteringPointsData(invoiceData.meteringPoints);

  return {
    address,
    customerName,
    meteringPoints,
  };
};
