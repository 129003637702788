import logger from 'logger';
import { useTranslation } from 'next-i18next';
import { Avatar, DetailsPageTitle, PageSection, Stack, Typography } from 'ui';

import { useCustomer } from '~/customer';
import { RecentInvoiceList } from '~/invoice';
import { GoBackButton, NextLink } from '~/ui';

import { CustomerDetailedData, CustomerMeteringPoints } from '../list';

interface Props {
  id: string;
}
export const CustomerDetails = ({ id }: Props) => {
  const { t } = useTranslation();

  const {
    data: { customer, invoices, contacts, meteringPoints },
  } = useCustomer({ customerId: id });

  if (!customer) {
    logger.debug('Customer not found');
    return null;
  }

  return (
    <>
      <DetailsPageTitle
        leftButtons={<GoBackButton />}
        titleGraphic={<Avatar shape="rounded" size="large" withGradient name={customer.name} />}
        subtitle={t('customers:id', { id: customer.customerId })}
        title={customer.name}
      />

      <Stack spacing={4} mt={4}>
        <CustomerDetailedData contacts={contacts.items} customer={customer} />

        <PageSection
          title={t('invoices:recentInvoices')}
          subtitle={<NextLink href="/invoices">{t('seeMore')}</NextLink>}
        >
          <RecentInvoiceList list={invoices.items} />
        </PageSection>

        <PageSection
          data-testid="customer-metering-points"
          title={t('meteringPoints:meteringPoints')}
          subtitle={
            <Stack direction="row" alignItems="end" gap={1}>
              <Typography variant="h6" color="primary.main" fontWeight="bold" lineHeight={1.4}>
                {meteringPoints.total}
              </Typography>
            </Stack>
          }
        >
          <CustomerMeteringPoints list={meteringPoints.items} total={meteringPoints.total} />
        </PageSection>
      </Stack>
    </>
  );
};
