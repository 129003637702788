import { Typography, TypographyProps } from '../Typography/Typography';

export type SupportedCurrencies = 'BGN' | 'EUR' | 'USD';

interface Props extends TypographyProps {
  value: number;
  currency?: SupportedCurrencies;
}

export const CurrencyFormat = ({ value, currency = 'BGN', ...rest }: Props) => {
  // TODO: make locale configurable
  const text = new Intl.NumberFormat('bg-BG', { currency, style: 'currency' }).format(value);
  return <Typography {...rest}>{text}</Typography>;
};
