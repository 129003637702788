import { useTranslation } from 'next-i18next';

import { useCreateCustomer } from '~/customer';
import { DataSection } from '~/ui';

import { Value } from '../Value';

interface Props {
  onClick: (step: string) => void;
}

export const Name = ({ onClick }: Props) => {
  const { t } = useTranslation('createCustomerWizard');
  const { getCustomerData } = useCreateCustomer();
  const { firstName, middleName, lastName } = getCustomerData();

  return (
    <DataSection
      label={t('steps.confirmation.name')}
      Value={
        <>
          <Value>
            {firstName} {middleName} {lastName}
          </Value>
          {firstName || middleName || lastName ? null : <Value>{t('steps.confirmation.missingData')}</Value>}
        </>
      }
      onClick={() => onClick('name')}
      error={!firstName || !middleName || !lastName ? t('steps.confirmation.error') : ''}
      data-testid="name"
    />
  );
};
