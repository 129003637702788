import { Box, Typography } from '@mui/material';

import { useResolution } from '../../hooks';

const ListPageTitle = ({
  title,
  actions,
  desktopActions,
}: {
  title: string;
  actions?: React.ReactNode;
  desktopActions?: React.ReactNode;
}) => {
  const { isTablet, isDesktop } = useResolution();

  return (
    <Box display="flex" justifyContent="space-between" flexDirection="row" mb={3}>
      <Box
        display="flex"
        mr={2}
        width={isTablet ? '45%' : '100%'}
        justifyContent={isTablet ? 'initial' : 'space-between'}
        alignItems="center"
      >
        <Typography data-testid="page-title" variant={isDesktop ? 'h3' : 'h4'} flexShrink={0} mr={1}>
          {title}
        </Typography>

        {actions}
      </Box>
      <Box display="flex">{isDesktop && desktopActions}</Box>
    </Box>
  );
};

export default ListPageTitle;
