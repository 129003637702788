import dayjs from 'dayjs';

import { dateLocales, Locale } from '../config/locales';
import { DateInput } from '../types';

export const formatDate = ({
  date,
  dateFormat = 'DD/MM/YYYY',
  locale = 'bg',
}: {
  date: DateInput;
  dateFormat?: string;
  locale?: Locale;
}) => dayjs(date).locale(dateLocales[locale]).format(dateFormat);
