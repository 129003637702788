import { useTranslation } from 'next-i18next';
import { Box, Button, Typography } from 'ui';

import { useCreateCustomer } from '~/customer';
import { StepTitle } from '~/ui';

export const BusinessStepsPreviewStep = () => {
  const { t } = useTranslation('createCustomerWizard');
  const { goToNextStep } = useCreateCustomer();

  return (
    <Box display="flex" flex={1} flexDirection="column" justifyContent="space-between">
      <Box>
        <Box mb={2}>
          <StepTitle>{t('steps.title')}</StepTitle>
        </Box>

        <Box>
          <Box mb={1}>
            <Typography variant="body1">1. {t('steps.eik.title')}</Typography>
          </Box>

          <Box mb={1}>
            <Typography variant="body1">3. {t('steps.legalEntityName.title')}</Typography>
          </Box>

          <Box mb={1}>
            <Typography variant="body1">2. {t('steps.businessRepresentative.title')}</Typography>
          </Box>
        </Box>

        <Box mb={1}>
          <Typography variant="body1">4. {t('steps.address.title')}</Typography>
        </Box>

        <Box mb={1}>
          <Typography variant="body1">5. {t('steps.contact.title')}</Typography>
        </Box>

        <Typography variant="body1">6. {t('steps.billingContact.title')}</Typography>
      </Box>

      <Button size="lg" fullWidth onClick={goToNextStep} data-testid="continue-button" color="gradient">
        {t('common:continue')}
      </Button>
    </Box>
  );
};
