import { auth, onAuthStateChanged } from 'firebase-client';

import { Attributes } from '../types';
import { setAttributes } from '../utils/setAttributes';

export const useSetAttributesOnAuthChange = (attributes?: Attributes) => {
  onAuthStateChanged(auth, (user) => {
    setAttributes({ email: user ? user.email : null, ...attributes });
  });
};
