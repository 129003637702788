import { Skeleton, TableBody, TableContainer, TableHead } from '@mui/material';

import { Table } from '../Table/Table';
import { TableCell } from '../TableCell/TableCell';
import { TableRow } from '../TableRow/TableRow';

export const TableLoader = ({ rows = 3 }: { rows?: number }) => (
  <TableContainer>
    <Table size="medium">
      <TableHead>
        <TableRow rounded>
          <TableCell>
            <Skeleton variant="rounded" />
          </TableCell>
          <TableCell>
            <Skeleton variant="rounded" />
          </TableCell>
          <TableCell>
            <Skeleton variant="rounded" />
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {Array.from(Array(rows).keys()).map((row) => (
          <TableRow rounded key={row}>
            <TableCell>
              <Skeleton variant="rounded" />
            </TableCell>
            <TableCell>
              <Skeleton variant="rounded" />
            </TableCell>
            <TableCell>
              <Skeleton variant="rounded" />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);
