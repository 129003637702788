import { useSuspenseQuery } from '@tanstack/react-query';

import { BaseHTTPResponse, createDataCacheKeys, Invoice, ListOptions, Page } from '~/data';
import { ResponseError, useCustomerApi } from '~/data/api';

const getCacheKey = (customerId: string, listOptions: ListOptions = {}) =>
  createDataCacheKeys(`customers/${customerId}/invoices`).list(listOptions);

type UseCustomerInvoicesResult = BaseHTTPResponse<Page<Invoice>>;

type CustomerInvoicesOptions = {
  customerId: string;
};

export const useCustomerInvoices = ({ customerId }: CustomerInvoicesOptions): UseCustomerInvoicesResult => {
  const api = useCustomerApi();

  const { data, error, isLoading, isError, isSuccess } = useSuspenseQuery<Page<Invoice>, ResponseError>({
    queryFn: () => api.getInvoices(customerId, { page: 1, size: 5 }),
    queryKey: getCacheKey(customerId),
  });

  return {
    data,
    error,
    isError,
    isLoading,
    isSuccess,
  };
};
