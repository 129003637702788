import { Box, Typography } from 'ui';

interface HeaderProps {
  title: string;
  description: string;
}

export const Header = ({ title, description }: HeaderProps) => (
  <Box
    display="flex"
    flexDirection="row"
    alignItems="center"
    justifyContent="flex-start"
    alignSelf="flex-start"
    width="100%"
    sx={{ borderBottomColor: 'gray.main', borderBottomStyle: 'solid', borderBottomWidth: 1 }}
    pb={2}
    mb={2}
  >
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Typography variant="h6" color="black">
        {title}
      </Typography>

      <Typography variant="body2" color="textColor.light">
        {description}
      </Typography>
    </Box>
  </Box>
);
