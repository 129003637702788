import { Box, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

import Card from '../Card';
import { CurrencyFormat } from '../CurrencyFormat/CurrencyFormat';
import { Divider } from '../Divider';
import { DownloadIconButton } from '../DownloadButton/DownloadIconButton';

interface InvoiceCardProps {
  subtitle: string;
  title: string;
  downloadUrl?: string;
  amount: number;
  details: ReactNode[];
  statusText?: string;
  status?: string;
  paymentBalance: string | null;
  customerName?: string;
  onInvoiceDownload?: () => void;
  ['data-testid']?: string;
}

const InvoiceCard = ({
  amount,
  subtitle,
  title,
  details,
  downloadUrl,
  paymentBalance,
  status,
  onInvoiceDownload,
  statusText,
  customerName,
  'data-testid': dataTestId,
}: InvoiceCardProps) => {
  const isCancelled = status === 'Cancelled';
  const isOverdue = status === 'Overdue';
  return (
    <Card
      data-testid={dataTestId}
      headerActions={downloadUrl && <DownloadIconButton downloadUrl={downloadUrl} onClick={onInvoiceDownload} />}
      title={
        <Typography variant="body2" color="info.main">
          {subtitle}
        </Typography>
      }
      subtitle={
        <Box display="inline-flex">
          <Typography variant="h6" color="info.main" fontWeight="bold" mt={0.5} textTransform="capitalize">
            {title}
          </Typography>
        </Box>
      }
      content={
        <Stack color="textColor.light">
          {details.map((detail, i) => (
            <Typography key={i} mb={0.5} variant="body2">
              {detail}
            </Typography>
          ))}
        </Stack>
      }
      actions={
        <Box width="100%">
          <CurrencyFormat
            sx={{ textDecoration: isCancelled ? 'line-through' : undefined }}
            value={amount}
            variant="h5"
            fontWeight="bold"
            color={isCancelled ? 'textColor.light' : 'primary.main'}
          />
          <Divider sx={{ backgroundColor: 'textColor.main', my: 2 }} />
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            {paymentBalance && <Typography variant="body1">{paymentBalance}</Typography>}
            <Typography variant="body1" color={isOverdue ? 'error.main' : 'info.main'}>
              {statusText}
            </Typography>
          </Box>
        </Box>
      }
      cardGradient={customerName}
    />
  );
};

export default InvoiceCard;
