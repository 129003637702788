class ResponseError extends Error {
  constructor(
    public response: Response,
    msg?: string
  ) {
    super(msg);
  }
}

export default ResponseError;
