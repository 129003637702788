import { CurrentStep } from './types';
import { UseStateManagerReturn } from './useStateManager';

type BeforePreviousCallback = {
  [key in CurrentStep[number]]?: (stateManager: UseStateManagerReturn) => void;
};

export const BEFORE_PREVIOUS_CALLBACKS: BeforePreviousCallback = {
  address: (stateManager: UseStateManagerReturn) => {
    stateManager.setCurrentRepresentativeIndex(0);
  },
  businessRepresentative1: (stateManager: UseStateManagerReturn) => {
    stateManager.setCurrentRepresentativeIndex(0);
  },
  businessRepresentative2: (stateManager: UseStateManagerReturn) => {
    stateManager.setCurrentRepresentativeIndex(0);
  },
};
