import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { Box, Divider, ListItemIcon, Menu, MenuItem, Typography, useResolution } from 'ui';
import { ArrowDropDownOutlined, Logout, PersonOutlineOutlined } from 'ui/icons';

import { useAuthUser } from '~/auth';
import { LinkContainer } from '~/ui/LinkContainer/LinkContainer';

const AccountContainer = styled.div({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
});

const paperProps = {
  elevation: 0,
  sx: {
    '&:before': {
      bgcolor: 'background.paper',
      content: '""',
      display: 'block',
      height: 10,
      position: 'absolute',
      right: { sm: 8, xs: 12 },
      top: 0,
      transform: 'translateY(-50%) rotate(45deg)',
      width: 10,
      zIndex: 0,
    },
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    overflow: 'visible',
    width: { sm: 195 },
  },
};
export const AccountMenu = () => {
  const { replace } = useRouter();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const { isAuthenticated, getUser, logout, getImpersonatedUser } = useAuthUser();
  const user = getUser();
  const impersonatedUser = getImpersonatedUser();

  const { isTablet } = useResolution();

  const handleOpen = (event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = async () => {
    await logout();
    handleClose();

    replace('/login');
  };

  if (!isAuthenticated()) {
    return (
      <Box>
        <LinkContainer href="/login">
          <Typography color="black" fontWeight="bold">
            {t('appBar.login')}
          </Typography>
        </LinkContainer>
      </Box>
    );
  }

  return (
    <Box display="flex" alignItems="center" flexDirection="row">
      {isTablet && user && impersonatedUser && (
        <Box mr={1} bgcolor="red.main" p={1} borderRadius={5}>
          <Typography color="white" data-testid="logged-as">
            {t('appBar.loggedAs', { email: impersonatedUser.email })}
          </Typography>
        </Box>
      )}

      <AccountContainer
        onClick={handleOpen}
        role="button"
        tabIndex={0}
        aria-controls={open ? 'account-menu' : undefined}
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            handleOpen(event);
          }
        }}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        {isTablet && user ? (
          user.email && (
            <>
              <Divider orientation="vertical" flexItem sx={{ bgcolor: 'textColor.main', mx: 1, my: 0 }} />
              <Typography fontWeight="bold" ml={1}>
                {user.email}
              </Typography>
            </>
          )
        ) : (
          <PersonOutlineOutlined fontSize="large" />
        )}
        <ArrowDropDownOutlined fontSize="large" />
      </AccountContainer>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={paperProps}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        {!isTablet && user && impersonatedUser && (
          <MenuItem>
            <Typography color="red.main" data-testid="logged-as">
              {impersonatedUser.email}
            </Typography>
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t('appBar.logout')}
        </MenuItem>
      </Menu>
    </Box>
  );
};
