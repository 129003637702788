import { useTranslation } from 'next-i18next';
import { Box } from 'ui';

import { useCreateCustomer } from '~/customer';
import { isCompletedAddress } from '~/services/firestore';
import { DataSection } from '~/ui';

import { Value } from '../Value';

interface Props {
  onClick: (step: string) => void;
}

export const Address = ({ onClick }: Props) => {
  const { t } = useTranslation('createCustomerWizard');
  const { getCustomerData } = useCreateCustomer();
  const { address } = getCustomerData();

  return (
    <DataSection
      label={t('steps.confirmation.address')}
      Value={
        <Box>
          {address?.region && <Value>{address?.region},</Value>}
          <Value>
            {address?.postCode && `${address.postCode},`} {address?.settlement && `${address.settlement},`}
          </Value>
          {address.area && <Value>{address.area},</Value>}
          <Value>
            {address.addressLine1} {address.addressLine2}
          </Value>
          {address.region ||
          address.postCode ||
          address.settlement ||
          address.addressLine1 ||
          address.addressLine2 ? null : (
            <Value>{t('steps.confirmation.missingData')}</Value>
          )}
        </Box>
      }
      onClick={() => onClick('address')}
      error={!isCompletedAddress(address) ? t('steps.confirmation.error') : ''}
      data-testid="address"
    />
  );
};
