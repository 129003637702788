import { Box, Stack, SvgIconProps } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ComponentType } from 'react';

import { TypographyOverflow } from '../TypographyOverflow';

interface Props {
  Icon: ComponentType<SvgIconProps>;
  title: string;
  subtitle?: string;
  backgroundColor?: string;
  iconColor?: string;
  size?: 'lg' | 'sm';
}

const ListItem = ({ Icon, title, subtitle, backgroundColor, iconColor, size = 'lg' }: Props) => {
  const isSmall = size === 'sm';
  return (
    <Box display="flex" alignItems="center" sx={{ height: 38 }}>
      <Box
        height={isSmall ? 26 : 38}
        width={isSmall ? 26 : 38}
        sx={{ backgroundColor: backgroundColor || 'purple.lighter', borderRadius: 4 }}
        justifyContent="center"
        alignItems="center"
        display="flex"
        flexShrink={0}
      >
        <Icon sx={{ color: iconColor || 'purple.main', width: size === 'sm' ? 14 : 24 }} />
      </Box>

      <Stack ml={1.5} sx={{ overflow: 'hidden' }}>
        {subtitle && (
          <TypographyOverflow variant="subtitle2" color={grey[500]}>
            {subtitle}
          </TypographyOverflow>
        )}
        <TypographyOverflow variant={isSmall ? 'caption' : 'subtitle1'} noWrap lineHeight={1.1}>
          {title}
        </TypographyOverflow>
      </Stack>
    </Box>
  );
};

export default ListItem;
