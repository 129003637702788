import { CardListContainer } from 'ui';

import { Invoice, PointInvoiceAddendum } from '~/data';

import { InvoiceCard } from '../detail';

export const InvoiceCardList = ({ list }: { list: Invoice[] | PointInvoiceAddendum[] }) => {
  return (
    <CardListContainer
      elements={list.map((invoice) => {
        return <InvoiceCard key={invoice.invoiceNumber} {...invoice} />;
      })}
    />
  );
};
