import { useTranslation } from 'next-i18next';

import { useCreateCustomer } from '~/customer';
import { DataSection } from '~/ui';

import { Value } from '../Value';

interface Props {
  onClick: (step: string) => void;
}

export const Eik = ({ onClick }: Props) => {
  const { t } = useTranslation('createCustomerWizard');
  const { getCustomerData } = useCreateCustomer();

  const customerData = getCustomerData();
  const { eik } = customerData;

  return (
    <DataSection
      label={t('steps.confirmation.eik')}
      Value={<Value>{eik || t('steps.confirmation.noData')}</Value>}
      onClick={() => onClick('eikId')}
      error={!eik ? t('steps.confirmation.error') : ''}
      data-testid="eik"
    />
  );
};
