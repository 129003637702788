import { Actions, addAction } from 'rum';

import { Invoice } from '~/data';

export const recordInvoiceDownload = (invoice: Pick<Invoice, 'invoiceId' | 'invoiceNumber' | 'pdfUrl'>) => {
  addAction(Actions.BLENDER_DOWNLOAD_INVOICE_PDF, {
    invoiceId: invoice.invoiceId,
    invoiceNumber: invoice.invoiceNumber,
    pdfUrl: invoice.pdfUrl,
  });
};
