import { Box, Typography } from '@mui/material';

import { Avatar } from '../Avatar';
import { AvatarGroup } from '../AvatarGroup';
import Card from '../Card/Card';
import CardAddress from '../CardAddress';
import { Divider } from '../Divider';
import { TypographyOverflow } from '../TypographyOverflow';

interface Props {
  title: string;
  subtitle: string;
  address: string;
  description?: React.ReactNode;
  representativesLabel?: string;
  representatives?: string[];
  'data-testid'?: string;
}

export const CustomerCard = ({
  title,
  subtitle,
  address,
  description,
  representatives,
  representativesLabel,
  ...rest
}: Props) => {
  return (
    <Card
      {...rest}
      avatar={<Avatar shape="rounded" size="medium" name={subtitle} withGradient />}
      title={
        <Typography variant="body2" color="info.main">
          {title}
        </Typography>
      }
      subtitle={
        <TypographyOverflow variant="h6" color="info.main" fontWeight="bold" lines={2} lineHeight={1} height={40}>
          {subtitle}
        </TypographyOverflow>
      }
      content={
        <Box display="flex" flexDirection="column" justifyContent="space-between" mt={0.5}>
          <CardAddress address={address} />
          {description && <Box mt={2}>{description}</Box>}
        </Box>
      }
      actions={
        representatives && (
          <Box width="100%">
            <Divider sx={{ bgcolor: 'textColor.main', mb: 1.5, mt: 0 }} />
            <Typography variant="body2" color="textColor.light">
              {representativesLabel}
            </Typography>
            <AvatarGroup users={representatives} />
          </Box>
        )
      }
    />
  );
};
