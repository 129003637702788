import { Box, Card as MuiCard, CardActions, CardContent, CardHeader, SxProps } from '@mui/material';

import { useResolution } from '../../hooks';
import CardGradient from '../CardGradient';

interface Props {
  title: React.ReactNode;
  subtitle: React.ReactNode | null;
  headerActions?: React.ReactNode;
  avatar?: React.ReactNode;
  content: React.ReactNode;
  actions?: React.ReactNode;
  cardGradient?: string;
  sx?: SxProps;
  'data-testid'?: string;
}

const Card = ({
  title,
  subtitle,
  headerActions,
  avatar,
  content,
  actions,
  cardGradient,
  sx,
  'data-testid': dataTestId,
}: Props) => {
  const { isMobile, isSmallMobile } = useResolution();

  return (
    <Box
      data-testid={dataTestId}
      sx={[
        (theme) => ({
          height: cardGradient ? `calc(100% - ${theme.spacing(4)})` : '100%',
          minWidth: isMobile || isSmallMobile ? theme.dimensions.mobileCardWidth : theme.dimensions.cardWidth,
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {cardGradient && <CardGradient text={cardGradient} />}
      <MuiCard
        sx={({ customShadows }) => ({
          borderRadius: 4,
          boxShadow: customShadows.main,
          height: '100%',
          p: 0.5,
        })}
      >
        <Box display="flex" flexDirection="column" height="100%">
          <CardHeader
            sx={{
              '& .MuiCardHeader-avatar': {
                height: 52,
                width: 52,
              },

              '& .MuiCardHeader-content': {
                overflow: 'hidden',
              },
              pb: 0,
            }}
            avatar={avatar}
            action={headerActions}
            title={title}
            subheader={subtitle}
          />
          <CardContent sx={{ flexGrow: 1, py: 0 }}>{content}</CardContent>
          {actions && <CardActions sx={{ justifyContent: 'space-between', pb: 2, px: 2 }}>{actions}</CardActions>}
        </Box>
      </MuiCard>
    </Box>
  );
};

export default Card;
