import logger from 'logger';
import { useTranslation } from 'next-i18next';
import { DetailsPageTitle, PageSection, Stack } from 'ui';

import { RecentInvoiceList } from '~/invoice';
import { GoBackButton, NextLink } from '~/ui';

import { useMeteringPoint } from '../../hooks/useMeteringPoint/useMeteringPoint';
import { MeteringPointDetailedData } from '../list';

interface Props {
  id: string;
}

const MeteringPointDetails = ({ id }: Props) => {
  const { t } = useTranslation();

  const {
    data: { meteringPoint, contacts, invoices },
  } = useMeteringPoint({ meteringPointId: id });

  if (!meteringPoint) {
    logger.debug('Metering point not found');
    return null;
  }

  return (
    <>
      <DetailsPageTitle
        leftButtons={<GoBackButton />}
        subtitle={meteringPoint.customer.name}
        title={meteringPoint.name || ''}
      />

      <Stack spacing={4} mt={4}>
        <MeteringPointDetailedData meteringPoint={meteringPoint} contacts={contacts} />

        <PageSection
          title={t('invoices:invoiceAppendices')}
          subtitle={<NextLink href="/invoices">{t('seeMore')}</NextLink>}
        >
          <RecentInvoiceList list={invoices} />
        </PageSection>
      </Stack>
    </>
  );
};

export default MeteringPointDetails;
