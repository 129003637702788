import { useTranslation } from 'next-i18next';
import { Box, Button } from 'ui';

import { useCreateCustomer } from '~/customer';
import { isCompletedOnboardingCustomer } from '~/services/firestore';
import { StepTitle } from '~/ui';

import { AccountManager } from '../BusinessConfirmationStep/AccountManager';
import { Address } from './Address';
import { BillingContact } from './BillingContact';
import { CustomerType } from './CustomerType';
import { Name } from './Name';
import { PersonalId } from './PersonalId';

export const ResidentConfirmationStep = () => {
  const { t } = useTranslation('createCustomerWizard');
  const { getCustomerData, setCurrentStep, onCompletion, setRedirectTo, navigatorSteps } = useCreateCustomer();

  const customerData = getCustomerData();

  const editStep = (step: string) => {
    setCurrentStep(step);
    setRedirectTo('residentConfirmation');
  };

  return (
    <Box height="100%" display="flex" flex={1} flexDirection="column" justifyContent="space-between">
      <Box>
        <Box mb={2} ml={1}>
          <StepTitle>{t('steps.confirmation.title')}</StepTitle>
        </Box>

        <Box display="flex" flexDirection="column" gap={3}>
          {navigatorSteps.includes('accountManager') && <AccountManager onClick={editStep} />}

          <CustomerType onClick={editStep} />

          <Name onClick={editStep} />

          <PersonalId onClick={editStep} />

          <Address onClick={editStep} />

          <BillingContact onClick={editStep} />
        </Box>
      </Box>

      <Box>
        <Button
          size="lg"
          fullWidth
          onClick={onCompletion}
          disabled={!isCompletedOnboardingCustomer(customerData)}
          data-testid="confirmation-button"
          color="gradient"
        >
          {t('steps.confirmation.button')}
        </Button>
      </Box>
    </Box>
  );
};
