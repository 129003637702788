import { Trans } from 'next-i18next';
import { Typography } from 'ui';

import { NextLink } from '~/ui';

export const AuthTranslation = ({ i18nKey, href }: { i18nKey: string; href: string }) => {
  return (
    <Trans
      i18nKey={i18nKey}
      components={[<Typography display="inline" fontSize={14} />, <NextLink href={href} fontSize={14} />]}
    />
  );
};
