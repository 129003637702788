const METERING_POINTS_REGEX = {
  ERP_GOLDEN_SANDS: /^(32Z5[A-Za-z0-9]{12})$/,
  ERP_NORTH: /^(32Z4[A-Za-z0-9]{12})$/,
  ERP_SOUTH: /^(\d{7})$/,
  ERP_WEST: /^(32Z1[A-Za-z0-9]{12})$/,
  ESO: /^(32X[A-Za-z0-9]{13})$/,
};

const isErpNorth = (meteringPointNumber: string | undefined): boolean => {
  return !meteringPointNumber ? false : METERING_POINTS_REGEX.ERP_NORTH.test(meteringPointNumber);
};

const isErpWest = (meteringPointNumber: string | undefined): boolean => {
  return !meteringPointNumber ? false : METERING_POINTS_REGEX.ERP_WEST.test(meteringPointNumber);
};

const isErpSouth = (meteringPointNumber: string | undefined): boolean => {
  return !meteringPointNumber ? false : METERING_POINTS_REGEX.ERP_SOUTH.test(meteringPointNumber);
};
const isErpGoldenSands = (meteringPointNumber: string | undefined): boolean => {
  return !meteringPointNumber ? false : METERING_POINTS_REGEX.ERP_GOLDEN_SANDS.test(meteringPointNumber);
};
const isErpEso = (meteringPointNumber: string | undefined): boolean => {
  return !meteringPointNumber ? false : METERING_POINTS_REGEX.ESO.test(meteringPointNumber);
};

export { isErpEso, isErpGoldenSands, isErpNorth, isErpSouth, isErpWest };
