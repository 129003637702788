import { LayoutSwitcher as UiLayoutSwitcher } from 'ui';

import { useLayoutStore } from '~/store';

import { LayoutType } from '../types';

export const LayoutSwitcher = () => {
  const { layout, setLayout } = useLayoutStore();

  const handleLayoutChange = (_e: React.MouseEvent<HTMLElement>, newValue: LayoutType | null) => {
    if (newValue === null) {
      // prevents from unselecting an already selected button when clicked
      return;
    }
    setLayout(newValue);
  };

  return <UiLayoutSwitcher value={layout} onChange={handleLayoutChange} data-testid="layout-switcher" />;
};
