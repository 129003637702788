import { auth } from 'firebase-client';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

export type ImpersonatedUser = {
  email: string;
  customerIds: string[];
};

export type AuthUser = {
  id: string;
  email: string;
  impersonatedUser?: ImpersonatedUser | undefined;
};

type Session = {
  user: AuthUser | undefined;
  isLoading: boolean;
};

type AuthContext = {
  isLoading: boolean;
  isAuthenticated: () => boolean;
  getUser: () => AuthUser | undefined;
  getToken: () => Promise<string> | undefined;
  getImpersonatedUser: () => ImpersonatedUser | undefined;
  logout: () => void;
  refreshToken: () => Promise<string>;
};

const defaultAuthContext: AuthContext = {
  getImpersonatedUser: () => undefined,
  getToken: () => undefined,
  getUser: () => undefined,
  isAuthenticated: () => false,
  isLoading: false,
  logout: () => undefined,
  refreshToken: async () => '',
};

const АuthUserContext = createContext<AuthContext>(defaultAuthContext);

export const AuthUserProvider = ({ children }: { children: React.ReactNode }) => {
  const [session, setSession] = useState<Session>({
    isLoading: true,
    user: undefined,
  });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (userState) => {
      const token = await userState?.getIdToken();
      const impersonatedUser =
        ((await userState?.getIdTokenResult())?.claims?.impersonated_user as ImpersonatedUser) || undefined;

      if (token && userState?.uid && userState.email) {
        setSession({
          isLoading: false,
          user: { email: userState.email, id: userState.uid, impersonatedUser },
        });

        const { setUser: setLoggerUser } = await import('logger');
        setLoggerUser({
          email: userState.email,
          id: userState.uid,
        });

        const { setUser: setRumUser } = await import('rum');
        setRumUser({
          email: userState.email,
          id: userState.uid,
        });
      } else {
        setSession({
          isLoading: false,
          user: undefined,
        });
      }
    });

    return () => unsubscribe();
  }, []);

  const getUser = useCallback((): AuthUser | undefined => session?.user, [session]);

  const getToken = useCallback((): Promise<string> | undefined => auth.currentUser?.getIdToken(), []);

  const getImpersonatedUser = useCallback(() => session?.user?.impersonatedUser, [session]);

  const isAuthenticated = useCallback(() => !!(session?.user?.id && session?.user?.email), [session]);

  const logout = useCallback(async () => {
    const { logoutUser } = await import('../utils/logoutUser');

    await logoutUser();
  }, []);

  const refreshToken = useCallback(async () => {
    const token = await auth.currentUser?.getIdToken(true);

    if (!token) {
      throw new Error('No token');
    }

    return token;
  }, []);

  const context = useMemo(
    () => ({
      getImpersonatedUser,
      getToken,
      getUser,
      isAuthenticated,
      isLoading: session.isLoading,
      logout,
      refreshToken,
    }),
    [logout, isAuthenticated, session.isLoading, getUser, getToken, getImpersonatedUser, refreshToken]
  );

  return <АuthUserContext.Provider value={context}>{children}</АuthUserContext.Provider>;
};

export const useAuthUser = () => useContext(АuthUserContext);
