import { useSuspenseQuery } from '@tanstack/react-query';

import { BaseHTTPResponse, Contact, createDataCacheKeys, ListOptions, Page } from '~/data';
import { ResponseError, useCustomerApi } from '~/data/api';

const getCacheKey = (customerId: string, listOptions: ListOptions = {}) =>
  createDataCacheKeys(`customers/${customerId}/contacts`).list(listOptions);

type UseCustomerContactsResult = BaseHTTPResponse<Page<Contact>>;

type CustomerContactsOptions = {
  customerId: string;
};

export const useCustomerContacts = ({ customerId }: CustomerContactsOptions): UseCustomerContactsResult => {
  const api = useCustomerApi();

  const { data, error, isLoading, isError, isSuccess } = useSuspenseQuery<Page<Contact>, ResponseError>({
    queryFn: () => api.getContacts(customerId, { page: 1, size: 5 }),
    queryKey: getCacheKey(customerId),
  });

  return {
    data,
    error,
    isError,
    isLoading,
    isSuccess,
  };
};
