import { useMutation } from '@tanstack/react-query';

import { useAuthUser } from '~/auth';

interface Props {
  message: string;
  token: string;
}

const sendMail = async ({ message, token }: Props) => {
  const response = await fetch('/api/sendmail', {
    body: JSON.stringify({ message }),
    headers: { Authorization: token, 'content-type': 'application/json' },
    method: 'POST',
  });

  if (!response.ok) {
    throw new Error(`Error in fetch: ${response.statusText}`);
  }

  return response;
};

export const useSendMail = () => {
  const { getToken } = useAuthUser();

  const { mutateAsync: sendCustomerInquiry, ...result } = useMutation({
    mutationFn: async ({ message }: { message: string }) => {
      const token = await getToken();

      if (!token) {
        return Promise.reject(new Error('User is undefined'));
      }

      return sendMail({ message, token });
    },
  });

  return {
    sendCustomerInquiry,
    ...result,
  };
};
