import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { Loader } from '../Loader';

interface ScrollableViewProps {
  listComponent: React.ReactNode;
  isLoading: boolean;
  fetchNextPage: () => void;
}

const ScrollableView = ({ listComponent, isLoading, fetchNextPage }: ScrollableViewProps) => {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  return (
    <>
      {listComponent}
      {isLoading && (
        <Box my={4} display="flex" flex={1} alignItems="center" justifyContent="center">
          <Loader />
        </Box>
      )}
      <Box ref={ref} />
    </>
  );
};

export default ScrollableView;
