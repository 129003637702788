import { Address } from '@toki-power/public-api';

import { Contact, Customer, MeteringPoint, OnboardingRequest, PartiallyCompletedOnboardingRequest } from './types';

export const isPartiallyCompletedOnboardingRequest = (
  request: OnboardingRequest
): request is PartiallyCompletedOnboardingRequest =>
  Boolean(request.accountData) && Boolean(request.meteringPointsData) && Boolean(request.customerData);

export const isCompletedAddress = (address: Address) =>
  Boolean(address.addressLine1) && Boolean(address.settlement) && Boolean(address.region) && Boolean(address.postCode);

export const isCompletedContact = (contact: Contact) =>
  Boolean(contact.firstName) && Boolean(contact.lastName) && Boolean(contact.email) && Boolean(contact.phone);

const isCompletedIndividualOnboardingCustomer = (customer: Customer) =>
  Boolean(customer.customerType) &&
  Boolean(customer.firstName) &&
  Boolean(customer.middleName) &&
  Boolean(customer.lastName) &&
  Boolean(customer.personalId) &&
  isCompletedAddress(customer.address);

const hasRepresentatives = (representatives: Customer['representatives']) =>
  representatives.length > 0 &&
  representatives.every(
    (representative) =>
      Boolean(representative.firstName) && Boolean(representative.middleName) && Boolean(representative.lastName)
  );

const isCompletedBusinessOnboardingCustomer = (customer: Customer) =>
  Boolean(customer.legalEntityName) &&
  Boolean(customer.customerType) &&
  Boolean(customer.eik) &&
  hasRepresentatives(customer.representatives) &&
  isCompletedAddress(customer.address) &&
  isCompletedContact(customer.contact);

export const isCompletedOnboardingCustomer = (customer: Customer) =>
  customer.customerType === 'person'
    ? isCompletedIndividualOnboardingCustomer(customer)
    : isCompletedBusinessOnboardingCustomer(customer);

export const isCompletedMeteringPoint = (meteringPoint: MeteringPoint) =>
  Boolean(meteringPoint.address) &&
  isCompletedAddress(meteringPoint.address) &&
  Boolean(meteringPoint.name) &&
  Boolean(meteringPoint.meteringPointId);
