import { GrowthBook } from '@growthbook/growthbook';
import { NextApiRequest, NextApiResponse } from 'next';

import { initServerGrowthBook } from './growthbook';

type GrowthBookHandler = (req: NextApiRequest, res: NextApiResponse, growthbook: GrowthBook) => Promise<void>;

const growthbookMiddleware = (handler: GrowthBookHandler) => async (req: NextApiRequest, res: NextApiResponse) => {
  const gb = initServerGrowthBook();

  // Clean up at the end of the request
  res.on('close', () => gb.destroy());

  try {
    await gb.loadFeatures({ autoRefresh: true });
  } catch (e: unknown) {
    // eslint-disable-next-line no-console
    console.error(e);
  } finally {
    await handler(req, res, gb);
  }
};

export default growthbookMiddleware;
