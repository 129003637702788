import { useTranslation } from 'next-i18next';

import { useCreateCustomer } from '~/customer';
import { DataSection } from '~/ui';

import { Value } from '../Value';

interface Props {
  onClick: (step: string) => void;
}

export const CustomerType = ({ onClick }: Props) => {
  const { t } = useTranslation('createCustomerWizard');
  const { getCustomerData } = useCreateCustomer();
  const { customerType } = getCustomerData();

  return (
    <DataSection
      label={t('steps.confirmation.customerType')}
      Value={
        <Value>{(customerType && t(`steps.confirmation.${customerType}`)) || t('steps.confirmation.noData')}</Value>
      }
      onClick={() => onClick('customerType')}
      error={!customerType ? t('steps.confirmation.error') : ''}
      data-testid="customer-type"
    />
  );
};
