import { useRouter } from 'next/router';

import { useAuthUser } from '~/auth';

interface SetUserTokenOptions {
  email: string;
  customerIds: string[];
  token: string;
  method: 'POST' | 'DELETE';
}

const setUserToken = async ({ email, customerIds, token, method }: SetUserTokenOptions): Promise<void> => {
  await fetch('/api/user-tokens/', {
    body: JSON.stringify({
      impersonatedUser: {
        customerIds,
        email,
      },
    }),
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    method,
  });
};

interface UseImpersonateOptions {
  email: string;
  customerIds: string[];
}

type UseImpersonateReturn = {
  startImpersonation: () => void;
  stopImpersonation: () => void;
};

export const useImpersonation = ({ email, customerIds }: UseImpersonateOptions): UseImpersonateReturn => {
  const { reload } = useRouter();
  const { refreshToken, getToken } = useAuthUser();

  const performImpersonationAction = async (action: (token: string) => Promise<void>) => {
    const token = await getToken();

    if (!token) {
      throw new Error('No token');
    }

    await action(token);
    await refreshToken();
    reload();
  };

  const startImpersonation = async () => {
    await performImpersonationAction(async (token) => {
      await setUserToken({ customerIds, email, method: 'POST', token });
    });
  };

  const stopImpersonation = async () => {
    await performImpersonationAction(async (token) => {
      await setUserToken({ customerIds, email, method: 'DELETE', token });
    });
  };

  return { startImpersonation, stopImpersonation };
};
