import { useInfiniteQuery, useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { LIST_PAGE_SIZE } from '~/config/constants';

import { BaseHTTPResponse, Page, PageParams } from '../../types';
import ResponseError from '../ResponseError';

interface UsePaginatedQuery<T> {
  cacheKey: unknown[];
  fetchAll: (pageParams: PageParams) => Promise<Page<T>>;
  itemsDecorator?: (items: T[]) => T[];
}

export type UsePaginatedQueryResult<T> = BaseHTTPResponse<Page<T>> & {
  fetchNextPage: () => void;
};

const getNextPageParam = <T>(lastPage: Page<T>) => {
  if (lastPage.page + 1 > lastPage.pages) {
    return undefined;
  }

  return lastPage.page + 1;
};

const select = <T>({ pages, itemsDecorator }: { pages: Page<T>[]; itemsDecorator: (items: T[]) => T[] }) => {
  const items = pages.flatMap((page) => itemsDecorator(page.items));
  return {
    items,
    page: pages[pages.length - 1].page,
    pages: pages[pages.length - 1].pages,
    size: pages[0].size,
    total: pages[0].total,
  };
};

export const useSuspensePaginatedQuery = <T>({
  cacheKey,
  fetchAll,
  itemsDecorator = (items) => items,
}: UsePaginatedQuery<T>) => {
  const { data, isFetchingNextPage, isLoading, isError, isSuccess, error, fetchNextPage } = useSuspenseInfiniteQuery<
    Page<T>,
    ResponseError,
    Page<T>
  >({
    getNextPageParam,
    initialPageParam: 1,
    queryFn: ({ pageParam }) => fetchAll({ page: pageParam as number, size: LIST_PAGE_SIZE }),
    queryKey: cacheKey,
    select: ({ pages }) => select({ itemsDecorator, pages }),
  });

  return {
    data,
    error,
    fetchNextPage,
    isError,
    isLoading: isFetchingNextPage || isLoading,
    isSuccess,
  };
};

export const usePaginatedQuery = <T>({
  cacheKey,
  fetchAll,
  itemsDecorator = (items) => items,
}: UsePaginatedQuery<T>) => {
  const { data, isFetchingNextPage, isLoading, isError, isSuccess, error, fetchNextPage } = useInfiniteQuery<
    Page<T>,
    ResponseError,
    Page<T>
  >({
    getNextPageParam,
    initialPageParam: 1,
    queryFn: ({ pageParam }) => fetchAll({ page: pageParam as number, size: LIST_PAGE_SIZE }),
    queryKey: cacheKey,
    select: ({ pages }) => select({ itemsDecorator, pages }),
  });

  return {
    data,
    error,
    fetchNextPage,
    isError,
    isLoading: isFetchingNextPage || isLoading,
    isSuccess,
  };
};
