import { ReactNode } from 'react';
import { Grid, TypographyOverflow, useResolution } from 'ui';

import { CompanyInvoiceDetails } from './CompanyInvoiceDetails';

interface Props {
  avatar: ReactNode;
  description: string;
  name: string;
  address: string;
  detailedData: string[];
}

export const CompanyAvatarDetails = ({ avatar, name, address, detailedData, description }: Props) => {
  const { isTablet } = useResolution();

  return (
    <>
      <TypographyOverflow
        variant="body1"
        letterSpacing={1}
        textTransform="uppercase"
        fontWeight="bold"
        sx={(theme) => ({ color: theme.palette.grey[500] })}
        lines={2}
        lineHeight={1}
        height={30}
      >
        {description}
      </TypographyOverflow>

      <Grid container spacing={1} mt={1}>
        {isTablet && (
          <Grid item xl={3} lg={4} md={5}>
            {avatar}
          </Grid>
        )}

        <Grid item xl={9} lg={8} md={7} xs={12}>
          <TypographyOverflow variant="h5" fontWeight="bold" mb={1} lines={2} lineHeight={1} height={45}>
            {name}
          </TypographyOverflow>
          <TypographyOverflow lines={2} height={45}>
            {address}
          </TypographyOverflow>

          {isTablet && <CompanyInvoiceDetails data={detailedData} />}
        </Grid>

        {!isTablet && (
          <Grid item xs={12}>
            <CompanyInvoiceDetails data={detailedData} />
          </Grid>
        )}
      </Grid>
    </>
  );
};
