import { ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';

import { GridView, SplitscreenOutlined } from '../../icons';

interface Props extends ToggleButtonGroupProps {
  value: 'grid' | 'table';
}

const LayoutSwitcher = (props: Props) => (
  <ToggleButtonGroup
    exclusive
    color="primary"
    sx={({ palette }) => ({
      backgroundColor: palette.common.white,
    })}
    {...props}
  >
    <ToggleButton value="grid" aria-label="grid">
      <GridView />
    </ToggleButton>
    <ToggleButton value="table" aria-label="table">
      <SplitscreenOutlined />
    </ToggleButton>
  </ToggleButtonGroup>
);

export default LayoutSwitcher;
