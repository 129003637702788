import { ReactNode, useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import { COLORS_ARRAY } from '../../constants';
import { ChartBaseProps } from '../../types';
import { centerTitle } from './centerTitle';
import { DefaultTooltip } from './DefaultTooltip';
import { renderCustomizedLabel } from './renderCustomizedLabel';
import { DonutPayload, DonutTooltipFormatter } from './types';

type ChartData<T> = T & { color?: string; name: string; value: number | string };

interface Props<T> extends ChartBaseProps {
  tooltipFormatter?: DonutTooltipFormatter;
  data: ChartData<T>[];
  innerRadius?: number;
  outerRadius?: number;
}
interface RenderTooltip {
  (payload: DonutPayload | undefined, tooltipFormatter?: DonutTooltipFormatter): ReactNode;
}

const renderTooltip: RenderTooltip = (payload, tooltipFormatter) => {
  if (payload) {
    return <DefaultTooltip payload={payload} tooltipFormatter={tooltipFormatter} />;
  }

  return null;
};

const DonutChart = <T,>({
  height = 260,
  tooltip,
  data,
  title,
  tooltipFormatter,
  customTooltip,
  innerRadius = 60,
  outerRadius = 100,
}: Props<T>) => {
  const [active, setActive] = useState(false);
  const [payload, setPayload] = useState<DonutPayload>();

  const handleMouseEnter = () => {
    setActive(true);
  };

  const handleMouseLeave = () => {
    setActive(false);
  };

  return (
    <ResponsiveContainer width="100%" height={height}>
      <PieChart>
        <Pie
          startAngle={210}
          endAngle={-160}
          data={data}
          dataKey="value"
          labelLine={false}
          cx="50%"
          cy="50%"
          blendStroke
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          label={(props) => renderCustomizedLabel({ ...props, handleMouseEnter, handleMouseLeave })}
          onMouseEnter={(props) => setPayload(props)}
          isAnimationActive={false}
        >
          {data.map(({ color }, i) => (
            <Cell
              key={`cell-${i}`}
              fill={color || COLORS_ARRAY[i]}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
          ))}
        </Pie>
        {title && (
          <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle" fontSize={18} fontWeight="bold">
            {centerTitle(title)}
          </text>
        )}
        {tooltip && (
          <Tooltip
            wrapperStyle={{
              visibility: active ? 'visible' : 'hidden',
              width: '100%',
            }}
            content={customTooltip || (() => renderTooltip(payload, tooltipFormatter))}
          />
        )}
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DonutChart;
