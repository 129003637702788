import { Box, Link } from '@mui/material';

const transition = {
  '&:hover': { cursor: 'pointer', transform: 'scale(1.05)', transition: 'all .2s ease' },
  '&:not(:hover)': { transition: 'all .15s ease' },
  textDecoration: 'none',
};

interface Props {
  href: string;
  children: React.ReactNode;
  withTransition?: boolean;
}

export const LinkContainer = ({ children, href, withTransition = true }: Props) => (
  <Box component={Link} href={href} sx={withTransition ? transition : {}}>
    {children}
  </Box>
);
