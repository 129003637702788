import { createDataCacheKeys, ImpersonalizedCustomer, ListOptions } from '~/data';
import { useCustomerApi, usePaginatedQuery, UsePaginatedQueryResult } from '~/data/api';

const getCacheKey = (q: string, listOptions: ListOptions = {}) =>
  createDataCacheKeys(`customer-search/${q}`).list(listOptions);

export type UseCustomerSearchResult = UsePaginatedQueryResult<ImpersonalizedCustomer>;

interface CustomerSearchOptions {
  q: string;
}

export const useCustomerSearch = ({ q }: CustomerSearchOptions): UseCustomerSearchResult => {
  const api = useCustomerApi();

  const { data, error, isLoading, isError, isSuccess, fetchNextPage } = usePaginatedQuery<ImpersonalizedCustomer>({
    cacheKey: [...getCacheKey(q)],
    fetchAll: async (pageParams) => api.search(q, { page: pageParams.page, size: pageParams.size }),
  });

  return {
    data: data || {
      items: [],
      page: 0,
      pages: 0,
      size: 0,
      total: 0,
    },
    error,
    fetchNextPage,
    isError,
    isLoading,
    isSuccess,
  };
};
