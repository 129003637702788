import { Box } from '@mui/material';

import { RoomOutlined } from '../../icons';
import { TypographyOverflow } from '../TypographyOverflow';

const CardAddress = ({ address }: { address: string }) => (
  <Box display="flex" alignItems="flex-start">
    <RoomOutlined sx={{ pr: 0.5 }} />

    <TypographyOverflow lines={2} height={40} variant="body2" color="textColor.light">
      {address}
    </TypographyOverflow>
  </Box>
);

export default CardAddress;
