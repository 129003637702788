import { FeatureApiResponse, GrowthBookProvider } from '@growthbook/growthbook-react';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';

import { useSetAttributesOnAuthChange } from '../hooks';
import { clientGrowthbook } from '../utils/growthbook';

// Let the GrowthBook instance know when the URL changes so the active
const updateGrowthBookURL = () => {
  clientGrowthbook.setURL(window.location.href);
};

const FeatureFlagsProvider = ({ children, payload }: { children: React.ReactNode; payload: FeatureApiResponse }) => {
  const router = useRouter();

  useSetAttributesOnAuthChange();

  const gb = useMemo(
    () =>
      clientGrowthbook.initSync({
        payload,
        streaming: true,
      }),
    [payload]
  );

  useEffect(() => {
    // Subscribe to route change events and update GrowthBook
    router.events.on('routeChangeComplete', updateGrowthBookURL);
    return () => router.events.off('routeChangeComplete', updateGrowthBookURL);
  }, [router.events]);

  return <GrowthBookProvider growthbook={gb}>{children}</GrowthBookProvider>;
};

export default FeatureFlagsProvider;
