import { FormProvider, SubmitHandler, TextInput, useForm, yupResolver } from 'forms';
import { useTranslation } from 'next-i18next';
import { useMemo, useState } from 'react';
import { addError, Errors } from 'rum';
import { Form, FormHelperText } from 'ui';
import { object, string } from 'yup';

import { NextLink } from '~/ui';

import { AuthSubmitButton } from '../shared';

interface FormValues {
  email: string;
  password: string;
}

const initialFormValues: FormValues = {
  email: '',
  password: '',
};

const Login = ({ onLogin }: { onLogin: () => void }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [helperText, setHelperText] = useState<string | null>(null);

  const resolver = useMemo(
    () =>
      yupResolver(
        object({
          email: string().required(t('validation:required')).email(t('validation:invalidEmail')),
          password: string().required(t('validation:required')),
        })
      ),
    [t]
  );

  const { handleSubmit, watch, ...rest } = useForm<FormValues>({
    defaultValues: initialFormValues,
    resolver,
  });

  const onSubmit: SubmitHandler<FormValues> = async ({ email, password }) => {
    setIsLoading(true);
    try {
      const { loginUserWithPassword } = await import('~/auth');
      await loginUserWithPassword({
        email,
        password,
      });
      await onLogin();
    } catch (error) {
      addError(Errors.AUTH_PASSWORD_LOGIN_FAILURE, error, {
        email,
        password,
      });
      setHelperText(t('auth:loginError'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...{ handleSubmit, watch, ...rest }}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          required
          margin="normal"
          fullWidth
          label={t('email')}
          autocomplete="email"
          name="email"
          autoFocus
          shrink
          data-testid="email"
        />

        <TextInput
          required
          margin="normal"
          fullWidth
          name="password"
          label={t('password')}
          type="password"
          autocomplete="password"
          data-testid="password"
          shrink
        />
        <NextLink fontSize={13} href="/reset-password">
          {t('auth:forgottenPassword')}
        </NextLink>
        {helperText && <FormHelperText error>{helperText}</FormHelperText>}

        <AuthSubmitButton isLoading={isLoading} label={t('auth:login')} data-testid="login-button" />
      </Form>
    </FormProvider>
  );
};

export default Login;
