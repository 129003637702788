import { useTranslation } from 'next-i18next';
import { Box } from 'ui';

import { useCreateCustomer } from '~/customer';
import { OptionCard, StepTitle } from '~/ui';

export const FillInOptionsStep = () => {
  const { t } = useTranslation();
  const { setCurrentStep, isCompany } = useCreateCustomer();

  return (
    <Box>
      <Box mb={2}>
        <StepTitle>{t('createCustomerWizard:fillInOptions.title')}</StepTitle>
      </Box>

      <Box mb={2}>
        <OptionCard
          title={t('createCustomerWizard:fillInOptions.fromInvoice.title')}
          description={t('createCustomerWizard:fillInOptions.fromInvoice.description')}
          onClick={() => setCurrentStep('invoiceUpload')}
          data-testid="invoice-upload"
        />
      </Box>

      <OptionCard
        title={t('createCustomerWizard:fillInOptions.manual.title')}
        description={t('createCustomerWizard:fillInOptions.manual.description')}
        onClick={() => setCurrentStep(isCompany() ? 'businessStepsPreview' : 'residentStepsPreview')}
        data-testid="manual"
      />
    </Box>
  );
};
