/* eslint-disable no-inline-styles/no-inline-styles */
import { FormProvider, SubmitHandler, useForm, yupResolver } from 'forms';
import { useTranslation } from 'next-i18next';
import { Box, Form } from 'ui';
import { object, string } from 'yup';

import { useCreateCustomer } from '~/customer';
import { ContinueButton, StepTitle } from '~/ui';

import { NameInputs } from './NameInputs';

interface FormValues {
  firstName: string;
  middleName: string;
  lastName: string;
}

const REPRESENTATIVE_INDEX = 1;

export const BusinessRepresentative2Step = () => {
  const { t } = useTranslation('createCustomerWizard');
  const { getCustomerData, setRepresentatives, setCurrentStep, navigateWithRedirect } = useCreateCustomer();

  const { representatives, currentRepresentativeIndex } = getCustomerData();

  const { firstName, middleName, lastName } = representatives[REPRESENTATIVE_INDEX]
    ? representatives[currentRepresentativeIndex]
    : {
        firstName: '',
        lastName: '',
        middleName: '',
      };

  const resolver = yupResolver(
    object({
      firstName: string().required(t('steps.businessRepresentative.fields.firstName.required')),
      lastName: string().required(t('steps.businessRepresentative.fields.lastName.required')),
      middleName: string().required(t('steps.businessRepresentative.fields.middleName.required')),
    })
  );

  const { handleSubmit, watch, formState, ...rest } = useForm<FormValues>({
    defaultValues: {
      firstName,
      lastName,
      middleName,
    },
    resolver,
  });

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    const newRepresentatives = !representatives[REPRESENTATIVE_INDEX]
      ? [
          ...representatives,
          {
            firstName: values.firstName,
            lastName: values.lastName,
            middleName: values.middleName,
          },
        ]
      : representatives;

    if (representatives[REPRESENTATIVE_INDEX]) {
      newRepresentatives[REPRESENTATIVE_INDEX] = {
        firstName: values.firstName,
        lastName: values.lastName,
        middleName: values.middleName,
      };
    }

    setRepresentatives(newRepresentatives, REPRESENTATIVE_INDEX);

    navigateWithRedirect(() => setCurrentStep('address'));
  };

  return (
    <FormProvider {...{ formState, handleSubmit, watch, ...rest }}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box display="flex" flexDirection="column">
          <Box mb={2}>
            <StepTitle>{t('steps.businessRepresentative.title')}</StepTitle>
          </Box>

          <Box display="flex" flexDirection="column" gap={1}>
            <NameInputs translationNamespace="businessRepresentative" />
          </Box>
        </Box>

        <ContinueButton data-testid="business-representative2-submit-button" disabled={!formState.isValid} />
      </Form>
    </FormProvider>
  );
};
