import { useSuspenseQuery } from '@tanstack/react-query';

import {
  BaseHTTPResponse,
  Contact,
  createDataCacheKeys,
  Customer,
  Invoice,
  MeteringPoint,
  transformMeteringPoints,
} from '~/data';
import { ResponseError, useCustomerApi } from '~/data/api';

import { useCustomerContacts } from '../useCustomerContacts/useCustomerContacts';
import { useCustomerInvoices } from '../useCustomerInvoices/useCustomerInvoices';
import { useCustomerMeteringPoints } from '../useCustomerMeteringPoints/useCustomerMeteringPoints';

const cacheKeys = createDataCacheKeys('customers');

type UseCustomerResult = Omit<BaseHTTPResponse<Customer>, 'data'> & {
  data: {
    customer: Customer | undefined;
    invoices: {
      items: Invoice[];
      total: number;
    };
    contacts: {
      items: Contact[];
      total: number;
    };
    meteringPoints: {
      items: MeteringPoint[];
      total: number;
    };
  };
};

type CustomerOptions = {
  customerId: string;
};

export const useCustomer = ({ customerId }: CustomerOptions): UseCustomerResult => {
  const api = useCustomerApi();

  const customer = useSuspenseQuery<Customer, ResponseError>({
    queryFn: () => api.get(customerId),
    queryKey: cacheKeys.detail(customerId),
  });

  const invoices = useCustomerInvoices({ customerId });
  const contacts = useCustomerContacts({ customerId });
  const meteringPoints = useCustomerMeteringPoints({ customerId });

  return {
    data: {
      contacts: {
        items: contacts.data.items || [],
        total: contacts.data.total || 0,
      },
      customer: customer.data,
      invoices: {
        items: invoices.data.items || [],
        total: invoices.data.total || 0,
      },
      meteringPoints: {
        items: transformMeteringPoints(meteringPoints.data?.items || []),
        total: meteringPoints.data?.total || 0,
      },
    },
    error: customer.error || invoices.error || contacts.error || meteringPoints.error,
    isError: customer.isError || invoices.isError || contacts.isError || meteringPoints.isError,
    isLoading: customer.isLoading || invoices.isLoading || contacts.isLoading || meteringPoints.isLoading,
    isSuccess: customer.isSuccess && invoices.isSuccess && contacts.isSuccess && meteringPoints.isSuccess,
  };
};
