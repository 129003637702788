import dayjs from 'dayjs';
import bgLocale from 'dayjs/locale/bg';
import enLocale from 'dayjs/locale/en-gb';

import formatDate from './formatDate';
import getDifference from './getDifference';
import isSameDate from './isSameDate';
import toMonthAndYearString from './toMonthAndYearString';
import toMonthNameAndYearString from './toMonthNameAndYearString';
import toTimeFromNow from './toTimeFromNow';
import toWeekDayAndDateString from './toWeekDayAndDateString';

export { Dayjs as TokiDate } from 'dayjs';
export {
  bgLocale,
  dayjs as createDate,
  enLocale,
  formatDate,
  getDifference,
  isSameDate,
  toMonthAndYearString,
  toMonthNameAndYearString,
  toTimeFromNow,
  toWeekDayAndDateString,
};
