export const isRegistered = async (email: string) => {
  const response = await fetch(`/api/emails/${email}`, {
    headers: { 'content-type': 'application/json' },
    method: 'GET',
  });

  if (!response.ok) {
    throw new Error(`Error: ${response.statusText}`);
  }

  const data = await response.json();

  return data.exists;
};
