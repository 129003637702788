import { useSuspenseQuery } from '@tanstack/react-query';

import { BaseHTTPResponse, createDataCacheKeys, ListOptions, MeteringPoint, Page } from '~/data';
import { ResponseError, useCustomerApi } from '~/data/api';

const getCacheKey = (customerId: string, listOptions: ListOptions = {}) =>
  createDataCacheKeys(`customers/${customerId}/metering-points`).list(listOptions);

type UseCustomerMeteringPointsResult = BaseHTTPResponse<Page<MeteringPoint>>;

type CustomerMeteringPointsOptions = {
  customerId: string;
};

export const useCustomerMeteringPoints = ({
  customerId,
}: CustomerMeteringPointsOptions): UseCustomerMeteringPointsResult => {
  const api = useCustomerApi();

  const { data, error, isLoading, isError, isSuccess } = useSuspenseQuery<Page<MeteringPoint>, ResponseError>({
    queryFn: () => api.getMeteringPoints(customerId, { page: 1, size: 5 }),
    queryKey: getCacheKey(customerId),
  });

  return {
    data,
    error,
    isError,
    isLoading,
    isSuccess,
  };
};
