import { Autocomplete, Props as AutocompleteProps } from '../Autocomplete/Autocomplete';
import { settlementsByRegion } from './settlements';

type Props = Omit<AutocompleteProps<string>, 'options'> & {
  region: keyof typeof settlementsByRegion;
};

export const SettlementAutocomplete = ({ region, ...rest }: Props) => {
  return <Autocomplete {...rest} options={settlementsByRegion[region]} />;
};
