import { Invoice } from '~/data';

const getInvoicePath = (invoice: Pick<Invoice, 'invoiceId' | 'parentId'>): string => {
  if (invoice.parentId) {
    return `/invoices/${invoice.parentId}?pointInvoiceAddendum=${invoice.invoiceId}`;
  }

  return `/invoices/${invoice.invoiceId}`;
};

export default getInvoicePath;
