import dayjs from 'dayjs';

import { DateInput } from '../types';

export const getDifference = ({
  dateA,
  dateB,
  unit,
}: {
  dateA: DateInput;
  dateB: DateInput;
  unit: 'hour' | 'day' | 'month' | 'year';
}) => dayjs(dateA).diff(dateB, unit);
