import {
  addDoc,
  doc,
  FieldValue,
  getDocs,
  limit,
  orderBy,
  query,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { createCollection } from 'firebase-client';

import { CompletedOnboardingRequest, OnboardingRequest } from './types';

const collection = createCollection('onboarding');

const getOnboardingRequest = async (userId: string): Promise<{ data: OnboardingRequest; requestId: string }> => {
  const docs = query(collection, where('userId', '==', userId), orderBy('createdOn', 'desc'), limit(1));

  const docsSnap = await getDocs(docs);

  if (docsSnap.empty) {
    throw new Error(`Request not found for user with id: ${userId}`);
  }

  const request = docsSnap.docs[0];
  return { data: request.data(), requestId: request.id };
};

const createOnboardingRequest = async (formData: CompletedOnboardingRequest) => {
  const createdOn = Timestamp.now();
  const request = {
    ...formData,
    createdOn,
  };
  const { id } = await addDoc(collection, request);
  return id;
};

const updateOnboardingRequest = async (
  userId: string,
  fields: { [x: string]: FieldValue | Partial<unknown> | null }
): Promise<void> => {
  const { requestId } = await getOnboardingRequest(userId);
  const docRef = doc(collection, requestId);

  return updateDoc(docRef, fields);
};

export { createOnboardingRequest, getOnboardingRequest, updateOnboardingRequest };
