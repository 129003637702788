import { Steps } from '~/utils';

import { NavigatorStep } from './types';

export const NAVIGATOR_STEPS: NavigatorStep[] = [
  'accountOptions',
  'name',
  'address',
  'meteringPointId',
  'confirmation',
  'completion',
];

export const getNavigationSteps = (skipSteps: Steps = []): Steps[] => {
  return NAVIGATOR_STEPS.filter((step) => !skipSteps.includes(step));
};
