import { Configuration, GetAllMeteringPointsV2Request, MeteringPointApi } from '@toki-power/public-api';

import { transformEmptyStringToUndefined } from '../transformers';
import { MeteringPoint, Page, PageParams, PointInvoiceAddendum } from '../types';

export type MeteringPointsRequestOptions = GetAllMeteringPointsV2Request;

type MeteringPointReturnType = {
  get: (meteringPointId: string) => Promise<MeteringPoint>;
  getAll: (options: MeteringPointsRequestOptions) => Promise<Page<MeteringPoint>>;
  getInvoices: (meteringPointId: string, page: PageParams) => Promise<Page<PointInvoiceAddendum>>;
};

const DEFAULT_ORDER = '-created_date';
export const meteringPointApi = (config: () => Promise<Configuration>): MeteringPointReturnType => {
  const api = async () => new MeteringPointApi(await config());

  const getAll = async (requestParameters: MeteringPointsRequestOptions) =>
    (await api()).getAllMeteringPointsV2({
      customerType: requestParameters.customerType,
      orderBy: requestParameters.orderBy || DEFAULT_ORDER,
      ownerIdIn: transformEmptyStringToUndefined(requestParameters.ownerIdIn),
      page: requestParameters.page,
      search: transformEmptyStringToUndefined(requestParameters.search),
      size: requestParameters.size,
    });

  const get = async (meteringPointId: string) => (await (await api()).getMeteringPointV2({ meteringPointId })).data;

  const getInvoices = async (meteringPointId: string, { page, size }: PageParams) =>
    (await api()).getMeteringPointInvoicesV2({ meteringPointId, page, size });

  return {
    get,
    getAll,
    getInvoices,
  };
};
