import { signOut } from '@firebase/auth';
import { auth } from 'firebase-client';
import logger from 'logger';

export const logoutUser = async () => {
  try {
    await signOut(auth);

    logger.debug('Sign-out successful.');
  } catch (error) {
    logger.error('logoutUser error', { error });
    throw error;
  }
};
