import NextLink from 'next/link';
import { TFunction, useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { Button, DataTable, DataTableColumns, TypographyOverflow } from 'ui';

import { getAddress, MeteringPoint } from '~/data';

import getMeteringPointPath from '../../helpers/getMeteringPointPath';

type RenderProps = {
  row: MeteringPoint;
};

const MeteringPointPathButton = ({ row, t }: RenderProps & { t: TFunction }) => (
  <Button
    LinkComponent={NextLink}
    href={getMeteringPointPath(row)}
    color="limeGreen"
    size="lg"
    data-testid="see-more-metering-point"
  >
    {t('seeMore')}
  </Button>
);

const CellValue = ({ value }: { value: string }) => (
  <TypographyOverflow variant="body2" lines={2}>
    {value}
  </TypographyOverflow>
);

const CustomerCell = ({ row }: RenderProps) => <CellValue value={row.customer.name} />;

const NameCell = ({ row }: RenderProps) => <CellValue value={row.name || ''} />;

const AddressCell = ({ row }: RenderProps) => <CellValue value={row.address ? getAddress(row.address) : ''} />;

const buildTableColumns = (t: TFunction): DataTableColumns<MeteringPoint>[] => [
  {
    accessor: 'customer',
    header: t('meteringPoints:table.customerName'),
    value: CustomerCell,
  },
  { accessor: 'name', header: t('meteringPoints:table.name'), value: NameCell },
  {
    accessor: 'address',
    header: t('meteringPoints:table.address'),
    value: AddressCell,
  },
  { accessor: 'meteringPointId', header: t('meteringPoints:table.id') },
  { accessor: 'profile', header: t('meteringPoints:table.profile') },
  { accessor: 'erp', header: t('meteringPoints:table.erp') },
  { header: '', value: ({ row }) => <MeteringPointPathButton row={row} t={t} />, virtualColumn: 'path' },
];

export const MeteringPointTable = ({ list }: { list: MeteringPoint[] }) => {
  const { t } = useTranslation();

  const tableColumns = useMemo(() => buildTableColumns(t), [t]);

  return (
    <DataTable<MeteringPoint>
      columns={tableColumns}
      data-testid="metering-point-table"
      uid="meteringPointId"
      data={list}
    />
  );
};
