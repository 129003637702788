import { useResolution } from '../../hooks';
import { Box } from '../Box/Box';
import { TypographyOverflow } from '../TypographyOverflow';

interface Props {
  title: string;
  subtitle: string;
  align?: 'left' | 'center';
  color?: string;
}

const Title = ({ title, subtitle, align = 'center', color = 'text.disabled' }: Props) => {
  const { isTablet } = useResolution();

  return (
    <>
      <Box mb={1}>
        <TypographyOverflow
          textAlign={align}
          variant={isTablet ? 'h5' : 'h6'}
          fontWeight="bold"
          lines={2}
          lineHeight={1}
        >
          {title}
        </TypographyOverflow>
      </Box>

      <TypographyOverflow textAlign={align} variant="subtitle1" color={color} lineHeight={1} lines={2}>
        {subtitle}
      </TypographyOverflow>
    </>
  );
};

export default Title;
