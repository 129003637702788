import { useTranslation } from 'next-i18next';

import { useCreateCustomer } from '~/customer';
import { DataSection } from '~/ui';

import { Value } from '../Value';

interface Props {
  onClick: (step: string) => void;
}

export const BillingContact = ({ onClick }: Props) => {
  const { t } = useTranslation('createCustomerWizard');
  const { getCustomerData } = useCreateCustomer();
  const { billingContact } = getCustomerData();

  const billingContactData = [
    billingContact.firstName || billingContact.lastName
      ? `${billingContact.firstName} ${billingContact.lastName}`
      : null,
    billingContact.email,
    billingContact.phone.value ? `${billingContact.phone.countryCode}${billingContact.phone.value}` : null,
  ].filter(Boolean);

  return (
    <DataSection
      label={t('steps.confirmation.billingContact')}
      Value={
        <Value>
          {billingContactData.length > 0 ? billingContactData.join(', ') : t('steps.confirmation.sameAsContact')}
        </Value>
      }
      onClick={() => onClick('billingContactPerson')}
      data-testid="billing-contact"
    />
  );
};
