import { Download } from '../../icons';
import { Button } from '../Button';
import { ButtonProps } from '../Button/types';

interface Props extends Omit<ButtonProps, 'startIcon'> {
  downloadUrl: string;
}

export const DownloadButton = ({ downloadUrl, children, onClick, ...rest }: Props) => (
  <Button
    onClick={(event) => {
      event.preventDefault();
      window.open(downloadUrl, '_blank', 'noopener noreferrer');
      onClick?.(event);
    }}
    startIcon={<Download />}
    {...rest}
  >
    {children}
  </Button>
);
