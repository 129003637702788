import { useQuery } from '@tanstack/react-query';

import { useAuthUser } from '~/auth';
import { createDataCacheKeys } from '~/data';
import { useCustomerApi } from '~/data/api';

import { BaseHTTPResponse, CustomerProfileType } from '../../types';

type UseProfileTypeResult = Omit<BaseHTTPResponse<CustomerProfileType | undefined>, 'error'> & {
  error: Error | null;
  isProducer: () => boolean;
  isConsumer: () => boolean;
};

const cacheKeys = createDataCacheKeys('profileType');

export const useProfileType = (): UseProfileTypeResult => {
  const { isAuthenticated } = useAuthUser();
  const api = useCustomerApi();

  const { data, isLoading, isError, isSuccess, error } = useQuery({
    enabled: isAuthenticated(),
    queryFn: () => api.getCustomerType(),
    queryKey: [cacheKeys.detail('profile')],
  });

  const isProducer = () => data?.profileType === 'Producer';

  const isConsumer = () => data?.profileType === 'Consumer';

  return {
    data,
    error,
    isConsumer,
    isError,
    isLoading,
    isProducer,
    isSuccess,
  };
};
