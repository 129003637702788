import { createDataCacheKeys, Invoice } from '~/data';
import {
  InvoiceFilter,
  InvoiceOrderBy,
  useInvoiceApi,
  UsePaginatedQueryResult,
  useSuspensePaginatedQuery,
} from '~/data/api';

const cacheKeys = createDataCacheKeys('invoices');

export type InvoicesOptions = {
  orderBy?: InvoiceOrderBy;
  filterBy?: InvoiceFilter;
  size?: number;
};

export type UseInvoicesResult = UsePaginatedQueryResult<Invoice>;

export const useInvoices = (
  { orderBy, filterBy, size }: InvoicesOptions = { filterBy: undefined, orderBy: '-invoice_date' }
): UseInvoicesResult => {
  const api = useInvoiceApi();

  const { data, isLoading, isError, isSuccess, error, fetchNextPage } = useSuspensePaginatedQuery<Invoice>({
    cacheKey: [...cacheKeys.list({ filterBy, orderBy })],
    fetchAll: (pageParams) =>
      api.getAll({ filter: filterBy, orderBy, page: { page: pageParams.page, size: size || pageParams.size } }),
  });

  return {
    data,
    error,
    fetchNextPage,
    isError,
    isLoading,
    isSuccess,
  };
};
