import { Box, Stack, Typography } from '@mui/material';

interface Props {
  title: string;
  subtitle?: React.ReactNode;
  actions?: React.ReactNode;
  children: React.ReactNode;
  'data-testid'?: string;
}

const PageSection = ({ title, subtitle, children, actions, ...rest }: Props) => {
  return (
    <Box my={2} {...rest}>
      <Stack direction="row" justifyContent="space-between" alignItems="end">
        <Stack direction="row" gap={2} alignItems="end">
          <Typography variant="h5" fontWeight="bold">
            {title}
          </Typography>

          {subtitle}
        </Stack>

        <Box>{actions}</Box>
      </Stack>

      <Box mt={3}>{children}</Box>
    </Box>
  );
};

export default PageSection;
