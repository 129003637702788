import { ChartBaseProps, LineChart } from 'charts';
import { toMonthNameAndYearString } from 'dates';
import { IfFeatureEnabled } from 'feature-flag';
import { useTranslation } from 'next-i18next';
import { ReactElement } from 'react';
import { Box, Typography, TypographyOverflow } from 'ui';

import { getAddress, PointInvoiceAddendum } from '~/data';

import { InvoiceMeteringPointTable } from '../../../list';
import { MeteringPointData } from '../../MeteringPointData';

type ChartData = {
  date: string;
  value: number;
};

const CHART_DATA: ChartData[] = [
  { date: '01.01.2021', value: 10 },
  { date: '02.01.2021', value: 25 },
  { date: '03.01.2021', value: 50 },
  { date: '04.01.2021', value: 40 },
  { date: '05.01.2021', value: 70 },
];

const customTooltip: ChartBaseProps['customTooltip'] = ({ payload }) => {
  if (!payload?.length) {
    return <Box />;
  }

  const { date, value } = payload[0].payload;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-strat"
      padding={1}
      borderRadius={2}
      bgcolor="background.paper"
      sx={({ customShadows }) => ({ boxShadow: customShadows.main })}
    >
      <Typography variant="h6" fontWeight="bold">
        {date}
      </Typography>

      <Typography variant="subtitle1">{value}</Typography>
    </Box>
  );
};

interface Props {
  date: string;
  meteringPointInvoice: PointInvoiceAddendum;
}

export const InvoiceMeteringPointDetails = ({ date, meteringPointInvoice }: Props): ReactElement => {
  const { t } = useTranslation();
  const { meteringPointId, name, customer, erp, plan, profile, address } = meteringPointInvoice.meteringPoint;

  return (
    <Box width="100%">
      <Box
        display="flex"
        gap={{ md: 1 }}
        flexDirection={{ md: 'row', xs: 'column' }}
        justifyContent="flex-start"
        marginBottom={2}
      >
        <Typography variant="h4">{name}</Typography>

        <TypographyOverflow lines={5} variant="h6" color="textColor.light">
          ({customer.name})
        </TypographyOverflow>
      </Box>

      <Box
        display="flex"
        flexDirection={{ md: 'row', xs: 'column' }}
        justifyContent="space-between"
        alignItems={{ md: 'center', xs: 'flex-start' }}
        marginBottom={2}
      >
        <Box display="flex" flex={1} flexDirection="column">
          <MeteringPointData i18nKey="id" value={meteringPointId} />
          {erp && <MeteringPointData i18nKey="erp" value={erp} valueBold />}
          {plan && <MeteringPointData i18nKey="plan" value={plan} valueBold />}
        </Box>

        <Box display="flex" flex={1} flexDirection="column">
          {address && <MeteringPointData i18nKey="address" value={getAddress(address)} wrapped={false} />}
          {profile && <MeteringPointData i18nKey="profile" value={profile} valueBold />}
        </Box>
      </Box>

      <Box marginBottom={4}>
        <InvoiceMeteringPointTable
          items={meteringPointInvoice.lineItems}
          totalInvoiceCost={meteringPointInvoice.totalInvoiceCost}
        />
      </Box>

      <IfFeatureEnabled feature="daily_consumption_report">
        <Box padding={4} sx={{ border: '1px solid', borderColor: 'textColor.main', borderRadius: 4 }}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h5" fontWeight="bold">
              {t('invoices:dailyConsumptionReport')}
            </Typography>

            <Typography variant="h5" fontWeight="bold">
              {toMonthNameAndYearString({ date: new Date(date), locale: 'bg' })}
            </Typography>
          </Box>

          <LineChart<ChartData>
            data={CHART_DATA}
            config={[{ accessorKey: 'value' }]}
            xAxisKey="date"
            lineWidth={3}
            hasLegend={false}
            showXAxis={false}
            showYAxis={false}
            showGrid={false}
            tooltip
            customTooltip={customTooltip}
          />
        </Box>
      </IfFeatureEnabled>
    </Box>
  );
};
