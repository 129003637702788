/* eslint-disable no-inline-styles/no-inline-styles */
import { Autocomplete, FormProvider, SubmitHandler, TextInput, useForm, yupResolver } from 'forms';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { Box, Form } from 'ui';
import { object, string } from 'yup';

import { areaCities, areas, regions, settlements } from '~/config';
import { useCreateCustomer } from '~/customer';
import { ContinueButton, StepTitle } from '~/ui';

interface FormValues {
  area?: string;
  postCode: string;
  region: string;
  settlement: string;
  street: string;
}

export const CustomerAddressStep = () => {
  const { t } = useTranslation('createCustomerWizard');
  const { goToNextStep, getCustomerData, setAddress, navigateWithRedirect } = useCreateCustomer();

  const { address } = getCustomerData();

  const requiredMessage = t('steps.address.fields.required');

  const resolver = yupResolver(
    object({
      area: string().when('settlement', {
        is: (value: string) => areaCities.includes(value),
        then: () => string().required(requiredMessage).nullable(),
      }),
      postCode: string().required(requiredMessage),
      region: string().required(requiredMessage),
      settlement: string().required(requiredMessage),
      street: string().required(requiredMessage),
    })
  );

  const { handleSubmit, watch, resetField, formState, ...rest } = useForm<FormValues>({
    defaultValues: {
      area: address.area || '',
      postCode: address.postCode || '',
      region: address.region || '',
      settlement: address.settlement || '',
      street: address?.addressLine1 || '',
    },
    resolver,
  });

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    setAddress({
      addressLine1: values.street,
      addressLine2: '',
      area: values.area || '',
      municipality: '',
      postCode: values.postCode,
      region: values.region,
      settlement: values.settlement,
    });

    navigateWithRedirect(goToNextStep);
  };

  const region = watch('region');
  const settlement = watch('settlement');

  useEffect(() => {
    resetField('settlement');
    resetField('area');
  }, [region, resetField]);

  useEffect(() => {
    resetField('area');
  }, [settlement, resetField]);

  return (
    <FormProvider {...{ formState, handleSubmit, resetField, watch, ...rest }}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box display="flex" flexDirection="column">
          <Box mb={2}>
            <StepTitle>{t('steps.address.title')}</StepTitle>
          </Box>

          <Box mb={2}>
            <TextInput
              required
              autocomplete="post-code"
              data-testid="post-code"
              label={t('steps.address.fields.postCode.label')}
              name="postCode"
              placeholder={t('steps.address.fields.postCode.placeholder')}
              shrink
            />
          </Box>

          <Box mb={2}>
            <Autocomplete
              label={t(`steps.address.fields.region.label`)}
              data-testid="region"
              name="region"
              autocomplete="region"
              required
              options={regions}
              placeholder={t('steps.address.fields.region.placeholder')}
              shrink
            />
          </Box>

          <Box mb={2}>
            <Autocomplete
              label={t('steps.address.fields.settlement.label')}
              name="settlement"
              autocomplete="settlement"
              required
              disabled={!region}
              data-testid="settlement"
              options={region ? settlements[region] : []}
              placeholder={t('steps.address.fields.settlement.placeholder')}
              shrink
            />
          </Box>

          {areaCities.includes(settlement) && (
            <Box mb={2}>
              <Autocomplete
                label={t(`steps.address.fields.area.label`)}
                name="area"
                autocomplete="area"
                required
                disabled={!settlement}
                data-testid="area"
                options={settlement ? areas[settlement.split(' ')[1]] : []}
                placeholder={t('steps.address.fields.area.placeholder')}
                shrink
              />
            </Box>
          )}

          <Box mb={2}>
            <TextInput
              required
              data-testid="street"
              autocomplete="address"
              label={t('steps.address.fields.street.label')}
              name="street"
              placeholder={t('steps.address.fields.street.placeholder')}
              shrink
            />
          </Box>
        </Box>

        <ContinueButton data-testid="customer-address-submit-button" disabled={!formState.isValid} />
      </Form>
    </FormProvider>
  );
};
