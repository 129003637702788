const extractStringsBetweenSlashes = (input: string) => input.split('/').filter((str) => str !== '');

export const getReadableErpName = (input: string) => {
  const [, shortErpName] = extractStringsBetweenSlashes(input);

  if (!shortErpName) {
    return input;
  }
  return shortErpName;
};
