/* eslint-disable no-inline-styles/no-inline-styles */
import { FormProvider, PhoneInput, SubmitHandler, TextInput, useForm, validatePhoneNumber, yupResolver } from 'forms';
import { useTranslation } from 'next-i18next';
import { Phone } from 'types';
import { Box, Form } from 'ui';
import { mixed, object, string } from 'yup';

import { useCreateCustomer } from '~/customer';
import { ContinueButton, StepTitle } from '~/ui';

interface FormValues {
  firstName: string;
  lastName: string;
  phone: Phone;
  email: string;
}

export const BusinessContactPersonStep = () => {
  const { t } = useTranslation('createCustomerWizard');
  const { goToNextStep, getCustomerContact, setCustomerContact, setName, navigateWithRedirect, getCustomerData } =
    useCreateCustomer();

  const { phone, email } = getCustomerContact();
  const { firstName, lastName } = getCustomerData();

  const resolver = yupResolver(
    object({
      email: string().required(t('validation:required')).email(t('steps.contact.fields.email.invalid')),
      firstName: string().required(t('steps.contact.fields.firstName.required')),
      lastName: string().required(t('steps.contact.fields.lastName.required')),
      phone: mixed<Phone>()
        .defined()
        .test('phoneValidation', t('invalidPhone'), ({ country, countryCode, value }) =>
          validatePhoneNumber(country, countryCode, value)
        ),
    })
  );

  const { handleSubmit, watch, formState, ...rest } = useForm<FormValues>({
    defaultValues: {
      email,
      firstName,
      lastName,
      phone,
    },
    mode: 'onChange',
    resolver,
  });

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    setCustomerContact({
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
    });

    setName({
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: '',
    });

    navigateWithRedirect(goToNextStep);
  };

  return (
    <FormProvider {...{ formState, handleSubmit, watch, ...rest }}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box display="flex" flexDirection="column">
          <Box mb={2}>
            <StepTitle>{t('steps.contact.title')}</StepTitle>
          </Box>

          <Box display="flex" flexDirection="column" gap={1}>
            <TextInput
              required
              margin="normal"
              fullWidth
              label={t(`steps.contact.fields.firstName.label`)}
              placeholder={t(`steps.contact.fields.firstName.placeholder`)}
              name="firstName"
              autoFocus
              shrink
              data-testid="first-name"
            />

            <TextInput
              required
              margin="normal"
              fullWidth
              label={t(`steps.contact.fields.lastName.label`)}
              placeholder={t(`steps.contact.fields.lastName.placeholder`)}
              name="lastName"
              shrink
              data-testid="last-name"
            />

            <TextInput
              required
              margin="normal"
              fullWidth
              label={t(`steps.contact.fields.email.label`)}
              placeholder={t(`steps.contact.fields.email.placeholder`)}
              autocomplete="email"
              name="email"
              autoFocus
              shrink
              data-testid="email"
            />

            <PhoneInput
              required
              margin="normal"
              fullWidth
              label={t('steps.contact.fields.phone.placeholder')}
              autocomplete="phone"
              name="phone"
              autoFocus
              shrink
              data-testid="phone"
            />
          </Box>
        </Box>

        <ContinueButton data-testid="business-contact-submit-button" disabled={!formState.isValid} />
      </Form>
    </FormProvider>
  );
};
