import { AvatarGroup as MuiAvatarGroup } from '@mui/material';

import { Avatar, Color } from '../Avatar';

interface AvatarGroupProps {
  users: string[];
  color?: Color;
}

const AvatarGroup = ({ users, color }: AvatarGroupProps) => (
  <MuiAvatarGroup sx={{ justifyContent: 'left' }}>
    {users.map((user) => {
      return <Avatar key={user} bgColor={color} shape="circular" size="small" name={user} tooltip />;
    })}
  </MuiAvatarGroup>
);

export default AvatarGroup;
