import { Middleware, ResponseContext } from '../types';
import ResponseError from './ResponseError';

class ErrorHandlerMiddleware implements Middleware {
  // eslint-disable-next-line class-methods-use-this
  public async post(context: ResponseContext): Promise<void | Response> {
    return new Promise((resolve, reject) => {
      if (!context.response.ok) {
        reject(new ResponseError(context.response, context.response.statusText));
      }

      resolve(context.response);
    });
  }
}

export default ErrorHandlerMiddleware;
