import { Paper, useTheme } from 'ui';

import { Header } from './Header';

interface Props {
  title: string;
  description: string;
  children: React.ReactNode;
}

export const Section = ({ title, description, children }: Props) => {
  const { customShadows } = useTheme();

  return (
    <Paper
      sx={{
        boxShadow: customShadows.main,
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginBottom: 3,
        width: '100%',
      }}
    >
      <Header title={title} description={description} />

      {children}
    </Paper>
  );
};
