import { FormProvider, useForm, yupResolver } from 'forms';
import { useEffect, useMemo, useState } from 'react';
import { Box } from 'ui';
import { array, object, string } from 'yup';

import { useAuthUser } from '~/auth';

import { useCustomerSearch } from '../hooks/useCustomerSearch';
import { Actions } from './Actions';
import { Credentials } from './Credentials';
import { Customers } from './Customers';
import { CustomerSelector } from './CustomerSelector';
import { FormValues } from './types';

const resolver = yupResolver(
  object({
    user: object({
      customers: array()
        .of(
          object({
            customerId: string().required(),
            name: string().required(),
          })
        )
        .required(),
      email: string().required(),
    }),
  })
);

export const UserImpersonation = () => {
  const { getImpersonatedUser } = useAuthUser();
  const initialUser = getImpersonatedUser();

  const [q, setQ] = useState(initialUser?.email || '');
  const { data } = useCustomerSearch({ q });
  const users = useMemo(() => data?.items || [], [data]);

  const user = useMemo(
    () =>
      users.find((u) => u.email === initialUser?.email) || {
        customers: [],
        email: '',
      },
    [initialUser, users]
  );

  const { control, handleSubmit, watch, getValues, reset, formState, ...rest } = useForm<FormValues>({
    defaultValues: {
      user,
    },
    resolver,
  });

  const currentUser = watch('user');

  useEffect(() => {
    if (initialUser && initialUser.email === q) {
      setQ(initialUser.email);
      reset({ user });
    }
  }, [initialUser, reset, user, q]);

  return (
    <Box height="100%" display="flex" flex={1} flexDirection="column" mb={3}>
      <FormProvider {...{ control, formState, getValues, handleSubmit, reset, watch, ...rest }}>
        <CustomerSelector control={control} options={users} q={q} setQ={setQ} />
      </FormProvider>

      {currentUser?.customers?.length > 0 && (
        <>
          <Actions email={currentUser.email} customerIds={currentUser.customers.map((c) => c.customerId)} />

          <Credentials email={currentUser.email} />

          <Customers customerIds={currentUser.customers.map((c) => c.customerId)} />
        </>
      )}
    </Box>
  );
};
