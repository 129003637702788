import Link, { LinkProps } from 'next/link';
import { styled } from 'ui';

interface Props {
  href: string;
  children?: React.ReactNode;
  fontSize?: number;
}

const StyledLink = styled(Link)<LinkProps & { fontSize?: number }>(({ theme, fontSize }) => ({
  color: theme.palette.primary.main,
  fontSize: fontSize || 16,
  textDecoration: 'none',
}));

export const NextLink = ({ href, children, fontSize }: Props) => {
  return (
    <StyledLink href={href} fontSize={fontSize}>
      {children}
    </StyledLink>
  );
};
