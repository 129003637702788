import { Badge } from 'ui';
import { PowerOutlined } from 'ui/icons';

interface CustomerCountBadgeProps {
  count: number;
}

const CustomerCountBadge = ({ count }: CustomerCountBadgeProps) => {
  return (
    <Badge
      badgeContent={count}
      color="primary"
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      sx={(theme) => ({
        '& .MuiBadge-badge': { color: theme.palette.common.white, left: 1, top: 0, width: 10 },
      })}
    >
      <PowerOutlined />
    </Badge>
  );
};

export default CustomerCountBadge;
