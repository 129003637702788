import { DocumentData } from 'firebase/firestore';

import { Steps, useStepNavigator } from '~/utils';

import { getNavigationSteps } from './steps';
import { StepNavigatorActions } from './types';

export const useBusinessStepNavigator = ({
  skipSteps,
  onboardingData,
}: {
  skipSteps?: Steps;
  onboardingData?: DocumentData | null;
}): StepNavigatorActions => {
  const navigatorSteps = getNavigationSteps({ customerType: 'business', skipSteps });

  const customerData = onboardingData?.customerData;

  const {
    getCurrentStep,
    getHistory,
    goToNextStep,
    goToPreviousStep,
    setCurrentStep,
    setRedirectTo,
    navigateWithRedirect,
  } = useStepNavigator({
    initialStep: customerData?.customerType ? navigatorSteps[navigatorSteps.length - 1] : navigatorSteps[0],
    steps: navigatorSteps,
  });

  return {
    getCurrentStep,
    getHistory,
    goToNextStep,
    goToPreviousStep,
    navigateWithRedirect,
    navigatorSteps,
    setCurrentStep,
    setRedirectTo,
  };
};
