import { Configuration } from '@toki-power/public-api';

import { useAuthUser } from '~/auth';

import ErrorHandlerMiddleware from '../ErrorHandlerMiddleware';

type ApiConfigReturn = () => Promise<Configuration>;

const buildHeaders = (token: string | undefined) => {
  const userInfo = (token || '').split('.')[1];

  const commonHeaders = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };

  if (!process.env.NEXT_PUBLIC_BACKOFFICE_API_URL?.includes('localhost')) {
    return commonHeaders;
  }

  return {
    ...commonHeaders,
    'X-Apigateway-Api-Userinfo': userInfo,
  };
};

export const useApiConfig = (): ApiConfigReturn => {
  const { getToken } = useAuthUser();

  return async () => {
    const token = await getToken();

    return new Configuration({
      basePath: process.env.NEXT_PUBLIC_BACKOFFICE_API_URL,
      headers: {
        ...buildHeaders(token),
      },
      middleware: [new ErrorHandlerMiddleware()],
    });
  };
};
