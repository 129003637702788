import { Invoice } from '~/data';
import { useLayoutStore } from '~/store';
import { NoResultsMessage } from '~/ui';

import { InvoiceCardList } from './InvoiceCardList';
import { InvoiceTable } from './InvoiceTable';

export const InvoiceList = ({ list }: { list: Invoice[] }) => {
  const { layout } = useLayoutStore();

  if (!list) {
    return <NoResultsMessage />;
  }

  if (layout === 'table') {
    return <InvoiceTable list={list} />;
  }

  return <InvoiceCardList list={list} />;
};
