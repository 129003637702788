/* eslint-disable no-inline-styles/no-inline-styles */
import { Autocomplete, FormProvider, SubmitHandler, TextInput, useForm, yupResolver } from 'forms';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { Box, Form, Typography } from 'ui';
import { object, string } from 'yup';

import { energyUtilityOptions } from '~/config';
import { meteringPointLabel } from '~/config/meteringPointLabel';
import { useCreateMeteringPoint } from '~/meteringPoint';
import {
  isErpEso,
  isErpGoldenSands,
  isErpNorth,
  isErpSouth,
  isErpWest,
} from '~/meteringPoint/helpers/determineErp/determineErp';
import { ContinueButton, StepTitle } from '~/ui';

interface FormValues {
  meteringPointId: string;
  previousUtility?: string;
}

export const MeteringPointIdStep = () => {
  const { t } = useTranslation('createMeteringPointWizard');
  const {
    goToNextStep,
    getMeteringPoint,
    setMeteringPointId,
    currentMeteringPointIndex,
    navigateWithRedirect,
    setPreviousUtility,
  } = useCreateMeteringPoint();

  const { meteringPointId, address, previousUtility } = getMeteringPoint(currentMeteringPointIndex);
  const { region, settlement } = address;

  const invalidMeteringPointIdMessage = t('steps.meteringPointId.fields.meteringPointId.invalid');

  const regionLabel = meteringPointLabel[region as keyof typeof meteringPointLabel];

  const resolver = yupResolver(
    object({
      meteringPointId: string()
        .required(t('steps.meteringPointId.fields.meteringPointId.required'))
        .test('meteringPointValidation', invalidMeteringPointIdMessage, (value, { path, createError }) => {
          const conditions: { [key: string]: () => boolean } = {
            itn: () => isErpSouth(value) || isErpEso(value),
            meteringPoint: () => isErpWest(value) || isErpEso(value),
            uin: () =>
              settlement === 'к.к. Златни Пясъци'
                ? isErpGoldenSands(value) || isErpEso(value)
                : isErpNorth(value) || isErpEso(value),
          };

          if (!conditions[regionLabel]?.()) {
            return createError({ message: invalidMeteringPointIdMessage, path });
          }

          return true;
        }),
      previousUtility:
        regionLabel === 'meteringPoint'
          ? string().required(t('steps.meteringPointId.fields.previousUtility.required'))
          : string(),
    })
  );

  const { handleSubmit, watch, formState, ...rest } = useForm<FormValues>({
    defaultValues: {
      meteringPointId,
      previousUtility,
    },
    mode: 'onChange',
    resolver,
  });

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    setMeteringPointId(values.meteringPointId, currentMeteringPointIndex);

    if (values.previousUtility) {
      setPreviousUtility(values.previousUtility, currentMeteringPointIndex);
    }

    navigateWithRedirect(goToNextStep);
  };

  return (
    <FormProvider {...{ formState, handleSubmit, watch, ...rest }}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box display="flex" flexDirection="column">
          <Box mb={2}>
            <StepTitle>{t('steps.meteringPointId.title')}</StepTitle>
          </Box>

          <TextInput
            required
            margin="normal"
            fullWidth
            label={t('steps.meteringPointId.fields.meteringPointId.label')}
            placeholder={t('steps.meteringPointId.fields.meteringPointId.placeholder')}
            name="meteringPointId"
            autoFocus
            shrink
            data-testid="metering-point-id"
          />

          {regionLabel && (
            <Box mt={1}>
              <Typography>{t('steps.meteringPointId.meteringPointLabels.formats')}</Typography>
              <Typography>
                {t(
                  `steps.meteringPointId.meteringPointLabels.${settlement === 'к.к. Златни Пясъци' ? 'goldenSands' : regionLabel}`
                )}
              </Typography>
            </Box>
          )}

          {regionLabel === 'meteringPoint' && (
            <Box mt={3}>
              <Autocomplete
                label={t('steps.meteringPointId.fields.previousUtility.label')}
                name="previousUtility"
                placeholder={t('steps.meteringPointId.fields.previousUtility.placeholder')}
                required
                fullWidth
                shrink
                options={energyUtilityOptions}
                data-testid="previous-utility"
              />
            </Box>
          )}
        </Box>

        <ContinueButton data-testid="metering-point-id-submit-button" disabled={!formState.isValid} />
      </Form>
    </FormProvider>
  );
};
