import { Box } from '@mui/material';

import { Theme } from '../../theme';
import SeeMoreCard from '../SeeMoreCard';

type CardSize = 'sm' | 'md';

interface Props {
  elements: React.ReactNode[];
  cardWidth?: CardSize;
  maxElements?: number;
  totalElements?: number;
  seeMoreHref?: string;
}

type CardWidths = {
  [key in CardSize]: {
    big: keyof Theme['dimensions'];
    small: keyof Theme['dimensions'];
  };
};

const cardWidths: CardWidths = {
  md: {
    big: 'cardWidth',
    small: 'mobileCardWidth',
  },
  sm: {
    big: 'mobileCardWidth',
    small: 'mobileCardWidth',
  },
};

export const CardListContainer = ({ elements, cardWidth = 'md', maxElements, totalElements, seeMoreHref }: Props) => {
  const cardWidthKey = cardWidths[cardWidth];

  const cardsList = maxElements ? elements.slice(0, maxElements) : elements;

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gap: 3,
        gridTemplateColumns: `repeat(auto-fill, minmax(${theme.dimensions[cardWidthKey.big]}, 1fr))`,
        [theme.breakpoints.down('md')]: {
          gridTemplateColumns: `repeat(auto-fill, minmax(${theme.dimensions[cardWidthKey.small]}, 1fr))`,
        },
        justifyContent: 'center',
      })}
    >
      {cardsList}

      {Number(totalElements) > Number(maxElements) && (
        <SeeMoreCard count={Number(totalElements) - Number(maxElements)} href={seeMoreHref || ''} />
      )}
    </Box>
  );
};
