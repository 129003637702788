import logger from 'logger';
import { InvoiceData } from 'types';

const analyzeInvoice = async (invoice: File): Promise<InvoiceData> => {
  const formData = new FormData();
  formData.append('invoice', invoice);

  const analyzerUrl = process.env.NEXT_PUBLIC_ANALYZE_INVOICE_CLOUD_FUNCTION_URL;

  if (!analyzerUrl) {
    logger.error('NEXT_PUBLIC_ANALYZE_INVOICE_CLOUD_FUNCTION_URL is not set');
    throw new Error('NEXT_PUBLIC_ANALYZE_INVOICE_CLOUD_FUNCTION_URL is not set');
  }

  const response = await fetch(analyzerUrl, {
    body: formData,
    method: 'POST',
  });

  if (!response.ok) {
    logger.error('Error analyzing invoice', { response });
    throw new Error('Error analyzing invoice');
  }

  const responseData = response.json() as Promise<{ data: InvoiceData }>;

  return (await responseData).data;
};

export default analyzeInvoice;
