import {
  Bar,
  BarChart as BarRechart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { COLORS_ARRAY } from '../../constants';
import { ChartBaseProps, TooltipFormatter } from '../../types';
import { labelFormatter } from '../../utils';

interface Config<K> {
  name: string;
  color?: string;
  accessorKey: keyof K & string;
}

interface Props<T> extends ChartBaseProps {
  config: Config<T>[];
  data: T[];
  tooltipFormatter?: TooltipFormatter;
  legendAlign?: 'top' | 'bottom';
  xAxisKey: keyof T & string;
}

const BarChart = <T,>({
  config,
  customTooltip,
  data,
  height = 260,
  legendAlign = 'top',
  tooltipFormatter,
  title,
  tooltip,
  xAxisKey,
}: Props<T>) => {
  const isBottomLegend = legendAlign === 'bottom';

  return (
    <ResponsiveContainer width="100%" height={height}>
      <BarRechart
        data={data}
        barSize={10}
        margin={{
          bottom: 0,
          left: isBottomLegend ? 0 : 10,
          right: isBottomLegend ? 0 : 20,
          top: isBottomLegend ? 40 : 10,
        }}
      >
        <CartesianGrid strokeDasharray="0" vertical={false} />
        <XAxis dataKey={xAxisKey} axisLine={false} tickLine={false} />
        <YAxis axisLine={false} tickLine={false} />
        {tooltip && (
          <Tooltip
            cursor={{ fill: 'transparent' }}
            content={customTooltip}
            formatter={tooltipFormatter}
            labelFormatter={labelFormatter}
          />
        )}
        <Legend align="right" verticalAlign={legendAlign} iconSize={10} iconType="circle" />
        {config.map(({ accessorKey, color, name }, i) => (
          <Bar key={accessorKey} dataKey={accessorKey} fill={color || COLORS_ARRAY[i]} name={name} />
        ))}
        {title && (
          <text x="70" y="10" dominantBaseline="hanging" fontSize="18" fontWeight="bold">
            {title}
          </text>
        )}
      </BarRechart>
    </ResponsiveContainer>
  );
};

export default BarChart;
