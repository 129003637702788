import { formatDate } from 'dates';
import { useFeatureIsOn } from 'feature-flag';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { memo, useMemo } from 'react';
import { Button, DataTable, DataTableColumns, DownloadIconButton, Typography } from 'ui';

import { Invoice } from '~/data';

import getInvoicePath from '../../helpers/getInvoicePath';
import { recordInvoiceDownload } from '../../helpers/getInvoicePath/recordInvoiceDownload';

type RenderProps = {
  row: Invoice;
};

const DownloadInvoiceButton = ({ row }: RenderProps) =>
  row.pdfUrl && (
    <DownloadIconButton
      downloadUrl={row.pdfUrl}
      onClick={() => {
        recordInvoiceDownload(row);
      }}
    />
  );

const InvoicePathButton = memo(({ row }: RenderProps) => {
  const { t } = useTranslation('invoices');

  return (
    <Button LinkComponent={Link} href={getInvoicePath(row)} color="limeGreen" size="lg" data-testid="see-more-invoice">
      {t('table.goTo')}
    </Button>
  );
});

const CustomerName = ({ row }: RenderProps) => <Typography>{row.customer.name}</Typography>;

type CellRenderMap = {
  [key: string]: (props: RenderProps) => React.ReactNode;
};

const CELL_RENDER_MAP: CellRenderMap = {
  customer: CustomerName,
  downloadUrl: DownloadInvoiceButton,
  path: InvoicePathButton,
};

export const InvoiceTable = ({ list }: { list: Invoice[] }) => {
  const { t } = useTranslation('invoices');
  const isDownloadEnabled = useFeatureIsOn('invoice_download_pdf');

  const tableColumns = useMemo(() => {
    const columns: DataTableColumns<Invoice>[] = [
      { accessor: 'customer', header: t('table.customerName') },
      { accessor: 'invoiceNumber', header: t('table.invoiceNumber') },
      {
        accessor: 'invoiceDate',
        header: t('table.date'),
        value: ({ row }: { row: Invoice }) => formatDate({ date: row.invoiceDate }),
      },
      {
        accessor: 'paymentDeadline',
        header: t('table.dueDate'),
        value: ({ row }: { row: Invoice }) => formatDate({ date: row.paymentDeadline }),
      },
      { accessor: 'meteringPointsCount', header: t('table.objects') },
      { accessor: 'totalInvoiceCost', header: t('table.amount') },
      {
        accessor: 'status',
        header: t('table.status'),
        value: ({ row }: { row: Invoice }) =>
          t(`invoices:${row.status === 'Part-paid' ? 'partPaid' : row.status.toLowerCase()}`),
      },
    ];

    if (isDownloadEnabled) {
      columns.push({ header: t('table.download'), virtualColumn: 'downloadUrl' });
    }

    columns.push({ header: '', virtualColumn: 'path' });

    return columns;
  }, [isDownloadEnabled, t]);

  return (
    <DataTable<Invoice>
      columns={tableColumns}
      uid="invoiceNumber"
      data-testid="invoice-table"
      data={list}
      renderCell={(row, accessor) => {
        const Cell = CELL_RENDER_MAP[accessor];

        if (!Cell) {
          return null;
        }

        return <Cell row={row} />;
      }}
    />
  );
};
