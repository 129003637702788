import Link from 'next/link';
import React from 'react';
import { AppBar as UiAppBar, Logo } from 'ui';

import { AccountMenu } from './AccountMenu';

const ActionItems = () => <AccountMenu />;

const AppBar = () => {
  return (
    <UiAppBar>
      <UiAppBar.Logo component={Link} href="/">
        <Logo />
      </UiAppBar.Logo>

      <UiAppBar.Actions>
        <ActionItems />
      </UiAppBar.Actions>
    </UiAppBar>
  );
};

export default AppBar;
