import { TextInput } from 'forms';
import { useTranslation } from 'next-i18next';

interface NameInputsProps {
  translationNamespace: string;
}

export const NameInputs = ({ translationNamespace }: NameInputsProps) => {
  const { t } = useTranslation('createCustomerWizard');

  return (
    <>
      <TextInput
        required
        margin="normal"
        fullWidth
        label={t(`steps.${translationNamespace}.fields.firstName.label`)}
        placeholder={t(`steps.${translationNamespace}.fields.firstName.placeholder`)}
        name="firstName"
        autoFocus
        shrink
        data-testid={`${translationNamespace}-firstName`}
      />
      <TextInput
        required
        margin="normal"
        fullWidth
        label={t(`steps.${translationNamespace}.fields.middleName.label`)}
        placeholder={t(`steps.${translationNamespace}.fields.middleName.placeholder`)}
        name="middleName"
        shrink
        data-testid={`${translationNamespace}-middleName`}
      />
      <TextInput
        required
        margin="normal"
        fullWidth
        label={t(`steps.${translationNamespace}.fields.lastName.label`)}
        placeholder={t(`steps.${translationNamespace}.fields.lastName.placeholder`)}
        name="lastName"
        shrink
        data-testid={`${translationNamespace}-lastName`}
      />
    </>
  );
};
