import Link from 'next/link';
import { useRouter } from 'next/router';
import { Logo, Sidebar as UiSidebar } from 'ui';

import { NavigationOptions } from '../types';

interface Props {
  options: NavigationOptions[];
}

const Sidebar = ({ options }: Props) => {
  const { pathname } = useRouter();

  return (
    <UiSidebar>
      <UiSidebar.Logo component={Link} href="/">
        <Logo />
      </UiSidebar.Logo>
      <UiSidebar.Menu>
        {options.map(({ label, href, icon }, i) => (
          <UiSidebar.Item
            key={i}
            data-testid={`sidebar-item-${href.slice(1)}`}
            component={Link}
            href={href}
            icon={icon}
            selected={pathname === href}
          >
            {label}
          </UiSidebar.Item>
        ))}
      </UiSidebar.Menu>
    </UiSidebar>
  );
};

export default Sidebar;
