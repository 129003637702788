import { BillingPlans } from 'types';

import { Address, CustomerType } from '~/data';

import { CreateCustomerState } from './types';

type SetAccountManagerAction = {
  payload: {
    accountManager: string | undefined;
  };
  type: 'SET_ACCOUNT_MANAGER';
};

export const SET_ACCOUNT_MANAGER: SetAccountManagerAction = {
  payload: {
    accountManager: undefined,
  },
  type: 'SET_ACCOUNT_MANAGER',
};

type SetPlanIdAction = {
  payload: {
    planId: BillingPlans | undefined;
  };
  type: 'SET_PLAN_ID';
};

export const SET_PLAN_ID: SetPlanIdAction = {
  payload: {
    planId: undefined,
  },
  type: 'SET_PLAN_ID',
};

type SetCustomerTypeAction = {
  payload: {
    customerType: CustomerType;
  };
  type: 'SET_CUSTOMER_TYPE';
};

export const SET_CUSTOMER_TYPE: SetCustomerTypeAction = {
  payload: {
    customerType: 'person',
  },
  type: 'SET_CUSTOMER_TYPE',
};

type SetName = {
  payload: {
    firstName: string;
    middleName: string;
    lastName: string;
  };
  type: 'SET_NAME';
};

export const SET_NAME: SetName = {
  payload: {
    firstName: '',
    lastName: '',
    middleName: '',
  },
  type: 'SET_NAME',
};

type SetLegalEntityNameAction = {
  payload: {
    legalEntityName: string;
  };
  type: 'SET_LEGAL_ENTITY_NAME';
};

export const SET_LEGAL_ENTITY_NAME: SetLegalEntityNameAction = {
  payload: {
    legalEntityName: '',
  },
  type: 'SET_LEGAL_ENTITY_NAME',
};

type SetPersonalIdAction = {
  payload: {
    personalId: string;
  };
  type: 'SET_PERSONAL_ID';
};

export const SET_PERSONAL_ID: SetPersonalIdAction = {
  payload: {
    personalId: '',
  },
  type: 'SET_PERSONAL_ID',
};

type SetEIKAction = {
  payload: {
    eik: string;
  };
  type: 'SET_EIK';
};

export const SET_EIK: SetEIKAction = {
  payload: {
    eik: '',
  },
  type: 'SET_EIK',
};

type SetAddressAction = {
  payload: {
    address: Address;
  };
  type: 'SET_ADDRESS';
};

export const SET_ADDRESS: SetAddressAction = {
  payload: {
    address: {
      addressLine1: '',
      addressLine2: '',
      area: '',
      municipality: '',
      postCode: '',
      region: '',
      settlement: '',
    },
  },
  type: 'SET_ADDRESS',
};

type SetCustomerDataAction = {
  payload: CreateCustomerState['customerData'];
  type: 'SET_CUSTOMER_DATA';
};

type SetRepresentativesAction = {
  payload: {
    representatives: CreateCustomerState['customerData']['representatives'];
    currentRepresentativeIndex: number;
  };
  type: 'SET_REPRESENTATIVES';
};

export const SET_REPRESENTATIVES: SetRepresentativesAction = {
  payload: {
    currentRepresentativeIndex: 0,
    representatives: [],
  },
  type: 'SET_REPRESENTATIVES',
};

type SetCustomerContactAction = {
  payload: {
    contact: CreateCustomerState['customerData']['contact'];
  };
  type: 'SET_CUSTOMER_CONTACT';
};

export const SET_CUSTOMER_CONTACT: SetCustomerContactAction = {
  payload: {
    contact: {
      email: '',
      firstName: '',
      lastName: '',
      phone: {
        country: 'bg',
        countryCode: '+359',
        value: '',
      },
    },
  },
  type: 'SET_CUSTOMER_CONTACT',
};

type SetCustomerBillingContactAction = {
  payload: {
    billingContact: CreateCustomerState['customerData']['billingContact'];
  };
  type: 'SET_CUSTOMER_BILLING_CONTACT';
};

export const SET_CUSTOMER_BILLING_CONTACT: SetCustomerBillingContactAction = {
  payload: {
    billingContact: {
      email: '',
      firstName: '',
      lastName: '',
      phone: {
        country: 'bg',
        countryCode: '+359',
        value: '',
      },
    },
  },
  type: 'SET_CUSTOMER_BILLING_CONTACT',
};

export const SET_CUSTOMER_DATA: SetCustomerDataAction = {
  payload: {
    accountManager: undefined,
    address: {
      addressLine1: '',
      addressLine2: '',
      area: '',
      municipality: '',
      postCode: '',
      region: '',
      settlement: '',
    },
    billingContact: {
      email: '',
      firstName: '',
      lastName: '',
      phone: {
        country: 'bg',
        countryCode: '+359',
        value: '',
      },
    },
    contact: {
      email: '',
      firstName: '',
      lastName: '',
      phone: {
        country: 'bg',
        countryCode: '+359',
        value: '',
      },
    },
    currentRepresentativeIndex: 0,
    customerType: 'person',
    eik: '',
    firstName: '',
    lastName: '',
    legalEntityName: '',
    middleName: '',
    personalId: '',
    representatives: [],
  },
  type: 'SET_CUSTOMER_DATA',
};

export type Action =
  | SetCustomerTypeAction
  | SetCustomerContactAction
  | SetCustomerBillingContactAction
  | SetName
  | SetPersonalIdAction
  | SetAddressAction
  | SetCustomerDataAction
  | SetLegalEntityNameAction
  | SetRepresentativesAction
  | SetEIKAction
  | SetAccountManagerAction
  | SetPlanIdAction;
