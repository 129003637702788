import { CardListContainer } from 'ui';

import { MeteringPoint } from '~/data';

import { MeteringPointCard } from '../detail/MeteringPointCard';

export const MeteringPointCardList = ({ list }: { list: MeteringPoint[] }) => {
  return (
    <CardListContainer
      elements={list.map((meteringPoint) => (
        <MeteringPointCard displayCustomerName key={meteringPoint.meteringPointId} meteringPoint={meteringPoint} />
      ))}
    />
  );
};
