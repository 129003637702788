import { GetAllMeteringPointsV2Request } from '@toki-power/public-api';

import { createDataCacheKeys, MeteringPoint, PageParams, transformMeteringPoints } from '~/data';
import { useMeteringPointApi, UsePaginatedQueryResult, useSuspensePaginatedQuery } from '~/data/api';

const cacheKeys = createDataCacheKeys('meteringPoints');

export type UseMeteringPointsResult = UsePaginatedQueryResult<MeteringPoint>;

type MeteringPointsOptions = {
  filterBy: Pick<GetAllMeteringPointsV2Request, 'customerType' | 'orderBy' | 'ownerIdIn' | 'search'>;
  size?: number;
};

export const useMeteringPoints = (
  { filterBy, size }: MeteringPointsOptions = { filterBy: { customerType: 'owner' } }
) => {
  const api = useMeteringPointApi();

  const fetchAll = (pageParams: PageParams) =>
    api.getAll({
      page: pageParams.page,
      size: size || pageParams.size,
      ...filterBy,
    });

  const { data, isLoading, isError, isSuccess, error, fetchNextPage } = useSuspensePaginatedQuery<MeteringPoint>({
    cacheKey: [
      ...cacheKeys.list({
        filterBy,
      }),
    ],
    fetchAll,
    itemsDecorator: transformMeteringPoints,
  });

  return {
    data,
    error,
    fetchNextPage,
    isError,
    isLoading,
    isSuccess,
  };
};
