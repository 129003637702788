import { Checkbox, FormProvider, SubmitHandler, TextInput, useForm, yupResolver } from 'forms';
import { useTranslation } from 'next-i18next';
import { error } from 'pdf-lib';
import { useMemo, useState } from 'react';
import { addError, Errors } from 'rum';
import { Form } from 'ui';
import { boolean, object, string } from 'yup';

import { AuthSubmitButton, AuthTranslation } from '../shared';

interface FormValues {
  email: string;
  password: string;
  terms: boolean;
}

const initialFormValues = {
  email: '',
  name: '',
  password: '',
  terms: false,
};

const termsAndConditionsLink =
  'https://cdn.prod.website-files.com/65e73d7a1d155119f24d9741/66a7555d4116986a90881fe3_%D0%9E%D0%B1%D1%89%D0%B8-%D1%83%D1%81%D0%BB%D0%BE%D0%B2%D0%B8%D1%8F_20.05.24-%D0%B3_.pdf';

export const SignUpForm = ({
  preselectedEmail,
  onSignup,
}: {
  preselectedEmail?: string;
  onSignup: (user: { email: string; password: string }) => void;
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const resolver = useMemo(() => {
    const requiredMessage = t('validation:required');
    return yupResolver(
      object({
        email: string().required(requiredMessage).email(t('validation:invalidEmail')),
        password: string()
          .required(requiredMessage)
          .min(6, t('validation:minMessageLength', { length: 6 })),
        terms: boolean().oneOf([true], requiredMessage).required(requiredMessage),
      })
    );
  }, [t]);

  const { handleSubmit, watch, setError, setValue, ...rest } = useForm({
    defaultValues: { ...initialFormValues, email: preselectedEmail },
    resolver,
  });

  const onSubmit: SubmitHandler<FormValues> = async ({ email, password }) => {
    setIsLoading(true);
    try {
      const { createUser } = await import('~/auth');
      const credentials = await createUser({
        email,
        password,
      });

      if (!credentials.user) {
        addError(Errors.AUTH_SIGN_UP_FAILURE, error, {
          email,
          password,
        });
        return;
      }
      onSignup({
        email,
        password,
      });
    } catch (e) {
      const { FirebaseError } = await import('~/auth');

      if (e instanceof FirebaseError && e.code === 'auth/email-already-in-use') {
        setError('email', { message: t('validation:emailAlreadyInUse'), type: 'manual' });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...{ handleSubmit, setError, setValue, watch, ...rest }}>
      <Form id="signUpForm" onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          margin="normal"
          required
          fullWidth
          label={t('common:email')}
          name="email"
          shrink
          disabled={Boolean(preselectedEmail)}
        />

        <TextInput
          margin="normal"
          required
          fullWidth
          name="password"
          label={t('common:password')}
          type="password"
          shrink
        />

        <Checkbox
          label={<AuthTranslation i18nKey="auth:terms" href={termsAndConditionsLink} />}
          name="terms"
          data-testid="terms"
        />

        <AuthSubmitButton isLoading={isLoading} label={t('auth:createAccount')} />
      </Form>
    </FormProvider>
  );
};
