export const centerTitle = (text: string) => {
  if (text.includes(' ')) {
    const [firstWord, secondWord] = text.split(' ');

    return (
      <>
        <tspan x="50%" y="45%">
          {firstWord}
        </tspan>
        <tspan x="50%" y="55%">
          {secondWord}
        </tspan>
      </>
    );
  }

  return text;
};
