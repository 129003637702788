export const splitLegalEntityName = (legalEntityName: string) => {
  const legalEntityNameParts = legalEntityName.split(' ');

  const companyType = legalEntityName.includes('НАРОДНО ЧИТАЛИЩЕ')
    ? legalEntityNameParts.splice(-2).join(' ')
    : legalEntityNameParts.pop();

  const name = legalEntityNameParts.join(' ');

  return {
    companyType,
    name,
  };
};
