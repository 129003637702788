import { Box, Typography } from '@mui/material';

interface Props {
  backgroundColor?: string;
  text?: string;
}

const CardGradient = ({ backgroundColor, text }: Props) => {
  return (
    <Box
      sx={(theme) => ({
        background: backgroundColor || theme.palette.gradient.darkGreen,
        borderRadius: theme.spacing(2, 2, 0, 0),
        height: theme.spacing(6.5),
        mb: -2.5,
      })}
    >
      <Typography variant="h6" color="common.white" ml={2} noWrap>
        {text}
      </Typography>
    </Box>
  );
};

export default CardGradient;
