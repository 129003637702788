import { Control, Controller } from 'forms';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import { AutocompleteField, Box, createFilterOptions, FormControl, TextField, Typography } from 'ui';

import { ImpersonalizedCustomer } from '~/data';

import { Section } from './Section';
import { FormValues } from './types';

interface Props {
  q: string;
  setQ: (value: string) => void;
  control: Control<FormValues>;
  options: ImpersonalizedCustomer[];
}

export const CustomerSelector = ({ control, options, setQ, q }: Props) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('userImpersonation');

  const filterOptions = createFilterOptions<ImpersonalizedCustomer>({
    matchFrom: 'any',
    stringify: (option) => `${option.email} ${option.customers.map(({ name }) => name).join(' ')}`,
  });

  return (
    <Section title={t('common:nav.userImpersonation')} description={t('sections.selectUser.description')}>
      <Box display="flex" flexDirection="column" gap={3}>
        <FormControl>
          <Controller
            name="user"
            control={control}
            render={({ field }) => (
              <AutocompleteField<ImpersonalizedCustomer>
                isOptionEqualToValue={(option, value) => {
                  if (option.email === value.email) {
                    return true;
                  }

                  const res = option.customers.some(({ name }) => {
                    return name.toLowerCase().includes(q.toLowerCase());
                  });

                  return res;
                }}
                getOptionLabel={(option) => option.email}
                filterOptions={filterOptions}
                value={field.value}
                options={options}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                noOptionsText={t('common:noResults')}
                autoHighlight
                renderOption={(_props, option) => {
                  return (
                    <Box
                      p={2}
                      sx={() => ({
                        '&:hover': { backgroundColor: 'limeGreen.light', cursor: 'pointer' },
                        backgroundColor: 'common.white',
                        borderBottom: '1px solid',
                        borderBottomColor: 'mintGreen.light',
                        textDecoration: 'none',
                      })}
                      onClick={() => {
                        field.onChange(option);
                        setOpen(false);
                      }}
                      key={option.email}
                    >
                      <Typography variant="body1" color="black">
                        {option.email}
                      </Typography>
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('sections.selectUser.fields.search.label')}
                    placeholder={t('sections.selectUser.fields.search.placeholder')}
                    name="search"
                    value={params.inputProps.value}
                    inputProps={{
                      ...params.inputProps,
                      'data-testid': 'search-input',
                    }}
                    onChange={(e) => {
                      setQ(e.target.value);
                    }}
                  />
                )}
              />
            )}
          />
        </FormControl>
      </Box>
    </Section>
  );
};
