/* eslint-disable no-inline-styles/no-inline-styles */
import { FormProvider, SubmitHandler, TextInput, useForm, yupResolver } from 'forms';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { Box, Form } from 'ui';
import { object, string } from 'yup';

import { useCreateMeteringPoint } from '~/meteringPoint';
import { ContinueButton, StepTitle } from '~/ui';

interface FormValues {
  meteringPointName: string;
}

export const MeteringPointNameStep = () => {
  const { t } = useTranslation('createMeteringPointWizard');
  const { goToNextStep, getMeteringPoint, setName, currentMeteringPointIndex, navigateWithRedirect } =
    useCreateMeteringPoint();

  const { name } = getMeteringPoint(currentMeteringPointIndex);

  const resolver = yupResolver(
    object({
      meteringPointName: string().required(t('steps.name.fields.name.required')),
    })
  );

  const { handleSubmit, watch, formState, ...rest } = useForm<FormValues>({
    defaultValues: {
      meteringPointName: name,
    },
    resolver,
  });

  const onSubmit: SubmitHandler<FormValues> = async ({ meteringPointName }) => {
    setName(meteringPointName, currentMeteringPointIndex);

    navigateWithRedirect(goToNextStep);
  };

  return (
    <FormProvider {...{ formState, handleSubmit, watch, ...rest }}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box display="flex" flexDirection="column">
          <Box mb={2}>
            <StepTitle>{t('steps.name.title')}</StepTitle>
          </Box>

          <TextInput
            required
            margin="normal"
            fullWidth
            label={t('steps.name.fields.name.label')}
            placeholder={t('steps.name.fields.name.placeholder')}
            name="meteringPointName"
            autoFocus
            shrink
            data-testid="metering-point-name"
          />
        </Box>

        <ContinueButton data-testid="metering-point-name-submit-button" disabled={!formState.isValid} />
      </Form>
    </FormProvider>
  );
};
