import { isLegalEntity } from '../isLegalEntity/isLegalEntity';

export type CustomerName = {
  firstName: string;
  middleName: string;
  lastName: string;
  legalEntityName: string;
};

export const parseCustomerName = (name: string): CustomerName => {
  if (isLegalEntity(name)) {
    return {
      firstName: '',
      lastName: '',
      legalEntityName: name,
      middleName: '',
    };
  }

  const [firstName, middleName, ...rest] = name.split(' ');

  const lastName = rest.join(' ');

  return {
    firstName: firstName || '',
    lastName: (!lastName ? middleName : lastName) || '',
    legalEntityName: '',
    middleName: lastName ? middleName : '',
  };
};
