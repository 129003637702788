import { User } from 'firebase-client/admin';
import { NextApiRequest, NextApiResponse } from 'next';

const emails = [
  'n.slavov@toki.bg',
  'v.atanasova@toki.bg',
  'i.dimitrov@toki.bg',
  'e2e.impersonation@toki.bg',
  'a.rekalov@toki.bg',
  'g.nankova@toki.bg',
  'l.velichkov@toki.bg',
];

export const doesEmailHaveImpersonationAccess = (email: string) => emails.includes(email);

export const withImpersonationAccess = (
  handler: (req: NextApiRequest, res: NextApiResponse, user: User) => Promise<void>
) => {
  return async (req: NextApiRequest, res: NextApiResponse, user: User) => {
    if (user.email && !doesEmailHaveImpersonationAccess(user.email)) {
      return res.status(401).json({ error: 'User is not authorized.' });
    }

    return handler(req, res, user);
  };
};
