import { useRouter } from 'next/router';
import { useState } from 'react';
import { Box, Paper, useTheme } from 'ui';

import { PointInvoiceAddendum } from '~/data';

import { useInvoiceMeteringPoints } from '../../../hooks/useInvoiceMeteringPoints/useInvoiceMeteringPoints';
import { InvoiceMeteringPointDetails } from './InvoiceMeteringPointDetails/InvoiceMeteringPointDetails';
import { InvoiceMeteringPointSwitcher } from './InvoiceMeteringPointSwitcher/InvoiceMeteringPointSwitcher';

interface Props {
  invoiceId: string;
}

export const MeteringPointsPreview = ({ invoiceId }: Props) => {
  const { query } = useRouter();
  const { data: meteringPointInvoices } = useInvoiceMeteringPoints({ invoiceId });

  const meteringPointInvoiceId = query.pointInvoiceAddendum as string | undefined;
  const selectedMeteringPoint = meteringPointInvoices.find(
    (pointInvoiceAddendum) => pointInvoiceAddendum.invoiceId === meteringPointInvoiceId
  );

  const [meteringPointInvoice, setMeteringPointInvoice] = useState<PointInvoiceAddendum>(
    selectedMeteringPoint || meteringPointInvoices[0]
  );
  const { customShadows } = useTheme();

  const onMeteringPointChange = (invoice: PointInvoiceAddendum) => {
    setMeteringPointInvoice(invoice);
  };

  return (
    <>
      <Box mb={4}>
        <InvoiceMeteringPointSwitcher
          defaultOption={meteringPointInvoice}
          meteringPointInvoices={meteringPointInvoices}
          onChange={onMeteringPointChange}
        />
      </Box>

      <Paper
        sx={{
          boxShadow: customShadows.main,
          m: 0,
          p: {
            md: 3,
            xl: 5,
            xs: 2,
          },
        }}
      >
        <InvoiceMeteringPointDetails meteringPointInvoice={meteringPointInvoice} date="2023-01-31" />
      </Paper>
    </>
  );
};
