import { create } from 'store';

import { LayoutStore } from './types';

const useLayoutStore = create<LayoutStore>()((set) => ({
  layout: 'grid',
  setLayout: (layout) => set(() => ({ layout })),
}));

export default useLayoutStore;
