import { Box, Button } from 'ui';

export const AuthSubmitButton = ({
  isLoading,
  label,
  'data-testid': dataTestId,
}: {
  isLoading: boolean;
  label: string;
  'data-testid'?: string;
}) => {
  return (
    <Box my={2}>
      <Button type="submit" fullWidth variant="primary" color="gradient" loading={isLoading} data-testid={dataTestId}>
        {label}
      </Button>
    </Box>
  );
};
