import { Configuration, CustomerApi, GetCustomerTypeByEmailv1200ResponseData } from '@toki-power/public-api';

import {
  Contact,
  Customer,
  GetAllOptions,
  ImpersonalizedCustomer,
  Invoice,
  MeteringPoint,
  Page,
  PageParams,
} from '../types';

export type CustomerFilter = {
  search?: string;
};

type CustomerReturnType = {
  get: (customerId: string) => Promise<Customer>;
  getAll: (options: GetAllOptions<CustomerFilter>) => Promise<Page<Customer>>;
  getContacts: (customerId: string, page: PageParams) => Promise<Page<Contact>>;
  getInvoices: (customerId: string, page: PageParams) => Promise<Page<Invoice>>;
  getMeteringPoints: (customerId: string, page: PageParams) => Promise<Page<MeteringPoint>>;
  getCustomerType: () => Promise<GetCustomerTypeByEmailv1200ResponseData | undefined>;
  search: (search: string, page: PageParams) => Promise<Page<ImpersonalizedCustomer>>;
};

export const customerApi = (config: () => Promise<Configuration>): CustomerReturnType => {
  const api = async () => new CustomerApi(await config());

  const getAll = async ({ page, filter }: GetAllOptions<CustomerFilter>) =>
    (await api()).getListOfCustomersByEmailV1({
      page: page?.page,
      search: filter?.search || undefined,
      size: page?.size,
    });

  const get = async (customerId: string) => (await (await api()).getCustomerDetailsV1({ customerId })).data;

  const getInvoices = async (customerId: string, { page, size }: PageParams) =>
    (await api()).getCustomerInvoicesV1({ customerId, page, size });

  const getContacts = async (customerId: string, { page, size }: PageParams) =>
    (await api()).getCustomerContactsV1({ customerId, page, size });

  const getMeteringPoints = async (customerId: string, { page, size }: PageParams) =>
    (await api()).getCustomerMeteringPointsV2({ customerId, page, size });

  const getCustomerType = async () => (await (await api()).getCustomerTypeByEmailv1()).data;

  const search = async (q: string, { page, size }: PageParams) =>
    (await api()).searchCustomersV1({ page, search: q, size });

  return {
    get,
    getAll,
    getContacts,
    getCustomerType,
    getInvoices,
    getMeteringPoints,
    search,
  };
};
