import { sendPasswordResetEmail } from '@firebase/auth';
import { auth } from 'firebase-client';
import logger from 'logger';

export const resetPassword = async (email: string) => {
  try {
    return await sendPasswordResetEmail(auth, email, {
      handleCodeInApp: false,
      url: 'https://oki.toki.bg/login',
    });
  } catch (error) {
    logger.error('resetPassword error', { error });
    throw error;
  }
};
