import {
  CartesianGrid,
  Legend,
  Line,
  LineChart as LineRechart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { COLORS_ARRAY } from '../../constants';
import { ChartBaseProps, TooltipFormatter } from '../../types';
import { labelFormatter } from '../../utils';

interface Config<K> {
  name?: string;
  color?: string;
  accessorKey: keyof K & string;
}

interface Props<T> extends ChartBaseProps {
  tooltipFormatter?: TooltipFormatter;
  xAxisKey: keyof T & string;
  legendAlign?: 'top' | 'bottom';
  data: T[];
  lineWidth?: number;
  config: Config<T>[];
  hasLegend?: boolean;
  showXAxis?: boolean;
  showYAxis?: boolean;
  showGrid?: boolean;
}

const LineChart = <T,>({
  xAxisKey,
  tooltip,
  customTooltip,
  height = 260,
  lineWidth,
  data,
  config,
  tooltipFormatter,
  legendAlign = 'top',
  title,
  hasLegend = true,
  showXAxis = true,
  showYAxis = true,
  showGrid = true,
}: Props<T>) => {
  const isBottomLegend = legendAlign === 'bottom';

  return (
    <ResponsiveContainer width="100%" height={height}>
      <LineRechart
        data={data}
        margin={{
          bottom: 0,
          left: isBottomLegend ? 0 : 10,
          right: isBottomLegend ? 0 : 20,
          top: isBottomLegend ? 40 : 10,
        }}
      >
        {showGrid && <CartesianGrid strokeDasharray="0" vertical={false} />}
        {showXAxis && <XAxis dataKey={xAxisKey} axisLine={false} tickLine={false} />}
        {showYAxis && <YAxis axisLine={false} tickLine={false} />}

        {tooltip && <Tooltip content={customTooltip} formatter={tooltipFormatter} labelFormatter={labelFormatter} />}
        {hasLegend && <Legend align="right" iconType="circle" iconSize={10} verticalAlign={legendAlign} />}

        {config.map(({ accessorKey, color, name }, i) => (
          <Line
            key={accessorKey}
            type="linear"
            dataKey={accessorKey}
            stroke={color || COLORS_ARRAY[i]}
            strokeWidth={lineWidth || 2}
            name={name}
          />
        ))}

        {title && (
          <text x="70" y="10" dominantBaseline="hanging" fontSize="18" fontWeight="bold">
            {title}
          </text>
        )}
      </LineRechart>
    </ResponsiveContainer>
  );
};

export default LineChart;
