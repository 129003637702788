import { useRef, useState } from 'react';

import StepNavigator, { Steps } from '../StepNavigator';

interface UseSteNavigationArgs<T extends Steps> {
  initialStep: T;
  steps: T[];
}

type UseStepNavigatorReturn<T extends Steps> = {
  setCurrentStep: (currentStep: T) => void;
  setRedirectTo: (redirectTo: T | undefined) => void;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  getCurrentStep: () => T;
  getRedirectTo: () => T | undefined;
  navigateWithRedirect: (navigate: () => void) => void;
  getHistory: () => Steps;
};

type NavigatorState<T extends Steps> = {
  currentStep: T;
  redirectTo: T | undefined;
};

export const useStepNavigator = <T extends Steps>({
  initialStep,
  steps,
}: UseSteNavigationArgs<T>): UseStepNavigatorReturn<T> => {
  const [navigatorState, setNavigatorState] = useState<NavigatorState<T>>({
    currentStep: initialStep,
    redirectTo: undefined,
  });

  const setNavigationStep = (currentStep: T, redirectTo?: T): void => {
    setNavigatorState({ currentStep, redirectTo });
  };

  const stepNavigator = useRef(new StepNavigator(steps, navigatorState.currentStep)).current;

  const goToNextStep = (): void => {
    if (stepNavigator.goToNextStep()) {
      setNavigationStep(stepNavigator.getCurrentStep());
    }
  };

  const goToPreviousStep = (): void => {
    if (stepNavigator.goToPreviousStep()) {
      setNavigationStep(stepNavigator.getCurrentStep());
    }
  };

  const getCurrentStep = (): T => {
    return navigatorState.currentStep;
  };

  const getHistory = (): Steps => {
    return stepNavigator.getHistory();
  };

  const setCurrentStep = (currentStep: T): void => {
    stepNavigator.goToStep(currentStep);
    setNavigationStep(stepNavigator.getCurrentStep());
  };

  const setRedirectTo = (redirectTo: T | undefined): void => {
    setNavigationStep(stepNavigator.getCurrentStep(), redirectTo);
  };

  const getRedirectTo = (): T | undefined => {
    return navigatorState.redirectTo;
  };

  const navigateWithRedirect = (navigate: () => void): void => {
    if (navigatorState.redirectTo) {
      setCurrentStep(navigatorState.redirectTo);
      setRedirectTo(undefined);
    } else {
      navigate();
    }
  };

  return {
    getCurrentStep,
    getHistory,
    getRedirectTo,
    goToNextStep,
    goToPreviousStep,
    navigateWithRedirect,
    setCurrentStep,
    setRedirectTo,
  };
};
