import { Configuration, Consumer, ConsumerApi, CreateConsumerV1201ResponseData } from '@toki-power/public-api';

import ResponseError from './ResponseError';

type ConsumerReturnType = {
  createConsumer: (data: Consumer) => Promise<CreateConsumerV1201ResponseData>;
};

type ErrorCode = 'METERING_POINT_EXISTS' | 'CUSTOMER_ID_EXISTS';

const METERING_POINT_EXISTS = /Metering point ([A-Za-z0-9]+) already exists\./;
const CUSTOMER_ID_EXISTS = /Customer ([0-9]+) already exists./;

export const parseConsumerResponseError = async (responseError: ResponseError): Promise<ErrorCode | undefined> => {
  const error = await responseError.response.json();

  const errorMessage = error?.detail || '';

  if (errorMessage.match(METERING_POINT_EXISTS)) {
    return 'METERING_POINT_EXISTS';
  }

  if (errorMessage.match(CUSTOMER_ID_EXISTS)) {
    return 'CUSTOMER_ID_EXISTS';
  }

  return undefined;
};

export const consumerApi = (config: () => Promise<Configuration>): ConsumerReturnType => {
  const api = async () => new ConsumerApi(await config());

  const createConsumer = async (data: Consumer) => (await (await api()).createConsumerV1({ data })).data;

  return {
    createConsumer,
  };
};
