import { arrayUnion, doc, setDoc, Timestamp } from 'firebase/firestore';
import { createCollection } from 'firebase-client';

type Message = {
  role: 'assistant' | 'user';
  message: string;
};

interface LogTokiGPTMessage {
  userId: string;
  message: Message;
}

type TokiMessageCollection = {
  messages: Message[];
  userId: string;
  createdOn: Date;
};

const collection = createCollection<TokiMessageCollection>('tokiGPTConversations');

export const logTokiGPTMessage = async (options: LogTokiGPTMessage) => {
  return setDoc(
    doc(collection, options.userId),
    {
      createdOn: Timestamp.now(),
      messages: arrayUnion(options.message),
      userId: options.userId,
    },
    { merge: true }
  );
};
