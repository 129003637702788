import { useTranslation } from 'next-i18next';
import { Box, Button, useMediaQuery } from 'ui';

import { useCreateCustomer } from '~/customer';
import { isCompletedOnboardingCustomer } from '~/services/firestore';
import { StepTitle } from '~/ui';

import { AccountManager } from './AccountManager';
import { Address } from './Address';
import { BillingContact } from './BillingContact';
import { Contact } from './Contact';
import { CustomerType } from './CustomerType';
import { Eik } from './Eik';
import { LegalEntityName } from './LegalEntityName';
import { Representatives } from './Representatives';

export const BusinessConfirmationStep = () => {
  const { t } = useTranslation('createCustomerWizard');
  const { setCurrentStep, onCompletion, setRedirectTo, getCustomerData, navigatorSteps } = useCreateCustomer();
  const isLargeMobile = useMediaQuery('(min-width:380px) or (min-height:800px)');

  const customerData = getCustomerData();

  const editStep = (step: string) => {
    setCurrentStep(step);
    setRedirectTo('businessConfirmation');
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" height={isLargeMobile ? '100%' : '75%'}>
      <Box maxHeight="80%">
        <Box mb={1} ml={1}>
          <StepTitle>{t('steps.confirmation.title')}</StepTitle>
        </Box>

        <Box display="flex" flexDirection="column" gap={3} overflow="auto" mb={2} maxHeight="100%">
          {navigatorSteps.includes('accountManager') && <AccountManager onClick={editStep} />}

          <CustomerType onClick={editStep} />

          <Eik onClick={editStep} />

          <LegalEntityName onClick={editStep} />

          <Representatives onClick={editStep} />

          <Address onClick={editStep} />

          <Contact onClick={editStep} />

          <BillingContact onClick={editStep} />
        </Box>
      </Box>

      <Box mt={2} position="relative">
        <Button
          size="lg"
          fullWidth
          onClick={onCompletion}
          disabled={!isCompletedOnboardingCustomer(customerData)}
          data-testid="confirmation-button"
          color="gradient"
        >
          {t('steps.confirmation.button')}
        </Button>
      </Box>
    </Box>
  );
};
