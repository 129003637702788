import { Box, Typography, useTheme } from '@mui/material';

import { useResolution } from '../../hooks';
import { Paper } from '../Paper';

interface Props {
  title: string;
  content: React.ReactNode;
}

const StatCard = ({ title, content }: Props) => {
  const theme = useTheme();
  const { isDesktop } = useResolution();

  return (
    <Paper
      sx={{
        alignItems: 'center',
        boxShadow: theme.customShadows.main,
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        mx: 0,
        p: 2,
        textAlign: 'center',
      }}
    >
      <Box height={45} display="flex" alignItems="flex-end" mb={1}>
        <Typography
          lineHeight={1}
          variant={isDesktop ? 'subtitle2' : 'subtitle1'}
          fontWeight="bold"
          color="info.main"
          mt={0.5}
          noWrap={false}
        >
          {title}
        </Typography>
      </Box>
      <Box mb={1}>{content}</Box>
    </Paper>
  );
};
export default StatCard;
