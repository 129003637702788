import { Typography } from 'ui';

interface StepTitleProps {
  children: React.ReactNode;
}

export const StepTitle = ({ children }: StepTitleProps) => (
  <Typography variant="h6" fontWeight="bold">
    {children}
  </Typography>
);
