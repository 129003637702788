import { Configuration, InvoiceApi } from '@toki-power/public-api';

import { GetAllOptions as GetAllOptionsType, Invoice, InvoiceDetails, Page } from '../types';

export type InvoiceFilter = {
  search?: string;
  customerIdIn?: string;
};

export type InvoiceOrderBy = '-invoice_date' | '-total_invoice_cost' | '+total_invoice_cost';

interface GetAllOptions extends GetAllOptionsType<InvoiceFilter> {
  orderBy?: InvoiceOrderBy;
}

export type InvoiceReturnType = {
  get: (invoiceId: string) => Promise<InvoiceDetails>;
  getAll: (options: GetAllOptions) => Promise<Page<Invoice>>;
};

export const invoiceApi = (config: () => Promise<Configuration>): InvoiceReturnType => {
  const api = async () => new InvoiceApi(await config());

  const getAll = async ({ page, filter, orderBy }: GetAllOptions = {}) =>
    (await api()).getAllInvoicesV1({
      customerIdIn: filter?.customerIdIn || undefined,
      orderBy: orderBy || undefined,
      page: page?.page,
      search: filter?.search || undefined,
      size: page?.size,
    });

  const get = async (invoiceId: string) => (await (await api()).getInvoiceDetailsV1({ invoiceId })).data;

  return {
    get,
    getAll,
  };
};
