export const isLegalEntity = (name: string | undefined): boolean => {
  const legalEntityTypes = [
    'ЕТ',
    'ЕООД',
    'ООД',
    'ЕАД',
    'АД',
    'ЗП',
    'СДРУЖЕНИЕ',
    'ФОНДАЦИЯ',
    'КООПЕРАЦИЯ',
    'НАРОДНО ЧИТАЛИЩЕ',
    'СД',
  ];

  if (!name) {
    return false;
  }

  return legalEntityTypes.some((type) => name.includes(type));
};
