import { Skeleton, SkeletonProps } from '@mui/material';

import Card from '../Card';

type Props = SkeletonProps;
export const CardLoader = (props: Props) => {
  return (
    <Card
      avatar={<Skeleton variant="circular" width={40} height={40} {...props} />}
      title={
        <Skeleton
          variant="rounded"
          sx={{
            marginBottom: '0.5rem',
          }}
        />
      }
      subtitle={
        <Skeleton
          variant="rounded"
          height={40}
          sx={{
            marginBottom: '0.5rem',
          }}
        />
      }
      content={
        <>
          <Skeleton variant="rounded" sx={{ marginBottom: '0.5rem' }} />
          <Skeleton variant="rounded" sx={{ marginBottom: '0.5rem' }} />
        </>
      }
    />
  );
};
