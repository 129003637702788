import { Steps } from '~/utils';

export const RESIDENT_CUSTOMER_NAVIGATOR_STEPS: Steps[] = [
  'accountManager',
  'customerType',
  'fillInOptions',
  ['residentStepsPreview', 'invoiceUpload'],
  'name',
  'personalId',
  'address',
  'billingContactPerson',
  'residentConfirmation',
];

export const BUSINESS_CUSTOMER_NAVIGATOR_STEPS: Steps[] = [
  'accountManager',
  'customerType',
  'fillInOptions',
  ['businessStepsPreview', 'invoiceUpload'],
  'eikId',
  'legalEntityName',
  'businessRepresentative1',
  'businessRepresentative2',
  'address',
  'contactPerson',
  'billingContactPerson',
  'businessConfirmation',
];

export const getNavigationSteps = ({
  skipSteps = [],
  customerType,
}: {
  skipSteps?: Steps;
  customerType: 'business' | 'resident';
}): Steps[] => {
  const navigatorSteps =
    customerType === 'business' ? BUSINESS_CUSTOMER_NAVIGATOR_STEPS : RESIDENT_CUSTOMER_NAVIGATOR_STEPS;

  return navigatorSteps.filter((step) => (typeof step === 'string' ? !skipSteps.includes(step) : true));
};
