import { InvoiceDetails } from '~/data';

import { getReadableErpName } from './getReadableErpName';
import { getReadablePlanName } from './getReadablePlanName';

export const transformInvoice = (data: InvoiceDetails): InvoiceDetails => {
  const { meteringPoints, ...restData } = data;
  return {
    ...restData,
    meteringPoints: meteringPoints.map((point) => ({
      ...point,
      meteringPoint: {
        ...point.meteringPoint,
        erp: point.meteringPoint.erp && getReadableErpName(point.meteringPoint.erp),
        plan: point.meteringPoint.plan && getReadablePlanName(point.meteringPoint.plan),
      },
    })),
  };
};
