import { DocumentData } from 'firebase/firestore';
import { useTranslation } from 'next-i18next';

import { CreateMeteringPointProvider, useCreateMeteringPoint } from '~/meteringPoint';
import { CreateMeteringPointState } from '~/meteringPoint/hooks/useCreateMeteringPoint/types';
import { MobilePageHeader, ProcessDetails, WizardLayout } from '~/ui';
import { Steps } from '~/utils';

import { AccountOptionsStep } from './AccountOptionsStep';
import { CompletionStep } from './CompletionStep';
import { ConfirmationStep } from './ConfirmationStep';
import { MeteringPointAddressStep } from './MeteringPointAddressStep';
import { MeteringPointIdStep } from './MeteringPointIdStep';
import { MeteringPointNameStep } from './MeteringPointNameStep';

const STEPS = {
  accountOptions: AccountOptionsStep,
  address: MeteringPointAddressStep,
  completion: CompletionStep,
  confirmation: ConfirmationStep,
  meteringPointId: MeteringPointIdStep,
  name: MeteringPointNameStep,
};

export const Wizard = () => {
  const { t } = useTranslation('createMeteringPointWizard');
  const { getCurrentStep, goToPreviousStep } = useCreateMeteringPoint();

  const currentStep = getCurrentStep();

  const CurrentStep = STEPS[currentStep as keyof typeof STEPS] || STEPS.accountOptions;

  const showBackButton = currentStep !== 'name' && currentStep !== 'completion';

  return (
    <WizardLayout
      leftChild={
        <ProcessDetails
          title={t('title')}
          subtitle={t('subtitle')}
          onBackClick={goToPreviousStep}
          showBackButton={showBackButton}
        />
      }
      rightChild={<CurrentStep />}
      header={<MobilePageHeader title={t('title')} onBackClick={goToPreviousStep} showBackButton={showBackButton} />}
    />
  );
};

interface CreateMeteringPointWizardProps {
  onCompletion: () => Promise<void>;
  onUpdate: (data: CreateMeteringPointState['meteringPointsData']) => void;
  skipSteps?: Steps;
  onboardingData?: DocumentData | null;
}

export const CreateMeteringPointWizard = ({
  onCompletion,
  skipSteps,
  onUpdate,
  onboardingData,
}: CreateMeteringPointWizardProps) => (
  <CreateMeteringPointProvider
    skipSteps={skipSteps}
    onCompletion={onCompletion}
    onUpdate={onUpdate}
    onboardingData={onboardingData}
  >
    <Wizard />
  </CreateMeteringPointProvider>
);
