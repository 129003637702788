import { useTranslation } from 'next-i18next';
import { Suspense } from 'react';
import { Box, Loader } from 'ui';

import { Customer } from './Customer';
import { Section } from './Section';

interface Props {
  customerIds: string[];
}

export const Customers = ({ customerIds }: Props) => {
  const { t } = useTranslation('userImpersonation');

  return (
    <Section title={t('sections.accounts.title')} description={t('sections.accounts.description')}>
      <Suspense
        fallback={
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
            sx={{ backgroundColor: 'common.white' }}
            data-testid="customers-loading"
          >
            <Loader />
          </Box>
        }
      >
        <Box
          mt={2}
          sx={(theme) => ({
            display: 'grid',
            gap: 3,
            gridTemplateColumns: `repeat(auto-fill, minmax(${theme.dimensions.cardWidth}, 1fr))`,
            [theme.breakpoints.down('md')]: {
              gridTemplateColumns: `repeat(auto-fill, minmax(${theme.dimensions.mobileCardWidth}, 1fr))`,
            },
            justifyContent: 'center',
          })}
        >
          {customerIds.map((customerId) => (
            <Customer key={customerId} customerId={customerId} />
          ))}
        </Box>
      </Suspense>
    </Section>
  );
};
