import { useSuspenseQuery } from '@tanstack/react-query';

import { BaseHTTPResponse, createDataCacheKeys, ListOptions, Page, PointInvoiceAddendum } from '~/data';
import { ResponseError, useMeteringPointApi } from '~/data/api';

const getCacheKey = (meteringPointId: string, listOptions: ListOptions = {}) =>
  createDataCacheKeys(`metering-points/${meteringPointId}/invoices`).list(listOptions);

export type UseMeteringPointInvoicesResult = BaseHTTPResponse<Page<PointInvoiceAddendum>>;

type MeteringPointInvoicesOptions = {
  meteringPointId: string;
};

export const useMeteringPointInvoices = ({
  meteringPointId,
}: MeteringPointInvoicesOptions): UseMeteringPointInvoicesResult => {
  const api = useMeteringPointApi();

  const { data, error, isLoading, isError, isSuccess } = useSuspenseQuery<Page<PointInvoiceAddendum>, ResponseError>({
    queryFn: () => api.getInvoices(meteringPointId, { page: 1, size: 5 }),
    queryKey: getCacheKey(meteringPointId),
  });

  return {
    data,
    error,
    isError,
    isLoading,
    isSuccess,
  };
};
