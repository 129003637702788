import { CardListContainer } from 'ui';

import { Customer } from '~/data';

import { CustomerCard } from '../detail';

export const CustomerCardList = ({ list }: { list: Customer[] }) => (
  <CardListContainer
    elements={list.map(({ name, customerId, address, representatives }) => (
      <CustomerCard
        key={customerId}
        name={name}
        customerId={customerId}
        address={address}
        representatives={representatives}
      />
    ))}
  />
);
