import { useResolution } from '../../hooks';
import { Box } from '../Box/Box';
import Title from './Title';
import { PageTitleProps } from './types';

type ResponsiveSettings = {
  titleContainer: {
    [key: string]: string | number;
  };
  title: {
    textAlign: 'left' | 'center';
  };
  rightButtons: {
    [key: string]: string | number;
  };
};

const getResponsiveSettings = (isTablet: boolean): ResponsiveSettings => {
  if (isTablet) {
    return {
      rightButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
      title: {
        textAlign: 'center',
      },
      titleContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      },
    };
  }

  return {
    rightButtons: {
      display: 'flex',
      gridColumnEnd: 4,
      gridColumnStart: 1,
      gridRowStart: 2,
      justifyContent: 'space-between',
    },
    title: {
      textAlign: 'left',
    },
    titleContainer: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
    },
  };
};

const TitleLayout = ({
  title,
  subtitle,
  secondarySubtitle,
  secondaryTitle,
  leftButtons,
  rightButtons,
  ...rest
}: PageTitleProps) => {
  const { isTablet } = useResolution();

  const { titleContainer, title: titleSettings, rightButtons: rightButtonsSettings } = getResponsiveSettings(isTablet);

  return (
    <Box
      {...rest}
      sx={{
        display: 'grid',
        gap: 2,
        gridTemplateColumns: secondarySubtitle ? '0.07fr auto auto 0.5fr' : '1fr auto 1fr',
      }}
    >
      <Box>{leftButtons}</Box>

      <Box sx={{ ...titleContainer, alignItems: secondarySubtitle && 'flex-start' }}>
        <Title title={title} subtitle={subtitle} align={secondarySubtitle ? 'left' : titleSettings.textAlign} />
      </Box>

      {secondaryTitle && secondarySubtitle && (
        <Box sx={{ ...titleContainer, alignItems: 'flex-start' }}>
          <Title title={secondaryTitle} subtitle={secondarySubtitle} align="left" color="info.main" />
        </Box>
      )}

      {rightButtons && <Box sx={rightButtonsSettings}>{rightButtons}</Box>}
    </Box>
  );
};

export default TitleLayout;
