import React from 'react';
import { Box, Card, CardActionArea, CardContent, Typography } from 'ui';

interface OptionProps {
  title: string;
  description?: string;
  children?: React.ReactNode;
  onClick: () => void;
  selected?: boolean;
  selectedColor?: string;
  titleColor?: string;
  action?: React.ReactNode;
  'data-testid'?: string;
}

export const OptionCard = ({
  title,
  description,
  children,
  onClick,
  selected = false,
  selectedColor = 'primary.main',
  titleColor,
  action,
  'data-testid': dataTestId,
}: OptionProps) => (
  <Card
    sx={({ customShadows }) => ({
      border: selected ? 1 : 0,
      borderColor: selected ? selectedColor : 'transparent',
      boxShadow: customShadows.main,
    })}
  >
    <CardActionArea onClick={onClick} data-testid={dataTestId}>
      <CardContent>
        <Box mb={1} display="flex">
          <Typography mt={1} flexGrow={1} variant="body1" fontWeight="bold" color={titleColor || 'primary.dark'}>
            {title}
          </Typography>
          <Box>{action}</Box>
        </Box>

        {description && <Typography variant="body2">{description}</Typography>}
        {children}
      </CardContent>
    </CardActionArea>
  </Card>
);
