/* eslint-disable no-inline-styles/no-inline-styles */
import { FormProvider, SubmitHandler, TextInput, useForm, yupResolver } from 'forms';
import { useTranslation } from 'next-i18next';
import { Box, Form } from 'ui';
import { object, string } from 'yup';

import { useCreateCustomer } from '~/customer/hooks/useCreateCustomer';
import { ContinueButton, StepTitle } from '~/ui';

interface FormValues {
  accountManager: string;
}

export const AccountManagerStep = () => {
  const { t } = useTranslation('createCustomerWizard');

  const { goToNextStep, getCustomerData, setAccountManager, navigateWithRedirect } = useCreateCustomer();
  const { accountManager } = getCustomerData();

  const resolver = yupResolver(
    object({
      accountManager: string()
        .required(t('steps.accountManager.fields.email.required'))
        .email(t('steps.accountManager.fields.email.invalidEmail')),
    })
  );

  const { handleSubmit, watch, formState, getValues, ...rest } = useForm<FormValues>({
    defaultValues: { accountManager },
    resolver,
  });

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    setAccountManager(values.accountManager);
    navigateWithRedirect(goToNextStep);
  };

  return (
    <FormProvider {...{ formState, getValues, handleSubmit, watch, ...rest }}>
      <Form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        <Box height="100%" display="flex" flexDirection="column">
          <StepTitle>{t('steps.accountManager.title')}</StepTitle>

          <Box display="flex" flexDirection="column" justifyContent="space-between" flexGrow={1}>
            <Box>
              <TextInput
                required
                margin="normal"
                fullWidth
                label={t('steps.accountManager.fields.email.label')}
                autocomplete="accountManager"
                name="accountManager"
                shrink
                data-testid="account-manager"
              />
            </Box>

            <ContinueButton data-testid="account-manager-submit-button" disabled={!formState.isValid} />
          </Box>
        </Box>
      </Form>
    </FormProvider>
  );
};
