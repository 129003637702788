import { Box } from '@mui/material';

import StatCard from '../StatCard';

interface Props {
  stats: { title: string; content: React.ReactElement }[];
}

const StatsList = ({ stats }: Props) => (
  <Box
    sx={{
      display: 'grid',
      gap: 3,
      gridTemplateColumns: {
        md: 'repeat(4, minmax(0, 1fr))',
        sm: 'repeat(2, 1fr)',
      },
    }}
  >
    {stats.map(({ title, content }) => (
      <StatCard key={title} title={title} content={content} />
    ))}
  </Box>
);

export default StatsList;
