import { ReactElement } from 'react';
import { Box, Typography, TypographyOverflow } from 'ui';

import { getAddress, MeteringPoint } from '~/data';

interface Props {
  meteringPoint: MeteringPoint;
  onClick: (meteringPoint: MeteringPoint) => void;
}

export const MeteringPointCard = ({ meteringPoint, onClick }: Props): ReactElement => (
  <Box
    p={1}
    sx={() => ({
      '&:hover': { backgroundColor: 'limeGreen.light', cursor: 'pointer' },
      backgroundColor: 'common.white',
      borderBottom: '1px solid',
      borderBottomColor: 'mintGreen.light',
      textDecoration: 'none',
    })}
    onClick={() => onClick(meteringPoint)}
  >
    <Box mb={1}>
      <Typography variant="body2" display="inline">
        {meteringPoint.meteringPointId}
      </Typography>

      {meteringPoint.name && (
        <TypographyOverflow variant="body2" fontWeight="bold" lines={2} lineHeight={1}>
          {meteringPoint.name}
        </TypographyOverflow>
      )}
    </Box>

    {meteringPoint.address && (
      <TypographyOverflow variant="body1" color="textColor.light">
        {getAddress(meteringPoint.address)}
      </TypographyOverflow>
    )}
  </Box>
);
