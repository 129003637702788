import GraphicTitleLayout from './GraphicTitleLayout';
import TitleLayout from './TitleLayout';
import { PageTitleProps } from './types';

const DetailsPageTitle = (props: PageTitleProps) => {
  const { title, subtitle, secondarySubtitle, secondaryTitle, titleGraphic, leftButtons, rightButtons } = props;

  return titleGraphic ? (
    <GraphicTitleLayout
      data-testid="details-page-title"
      title={title}
      subtitle={subtitle}
      titleGraphic={titleGraphic}
      leftButtons={leftButtons}
      rightButtons={rightButtons}
    />
  ) : (
    <TitleLayout
      data-testid="details-page-title"
      title={title}
      subtitle={subtitle}
      secondarySubtitle={secondarySubtitle}
      secondaryTitle={secondaryTitle}
      leftButtons={leftButtons}
      rightButtons={rightButtons}
    />
  );
};

export default DetailsPageTitle;
