import { Box, CurrencyFormat, Stack, SupportedCurrencies, Typography } from 'ui';

export const InvoiceDues = ({
  items,
  currency,
}: {
  items: { name: string; value: number }[];
  currency: SupportedCurrencies;
}) =>
  items.map(({ name, value }) => (
    <Stack direction="row" justifyContent="flex-end" key={name}>
      <Typography align="right" fontWeight="bold">
        {name}:
      </Typography>

      <Box ml={4} width={100}>
        <CurrencyFormat value={value} currency={currency} fontWeight="bold" />
      </Box>
    </Stack>
  ));
