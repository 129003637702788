export type FilterBy = {
  [key: string]: string | string[] | undefined;
};

export type ListOptions = { filterBy?: FilterBy; orderBy?: string | object };

const removeEmptyFilterFields = (obj: FilterBy): { filterBy: FilterBy } | object => {
  const filterFields = Object.fromEntries(
    Object.entries(obj).filter(([, v]) => {
      if (Array.isArray(v)) {
        return v.length > 0;
      }

      return v != null && v !== '' && v !== undefined;
    })
  );

  return Object.keys(filterFields).length > 0 ? { filterBy: filterFields } : {};
};

const createDataCacheKeys = (name: string) => {
  const cacheKeys = {
    all: [name] as const,
    detail: (id: string) => [...cacheKeys.details(), id] as const,
    details: () => [...cacheKeys.all, 'detail'] as const,
    list: ({ filterBy, orderBy }: ListOptions = {}) => {
      const hasFilterBy = filterBy && Object.keys(filterBy || {}).length > 0;
      const hasOrderBy = orderBy && Object.keys(orderBy || {}).length > 0;

      if (!hasFilterBy && !hasOrderBy) {
        return [...cacheKeys.lists()] as const;
      }

      if (!hasFilterBy && hasOrderBy) {
        return [...cacheKeys.lists(), { orderBy }] as const;
      }

      if (hasFilterBy && !hasOrderBy) {
        const filterFields = removeEmptyFilterFields(filterBy || {});
        return [...cacheKeys.lists(), ...(Object.keys(filterFields).length > 0 ? [filterFields] : [])] as const;
      }

      return [...cacheKeys.lists(), { ...removeEmptyFilterFields(filterBy || {}), orderBy }] as const;
    },
    lists: () => [...cacheKeys.all] as const,
  };

  return cacheKeys;
};

export default createDataCacheKeys;
