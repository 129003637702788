export type Step = string;
export type Steps = Step | Step[];

export default class StepNavigator<T extends Steps[]> {
  private steps: T;

  private currentStep: Step;

  private history: Step[] = [];

  constructor(steps: T, currentStep?: Steps) {
    if (steps.length === 0) {
      throw new Error('StepNavigator must have at least one step');
    }

    this.steps = steps;
    this.checkStepsUniqueness();

    const [firstStep] = steps;
    this.currentStep = this.getStep(currentStep || firstStep);
    this.history.push(this.currentStep);
  }

  public getCurrentStep = (): T[number] => this.currentStep;

  public getHistory = (): Steps => this.history;

  public goToNextStep = (): boolean => {
    const currentStepIndex = this.steps.indexOf(this.currentStep);
    const step = currentStepIndex > -1 ? this.steps[currentStepIndex + 1] : undefined;

    if (step && typeof step === 'string') {
      this.currentStep = step;
      this.history.push(this.currentStep);
      return true;
    }

    if (step && Array.isArray(step)) {
      const [nextStep] = step;
      this.currentStep = this.getStep(nextStep);
      this.history.push(this.currentStep);
      return true;
    }

    const arrayStep = this.findArrayStep(this.currentStep);

    if (arrayStep) {
      const indexOfArrayStep = this.steps.indexOf(arrayStep);
      const nextStep = this.steps[indexOfArrayStep + 1];
      this.currentStep = this.getStep(nextStep);
      this.history.push(this.currentStep);
      return true;
    }

    return false;
  };

  public goToPreviousStep = (): boolean => {
    const currentStepIndex = this.history.lastIndexOf(this.currentStep);

    if (this.history[currentStepIndex - 1]) {
      this.currentStep = this.history[currentStepIndex - 1];
      this.history.pop();
      return true;
    }

    return false;
  };

  public goToStep = (step: T[number]): boolean => {
    if (Array.isArray(step)) {
      return false;
    }

    if (this.steps.includes(step)) {
      this.currentStep = step;
      this.history.push(this.currentStep);
      return true;
    }

    const arrayStep = this.findArrayStep(step);

    if (arrayStep) {
      this.currentStep = step;
      this.history.push(this.currentStep);
      return true;
    }

    return false;
  };

  private findArrayStep = (step: Step): T[number] | undefined => {
    return this.steps.find((s) => Array.isArray(s) && s.includes(step));
  };

  private checkStepsUniqueness = (): void => {
    const steps = this.steps.flat();
    const uniqueSteps = new Set(steps);

    if (steps.length !== uniqueSteps.size) {
      throw new Error('StepNavigator steps must be unique');
    }
  };

  // eslint-disable-next-line class-methods-use-this
  private getStep = (step: Step | Step[]): Step => (Array.isArray(step) ? step[0] : step);
}
