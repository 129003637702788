import { Paper } from '../Paper';
import { Typography } from '../Typography/Typography';
import MobileSwiper from './MobileSwiper';

interface Props {
  elements: { label?: string; content: React.ReactNode }[];
}

const CardMobileSwiper = ({ elements }: Props) => {
  return (
    <MobileSwiper
      elements={elements.map(({ label, content }) => (
        <Paper sx={{ height: '100%', p: 4 }} key={label}>
          {label && (
            <Typography variant="h5" fontWeight="bold" mb={4} lineHeight={1}>
              {label}
            </Typography>
          )}
          {content}
        </Paper>
      ))}
    />
  );
};

export default CardMobileSwiper;
