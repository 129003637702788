import { useTranslation } from 'next-i18next';
import { CardMobileSwiper, ListItemGroup, TabPanel, useResolution } from 'ui';
import { AccountBalance, Email, LocationOn, Person, Phone, PowerOutlined, Tag } from 'ui/icons';

import { Contact, Customer, getAddress } from '~/data';

import getCustomerRepresentatives from '../../helpers/getCustomerRepresentatives';

interface Props {
  customer: Customer;
  contacts: Contact[];
}

const buildContactData = (contacts: Contact[], t: (key: string) => string) => {
  if (!contacts[0]) {
    return [];
  }

  const contactsData = [];

  if (contacts[0].phone) {
    contactsData.push({
      icon: Phone,
      subtitle: t('common:tabPanelTabs.contactData.phone'),
      title: contacts[0].phone,
    });
  }

  if (contacts[0].phoneMobile) {
    contactsData.push({
      icon: Phone,
      subtitle: t('common:tabPanelTabs.contactData.phone'),
      title: contacts[0].phoneMobile,
    });
  }

  if (contacts[0].email) {
    contactsData.push({
      icon: Email,
      subtitle: t('common:tabPanelTabs.contactData.email'),
      title: contacts[0].email,
    });
  }

  return contactsData;
};

const buildData = (customer: Customer, contacts: Contact[], t: (key: string) => string) => {
  return [
    {
      details: [
        {
          icon: Tag,
          subtitle: t('common:tabPanelTabs.tradeRegistryData.customerName'),
          title: customer.name,
        },
        {
          icon: PowerOutlined,
          subtitle: t('common:tabPanelTabs.tradeRegistryData.customerId'),
          title: customer.customerId,
        },
        {
          icon: AccountBalance,
          subtitle: t('common:tabPanelTabs.tradeRegistryData.vatRegistration'),
          title: customer.vatNo
            ? t('common:tabPanelTabs.tradeRegistryData.yes')
            : t('common:tabPanelTabs.tradeRegistryData.no'),
        },
        {
          icon: LocationOn,
          subtitle: t('common:tabPanelTabs.tradeRegistryData.address'),
          title: getAddress(customer.address),
        },
        ...(getCustomerRepresentatives(customer).map((representative) => ({
          icon: Person,
          subtitle: t('common:tabPanelTabs.tradeRegistryData.representative'),
          title: representative,
        })) || []),
      ],
      key: 'tradeRegistryData',
    },
    {
      details: buildContactData(contacts, t),
      key: 'contactData',
    },
  ];
};

const CustomerDetailedData = ({ contacts, customer }: Props) => {
  const { t } = useTranslation();
  const { isTablet } = useResolution();

  const data = buildData(customer, contacts, t);

  const listData = data.map(({ key, details }) => ({
    content: <ListItemGroup data={details} />,
    label: t(`tabPanelTabs.${key}.tabName`),
  }));

  return isTablet ? <TabPanel tabs={listData} /> : <CardMobileSwiper elements={listData} />;
};

export default CustomerDetailedData;
