import { Grid, Typography } from '@mui/material';

import { useResolution } from '../../hooks';

const NotificationContent = ({
  title,
  subtitle,
  timestamp,
}: {
  title: React.ReactNode;
  subtitle: string;
  timestamp: string;
}) => {
  const { isTablet } = useResolution();
  return (
    <Grid container height={isTablet ? 60 : 80}>
      <Grid item xs={12}>
        {title}
      </Grid>
      <Grid item xs={10} alignSelf="end">
        <Typography color="textColor.light" variant="body2" textTransform="capitalize">
          {subtitle}
        </Typography>
      </Grid>
      {!isTablet && (
        <Grid item xs={2} display="flex" justifyContent="right" alignSelf="end">
          <Typography variant="body2">{timestamp}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default NotificationContent;
