import { Configuration } from '@toki-power/public-api';

import { Message } from '../types';

export type ChatReturnType = {
  ask: (messages: Message[]) => Promise<Message>;
};

export const chatApi = (_config: () => Promise<Configuration>): ChatReturnType => {
  // const api = async () => new ChatApi(await config());

  const ask = async (previousMessages: Message[]) => {
    // (await api()).askTokiGPTV1({ data: { messages: previousMessages } });
    const endpoint = process.env.NEXT_PUBLIC_TOKI_GPT_API_URL;

    if (!endpoint) {
      throw new Error('Toki GPT API URL is not set');
    }

    const response = await fetch(endpoint, {
      body: JSON.stringify({ messages: previousMessages }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    return (await response.json()) as Message;
  };

  return {
    ask,
  };
};
