import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormHelperText, MenuItem, SelectField, SelectProps } from 'ui';

import { getError } from '../../utils';

export type CustomSelectProps = SelectProps<string> & {
  name: string;
  autocomplete?: string;
  options: string[];
};

export const Select = ({
  label,
  name,
  autocomplete,
  options,
  defaultValue,
  disabled = false,
  onBlur,
  ...rest
}: CustomSelectProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = getError(errors, name);

  return (
    <FormControl fullWidth error={Boolean(error)}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <SelectField<string>
            id={name}
            error={Boolean(error)}
            label={label}
            disabled={disabled}
            defaultValue={defaultValue}
            {...(onBlur && { onBlur })}
            {...field}
            {...rest}
            name={autocomplete || name}
            ref={field.ref}
          >
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </SelectField>
        )}
      />
      {error && <FormHelperText>{error?.message}</FormHelperText>}
    </FormControl>
  );
};
