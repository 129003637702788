import { Box, Grid, Paper, useResolution } from 'ui';

interface Props {
  leftChild: React.ReactNode;
  rightChild: React.ReactNode;
  header: React.ReactNode;
}

export const WizardLayout = ({ leftChild, rightChild, header }: Props) => {
  const { isLargeTablet } = useResolution();
  return (
    <Grid container height="100%" maxHeight="100%" sx={{ margin: 0 }}>
      <Grid
        item
        md={isLargeTablet ? 6 : 0}
        lg={6}
        paddingX={10}
        display={isLargeTablet ? 'flex' : 'none'}
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        height="100%"
        sx={{
          backgroundColor: '#002138',
          backgroundImage: 'url(/images/conic-gradient-1.png), url(/images/conic-gradient-2.png)',
          backgroundPosition: 'left top, right bottom',
          backgroundSize: 'cover',
        }}
      >
        {leftChild}
      </Grid>
      <Grid
        item
        xs={12}
        md={isLargeTablet ? 6 : 12}
        lg={6}
        display="flex"
        flexDirection="column"
        justifyContent={isLargeTablet ? 'center' : 'none'}
        alignItems={isLargeTablet ? 'center' : 'none'}
        paddingX={2}
        height="100%"
        overflow="hidden"
      >
        {isLargeTablet ? (
          <Paper
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: 600,
              justifyContent: 'space-between',
              width: 400,
            }}
          >
            {rightChild}
          </Paper>
        ) : (
          <Box display="flex" flexDirection="column" height="100%">
            <Box>{header}</Box>
            <Box display="flex" flexGrow={1} height="100%" flexDirection="column" my={2}>
              {rightChild}
            </Box>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
