import { IconButton } from '@mui/material';

import { Download } from '../../icons';

export const DownloadIconButton = ({ downloadUrl, onClick }: { downloadUrl: string; onClick?: () => void }) => {
  return (
    <IconButton
      onClick={(event) => {
        event.preventDefault();
        window.open(downloadUrl, '_blank', 'noopener noreferrer');
        onClick?.();
      }}
      sx={{ color: 'info.main' }}
    >
      <Download />
    </IconButton>
  );
};

export default DownloadIconButton;
