export const energyUtilityOptions = [
  'АЕЦ КОЗЛОДУЙ ЕАД',
  'АКТАЕЛ ЕООД',
  'АЛПИК ЕНЕРГИЯ БЪЛГАРИЯ ЕООД',
  'АРЕСЕНЕРДЖИ ЕООД',
  'АРМАКО ЕНЕРДЖИ ЕАД',
  'АСМ ЕНЕРДЖИ ООД',
  'БОЛКАН ЛОДЖИК ООД - КОМБИНИРАНА',
  'БОЛКАН ЛОДЖИК ООД - СТАНДАРТНА',
  'ВИК ЕНЕРДЖИ ГРУП ЕООД - ПРОИЗВОДИТЕЛИ СТАНДАРТНА',
  'ГРАНД ЕНЕРДЖИ ДИСТРИБЮШЪН ЕООД',
  'ГРАНД ЕНЕРДЖИ ДИСТРИБЮШЪН ЕООД - ПРОИЗВОДИТЕЛИ',
  'ГРИЙН ЕНЕРДЖИ ТРЕЙД ООД',
  'ЕВН БЪЛГАРИЯ ЕЛЕКТРОСНАБДЯВАНЕ ЕАД',
  'ЕВН ТРЕЙДИНГ САУТ ИЙСТ ЮРЪП EАД',
  'ЕЛ БОНУС ЕООД - КОМБИНИРАНА',
  'ЕЛ БОНУС ЕООД - ПРОИЗВОДИТЕЛИ',
  'ЕЛ ЕКС КОРПОРЕЙШЪН АД',
  'ЕЛЕКТРОХОЛД ПРОДАЖБИ АД - ДПИ',
  'ЕЛЕКТРОХОЛД ПРОДАЖБИ АД - КРАЕН СНАБДИТЕЛ',
  'ЕЛЕКТРОХОЛД ПРОДАЖБИ АД - ТЪРГОВИЯ',
  'ЕЛЕКТРОХОЛД ТРЕЙД ЕАД - ПРОИЗВОДИТЕЛИ СТАНДАРТНА',
  'ЕЛНОВА ЕАД',
  'ЕМ ЕН ЕР ДЖИ ЛИМИТИД',
  'ЕНЕКОД АД',
  'ЕНЕКОД ГРИЙН ЕАД',
  'ЕНЕРГАМА АД - ПРОИЗВОДИТЕЛИ СТАНДАРТНА',
  'ЕНЕРГЕО ЕООД',
  'ЕНЕРГИЙНИ ИНОВАТИВНИ ТЕХНОЛОГИИ ЕООД',
  'ЕНЕРГО-ПРО ЕНЕРГИЙНИ УСЛУГИ EАД',
  'ЕНЕРГО-ПРО ПРОДАЖБИ АД',
  'ЕНЕРГОВИА ЕООД',
  'ЕНЕРГОИНВЕСТМЪНТ АД - ПРОИЗВОДИТЕЛИ СТАНДАРТНА',
  'ЕНЕРДЖИ ИНВЕСТ ЮРЪП АД',
  'ЕНЕРДЖИ МАРКЕТ АД',
  'ЕНЕРДЖИ МАРКЕТ ГЛОБАЛ ООД',
  'ЕНЕРДЖИ МТ EАД',
  'ЕНЕРДЖИ ОПЕРЕЙШЪНС ООД ("КААЛЕКС-ЕНЕРДЖИ" ООД)',
  'ЕНЕРДЖИ СЪПЛАЙ EООД',
  'ЕНЕРДЖИ СЪПЛАЙ ГРИЙН EООД',
  'ЕОН БЪЛГАРИЯ 1 - ПРОИЗВОДИТЕЛИ КОМБИНИРАНА',
  'ЕОН БЪЛГАРИЯ 1 - ПРОИЗВОДИТЕЛИ СТАНДАРТНА',
  'ЕСП ЗЛАТНИ ПЯСЪЦИ ООД',
  'ЗАГОРА ЕНЕРДЖИ ООД',
  'ЗЛАТНА ПАНЕГА ЦИМЕНТ АД',
  'ИН АУТ ЕНЕРДЖИ ООД',
  'КОМПАНИЯ ЗА ЕНЕРГЕТИКА И РАЗВИТИЕ ООД',
  'КУМЕР ООД',
  'ЛОНИКО ЕООД',
  'МЕТ ЕНЕРДЖИ ТРЕЙДИНГ ЕАД',
  'МОСТ ЕНЕРДЖИ АД',
  'НЕК ЕАД',
  'НУМАТ ЕНЕРДЖИ КЪМПАНИ ЕООД',
  'ПАУЪР СИСТ ЕООД',
  'ПРОАКТ ООД - ПРОИЗВОДИТЕЛИ КОМБИНИРАНА',
  'ПРОАКТ ООД - ПРОИЗВОДИТЕЛИ СТАНДАРТНА',
  'РИТЪМ-4-ТБ ООД - ПРОИЗВОДИТЕЛИ СТАНДАРТНА',
  'САЙТ ЕНЕРДЖИ ЕООД',
  'СИ ЕНЕРДЖИ ГРУП ЕАД',
  'СИНЕРГОН ЕНЕРДЖИ ЕООД',
  'СМАРТ ЕНЕРДЖИ ТРЕЙД ЕАД',
  'СОЛАР СВ ООД',
  'СОНЕЛ 888 ЕООД',
  'СТОК ЕНЕРДЖИ ЕООД',
  'ТЕРА КАП ЕООД',
  'ТМ-ТЕХНОЛОДЖИ АД',
  'ТОПЛОФИКАЦИЯ СОФИЯ ЕАД',
  'ТЪРГОВСКА КОМПАНИЯ НА ВЪЗОБНОВЯЕМА ЕНЕРГИЯ ООД',
  'ФАКТОР ЕНЕРДЖИ БЪЛГАРИЯ - ПРОИЗВОДИТЕЛИ',
  'ХИДРО ПАУЪР ЮТИЛИТИС ЕООД - ПРОИЗВОДИТЕЛИ СТАНДАРТНА',
  'ЮРОПИАН ТРЕЙД ОФ ЕНЕРДЖИ АД',
];
