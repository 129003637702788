import { useTranslation } from 'next-i18next';
import { MobileSwiper, Typography, useResolution } from 'ui';

import { Invoice, PointInvoiceAddendum } from '~/data';

import { InvoiceCard } from '../detail';
import { InvoiceCardList } from './InvoiceCardList';

interface Props {
  list: Invoice[] | PointInvoiceAddendum[];
}

export const RecentInvoiceList = ({ list }: Props) => {
  const { isDesktop } = useResolution();
  const { t } = useTranslation();

  if (!list.length) {
    return <Typography>{t('invoices:noInvoicesFound')}</Typography>;
  }

  const recentInvoiceList = list.map((invoice) => <InvoiceCard key={invoice.invoiceNumber} {...invoice} />);

  return isDesktop ? <InvoiceCardList list={list} /> : <MobileSwiper elements={recentInvoiceList} />;
};
