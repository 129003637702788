import { useTranslation } from 'next-i18next';
import { ReactElement, useState } from 'react';
import { AutocompleteField, Box, createFilterOptions, TextField, useResolution } from 'ui';

import { PointInvoiceAddendum } from '~/data';

import { MeteringPointCard } from '../../InvoiceMeteringPointCard';

interface Props {
  meteringPointInvoices: PointInvoiceAddendum[];
  defaultOption: PointInvoiceAddendum;
  onChange: (meteringPointInvoice: PointInvoiceAddendum) => void;
  dataTestId?: string;
}

const buildOptionLabel = (option: PointInvoiceAddendum) => {
  if (!option.meteringPoint.name) {
    return option.meteringPoint.meteringPointId;
  }

  return `${option.meteringPoint.meteringPointId}, ${option.meteringPoint.name}`;
};

export const InvoiceMeteringPointSwitcher = ({
  meteringPointInvoices,
  defaultOption,
  onChange,
  dataTestId,
}: Props): ReactElement => {
  const { t } = useTranslation('invoices');
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedMeteringPoint, setSelectedMeteringPoint] = useState(defaultOption);
  const { isTablet } = useResolution();

  const filterOptions = createFilterOptions<PointInvoiceAddendum>({
    matchFrom: 'any',
    stringify: (option) =>
      `${option.meteringPoint.name} ${option.meteringPoint.address} ${option.meteringPoint.meteringPointId}`,
  });

  const handleMeteringPointClick = (pointInvoiceAddendum: PointInvoiceAddendum) => {
    setSelectedMeteringPoint(pointInvoiceAddendum);
    onChange(pointInvoiceAddendum);
    setOpen(false);
  };

  return (
    <Box sx={{ width: isTablet ? '400px' : '100%' }}>
      <AutocompleteField<PointInvoiceAddendum>
        isOptionEqualToValue={(option, value) => option.invoiceId === value.invoiceId}
        getOptionLabel={buildOptionLabel}
        value={selectedMeteringPoint}
        options={meteringPointInvoices}
        filterOptions={filterOptions}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        noOptionsText={t('common:noResults')}
        autoHighlight
        renderOption={(_props, pointInvoiceAddendum) => {
          return (
            <MeteringPointCard
              key={pointInvoiceAddendum.invoiceId}
              meteringPoint={pointInvoiceAddendum.meteringPoint}
              onClick={() => handleMeteringPointClick(pointInvoiceAddendum)}
            />
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            value={search}
            label={t('invoiceMteringPointData', { count: meteringPointInvoices.length })}
            name="meteringPoints"
            data-testid={dataTestId}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        )}
      />
    </Box>
  );
};
