import logger from 'logger';
import { useTranslation } from 'next-i18next';
import { Box, DetailsPageTitle, Paper, useTheme } from 'ui';

import { useInvoiceBalance } from '~/invoice/hooks/useInvoiceBalance/useInvoiceBalance';
import { GoBackButton } from '~/ui';

import { useInvoice } from '../../hooks/useInvoice/useInvoice';
import { DownloadButtons } from '../shared';
import { InvoiceSummary } from './InvoiceSummary/InvoiceSummary';
import { MeteringPointsPreview } from './MeteringPointsPreview';

interface Props {
  invoiceId: string;
}

export const InvoiceDetails = ({ invoiceId }: Props) => {
  const { customShadows } = useTheme();
  const { t } = useTranslation('invoices');

  const { data: invoice } = useInvoice({ invoiceId });

  const statusText = useInvoiceBalance({ balance: invoice?.balance });
  const status = t(`${invoice?.status.toLowerCase()}`);

  if (!invoice) {
    logger.debug('Invoice not found');
    return null;
  }

  return (
    <>
      <Box mb={4}>
        <DetailsPageTitle
          title={t('invoice')}
          subtitle={invoice?.invoiceNumber || ''}
          secondaryTitle={t('status')}
          secondarySubtitle={statusText || status}
          leftButtons={<GoBackButton />}
          rightButtons={<DownloadButtons invoiceId={invoiceId} />}
        />
      </Box>

      {invoice && (
        <>
          <Box mb={4}>
            <Paper
              sx={{
                boxShadow: customShadows.main,
                p: {
                  xs: 2,
                },
              }}
            >
              <InvoiceSummary invoiceId={invoiceId} />
            </Paper>
          </Box>

          <MeteringPointsPreview invoiceId={invoiceId} />
        </>
      )}
    </>
  );
};
