import { Trans, useTranslation } from 'next-i18next';
import { ReactElement } from 'react';
import { Box, Typography } from 'ui';

interface Props {
  i18nKey: string;
  value: string | number;
  wrapped?: boolean;
  valueBold?: boolean;
}

export const MeteringPointData = ({ i18nKey, value, wrapped, valueBold }: Props): ReactElement => {
  const { t } = useTranslation();

  if (wrapped) {
    return (
      <Box flexDirection="row" marginBottom={1}>
        <Trans
          i18nKey={`meteringPoints:${i18nKey}`}
          values={{ [i18nKey]: value }}
          components={[
            <Typography variant="body2" color="textColor.light" display="inline" />,
            <Typography variant="body2" display="inline" ml={0.5} sx={{ fontWeight: valueBold ? 'bold' : '' }} />,
          ]}
        />
      </Box>
    );
  }

  return (
    <Box flexDirection="row" marginBottom={1}>
      <Typography variant="body2" color="textColor.light" display="inline">
        {t(`meteringPoints:${i18nKey}`)}
      </Typography>

      <Typography variant="body2" display="inline" ml={0.5} sx={{ fontWeight: valueBold ? 'bold' : '' }}>
        {value}
      </Typography>
    </Box>
  );
};

MeteringPointData.defaultProps = {
  valueBold: false,
  wrapped: true,
};
