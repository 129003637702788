import { Grid, SvgIconProps } from '@mui/material';
import { ComponentType } from 'react';

import ListItem from '../ListItem/ListItem';

interface Props {
  data: { title: string | null; subtitle: string; icon: ComponentType<SvgIconProps> }[];
}

const ListItemGroup = ({ data }: Props) => {
  return (
    <Grid container spacing={2}>
      {data.map(
        ({ icon, title, subtitle }, index) =>
          title && (
            <Grid item xs={12} md={6} xl={3} key={index}>
              <ListItem Icon={icon} title={title} subtitle={subtitle} />
            </Grid>
          )
      )}
    </Grid>
  );
};

export default ListItemGroup;
