import { useRouter } from 'next/router';
import { Box, styled, useTheme } from 'ui';

const TokiIcon = styled('img')(() => ({
  height: 26,
  width: 26,
}));

export const TokiGPTNavIcon = () => {
  const { palette, spacing, breakpoints } = useTheme();
  const { pathname } = useRouter();

  const selectedStyle =
    pathname === '/toki-gpt'
      ? {
          [breakpoints.down('lg')]: {
            background: palette.gradient.darkGreen,
            borderRadius: '14px',
            height: spacing(4),
            padding: spacing(0.5),
            width: spacing(4),
          },
        }
      : {};

  return (
    <Box display="flex" justifyContent="center" alignItems="center" sx={selectedStyle}>
      <TokiIcon
        src={pathname === '/toki-gpt' ? '/images/toki-gpt-white-nav-icon.svg' : '/images/toki-gpt-nav-icon.svg'}
      />
    </Box>
  );
};
